import React from 'react';
import Image from 'react-bootstrap/Image';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Table from 'react-bootstrap/Table';
import Tabla from '../Tabla';
import MatchList from '../MatchList';
import MatchCardGroup from '../MatchCardGroup';
import Players from '../Players';
import ToggleButtonGroup from 'react-bootstrap/ToggleButtonGroup';
import ToggleButton from 'react-bootstrap/ToggleButton';
class Campeonato2010 extends React.Component {

  constructor(props){
    super(props);
        this.comeBack = this.comeBack.bind(this);
	this.handleChange = this.handleChange.bind(this);
	var id_list = [];
	for (var i = 609; i <= 646; i++) {
           if([623,626,624,625].indexOf(i) === -1){
        	id_list.push(i);
	    }
        }
    this.state = {
	showCards: true,
      path: this.props.path,
	kit_path: '/images/kits/todas/',
      kits: [],
      ctype: this.props.ctype,
      torneo: this.props.torneo,
      id_list: id_list,


        table3:[{'color':0,'shortname':'cobreloa', 'rivalName':'Cobreloa', 'pj':34, 'pg':10,'pe':9,'pp':14,'gf':45,'gc':47,'dif':-2, 'pts':39},
              {'color':'#295e1e','shortname':'audax', 'rivalName':'Audax Italiano', 'pj':34, 'pg':20,'pe':5,'pp':9,'gf':75,'gc':58,'dif':17, 'pts':65},
              {'color':0,'shortname':'sanfelipe', 'rivalName':'U. San Felipe', 'pj':34, 'pg':12,'pe':7,'pp':15,'gf':38,'gc':47,'dif':-9, 'pts':43},
              {'color':'#E4AB27','shortname':'udeconce', 'rivalName':'U. de Concepción', 'pj':34, 'pg':9,'pe':11,'pp':14,'gf':39,'gc':50,'dif':-11, 'pts':38},
              {'color':'#135683','shortname':'catolica', 'rivalName':'U. Católica', 'pj':34, 'pg':23,'pe':5,'pp':6,'gf':77,'gc':39,'dif':38, 'pts':74},
              {'color':'#135683','shortname':'colocolo', 'rivalName':'Colo Colo', 'pj':34, 'pg':22,'pe':5,'pp':7,'gf':67,'gc':34,'dif':33, 'pts':71},
              {'color':0,'shortname':'cobresal', 'rivalName':'Cobresal', 'pj':34, 'pg':12,'pe':6,'pp':16,'gf':47,'gc':52,'dif':-5, 'pts':42},
              {'color':'#295e1e','shortname':'huachipato', 'rivalName':'Huachipato', 'pj':34, 'pg':12,'pe':12,'pp':10,'gf':44,'gc':40,'dif':4, 'pts':48},
              {'color':'#295e1e','shortname':'udechile', 'rivalName':'U. de Chile', 'pj':34, 'pg':20,'pe':4,'pp':10,'gf':75,'gc':48,'dif':27, 'pts':64},
              {'color':'#E42727','shortname':'sanluis', 'rivalName':'San Luis', 'pj':34, 'pg':5,'pe':9,'pp':20,'gf':36,'gc':71,'dif':-35, 'pts':24},
              {'color':0,'shortname':'wanderers', 'rivalName':'S. Wanderers', 'pj':34, 'pg':12,'pe':9,'pp':13,'gf':48,'gc':52,'dif':-4, 'pts':45},
              {'color':'#295e1e','shortname':'union', 'rivalName':'U. Española', 'pj':34, 'pg':14,'pe':10,'pp':10,'gf':58,'gc':50,'dif':8, 'pts':52},
              {'color':0,'shortname':'nublense', 'rivalName':'Ñublense', 'pj':34, 'pg':9,'pe':13,'pp':12,'gf':54,'gc':66,'dif':-12, 'pts':40},
              {'color':0,'shortname':'laserena', 'rivalName':'La Serena', 'pj':34, 'pg':13,'pe':6,'pp':15,'gf':45,'gc':59,'dif':-14, 'pts':45},
              {'color':'#E42727','shortname':'everton', 'rivalName':'Everton', 'pj':34, 'pg':8,'pe':10,'pp':16,'gf':38,'gc':58,'dif':-20, 'pts':34},
              {'color':0,'shortname':'palestino', 'rivalName':'Palestino', 'pj':34, 'pg':11,'pe':9,'pp':14,'gf':35,'gc':41,'dif':-6, 'pts':42},
              {'color':0,'shortname':'ohiggins', 'rivalName':'O\'Higgins', 'pj':34, 'pg':10,'pe':11,'pp':13,'gf':46,'gc':44,'dif':2, 'pts':41},
              {'color':'#E4AB27','shortname':'morning', 'rivalName':'S. Morning', 'pj':34, 'pg':9,'pe':9,'pp':16,'gf':34,'gc':45,'dif':-11, 'pts':36}]

    };
  }

  componentDidMount() {
		this.getKits();
		let newtable2 = this.sortTable(this.state.table3);
                this.setState({table3:newtable2});
	}

getKits(){

        fetch("https://www.registrosudec.com:5000/kits", {

    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      id_list: this.state.id_list
    })
    }).then(
                res => res.json()
                )
  .then(
    (result) => {
        console.log(result)
        this.setState({ 
        kits: result['kits']}) //[{path:"45.png", kit:yellow, short:"blue", soc$
    },
    // Nota: es importante manejar errores aqu   y no en
    // un bloque catch() para que no interceptemos errores
    // de errores reales en los componentes.
    (error) => {
      console.log(error);
      this.setState({fecha: []})
    }
  );
}


  handleChange(val){
    console.log(val);
    if(val===1){
      this.setState({showCards:true});
    }
    else{
      this.setState({showCards:false});
    }
  }

comeBack(){
this.setState({path:this.state.path});
}

subText(color, text){

let comp = [];
comp.push(

	<Row className="justify-content-md-center">
            <Col lg={3} xl={3} >

        <Table bordered={ false } size="sm">
        <tr style={{border: 'none', textAlign: 'rigth' }}>
        <td bgcolor={color} style={{width:'20px'}}>
        </td>
        <td style={{border: 'none', textAlign: 'center'}}> {text} </td>
        </tr>
        </Table>
        </Col>
        </Row>



)
return comp;

}


sortTable(table){
       let aux;
      for(let i=table.length-1;i>0;i--){
             for(let j =0; j<i; j++){
                 if( (table[j].pts < table[j+1].pts) || 
                     ((table[j].pts === table[j+1].pts) && (table[j].pg < table[j+1].pg)) ||
                     ((table[j].pts === table[j+1].pts) && (table[j].pg === table[j+1].pg) && (table[j].dif < table[j+1].dif)) ||
                     ((table[j].pts === table[j+1].pts) && (table[j].pg === table[j+1].pg) && (table[j].dif === table[j+1].dif) && (table[j].gf < table[j+1].gf))){
                        aux = table[j+1];
                        table[j+1] = table[j];
                        table[j] = aux;
                } 
             }          
        }
return table;

}



render(){


  let subT = this.subText('#295e1e', 'Clasificado a Liguilla Pre-Libertadores');
  let subC = this.subText('#135683', 'Clasificado a Libertadores 2011');
    let subP = this.subText('#E4AB27', 'Juega Liguilla Promoción');
  let subD = this.subText('#E42727', 'Desciende a Primera B');

  return(

	<div style={{textAlign: 'center' }}>
	   <div style={{ height: '50px' }}></div>
        <div className="mytitlepartidos">  Indumentaria UDEC Campeonato 2010</div>
        <div style={{ height: '50px' }}></div> 

        <div style={{display: 'inline-block' }}>
        <table style={{textAlign: 'center' }}>
        <tr>
           {this.state.kits.map((i) => {
                   return ( <td> <Image  className="escudo-home" src={this.state.kit_path+i['path']+".png"}/> </td>)
            })}
        </tr>
        <tr>
        {this.state.kits.map((i) => {
                   return ( <td> <Image  className="myshort" src={this.state.kit_path+i['short']+".png"}/> </td>)
            })}
        </tr>
        <tr>
        {this.state.kits.map((i) => {
                   return ( <td> <Image  className="myshort" src={this.state.kit_path+'socks-'+i['socks']+".png"}/> </td>)
            })}
        </tr>

        <tr>
                {this.state.kits.map((i) => {
                   return ( <td class="circle"> {i['count']} </td>)
            })}
        </tr>
        </table>
        </div>

	<div style={{ height: '50px' }}></div>
        <div className="mytitlepartidos"> Tabla General</div>
        <div style={{ height: '10px' }}></div>
        {<Tabla teams={this.state.table3} />}

        <div style={{ height: '10px' }}></div>
	{subC}
        {subT}
        {subP}
	{subD}


	<div style={{ height: '100px' }}></div>
	<div className="mytitlepartidos">  Partidos UDEC Campeonato 2010</div>
	<div style={{ height: '10px' }}></div>

	<ToggleButtonGroup type="radio" name="options" defaultValue={1} onChange={this.handleChange}>
            <ToggleButton variant="secondary" value={1}>Tarjetas</ToggleButton>
            <ToggleButton variant="secondary" value={2}>Listado</ToggleButton>
          </ToggleButtonGroup>
	 <div style={{ height: '10px' }}></div>
	<Container>
	<div className={this.state.showCards ? '': 'hiddenCard'}>
           <MatchCardGroup id_list={this.state.id_list}></MatchCardGroup>
         </div>
         <div className={this.state.showCards ? 'hiddenCard': ''}>

           <MatchList id_list={this.state.id_list} showName={true}></MatchList>

         </div>
	</Container>

	<div style={{ height: '100px' }}></div>
        <div className="mytitlepartidos">  Jugadores UDEC Campeonato 2010</div>
        <div style={{ height: '10px' }}></div>

	<Players torneo="Campeonato 2010" />
     </div>


  )
}

}
export default Campeonato2010;
