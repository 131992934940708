import React from 'react';

import Image from 'react-bootstrap/Image';
import Campeonato2024 from './Torneos/Campeonato2024';
import Campeonato2023 from './Torneos/Campeonato2023';
import Campeonato2022 from './Torneos/Campeonato2022';
import Campeonato2021 from './Torneos/Campeonato2021';
import Campeonato2020 from './Torneos/Campeonato2020';
import Campeonato2019 from './Torneos/Campeonato2019';
import Campeonato2018 from './Torneos/Campeonato2018';
import Transicion2020 from './Torneos/Transicion2020';
import Clausura2017 from './Torneos/Clausura2017';
import Apertura2017 from './Torneos/Apertura2017';
import Clausura2016 from './Torneos/Clausura2016';
import Apertura2016 from './Torneos/Apertura2016';

class Torneo extends React.Component {

  constructor(props){
    super(props);
        this.comeBack = this.comeBack.bind(this);
    this.state = {
      path: this.props.path,
      ctype: this.props.ctype,
      torneo: this.props.torneo

    };
  }

  componentDidMount() {
	}




comeBack(){
this.setState({path:this.state.path});
}



render(){
  return(

	<div style={{textAlign: 'center' }}>
      <div style={{ height: '30px' }}></div>
        <div className="mytitlepartidos"> {this.state.torneo} </div>
      <div style={{textAlign: 'center' }}>
        <div style={{ height: '20px' }}></div>
      <Image  className="myescudo-away-main"  src={'/images/escudos/udeconce.png'}/>
      </div>

      <div style={{ height: '50px' }}></div>
		{this.state.path === '2023' && <Campeonato2023/>}
		{this.state.path === '2022' && <Campeonato2022/>}
		{this.state.path === 'a2017' && <Apertura2017/>}
	        {this.state.path === 'c2017' && <Clausura2017/>}
                {this.state.path === 'a2016' && <Apertura2016/>}
		{this.state.path === 'c2016' && <Clausura2016/>}
		{this.state.path === '2018' && <Campeonato2018/>}
		{this.state.path === '2019' && <Campeonato2019/>}
                {this.state.path === '2020' && <Campeonato2020/>}
		{this.state.path === '2021' && <Campeonato2021/>}
		{this.state.path === '2024' && <Campeonato2024/>}
		{this.state.path === 't2020' && <Transicion2020/>}
        <div style={{ height: '70px' }}></div>

     </div>


  )
}

}
export default Torneo;
