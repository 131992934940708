import React from 'react';
import Image from 'react-bootstrap/Image';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Table from 'react-bootstrap/Table';
import Tabla from '../Tabla';
import MatchList from '../MatchList';
import MatchCardGroup from '../MatchCardGroup';
import Players from '../Players';
import ToggleButtonGroup from 'react-bootstrap/ToggleButtonGroup';
import ToggleButton from 'react-bootstrap/ToggleButton';
import TablaProm2008 from '../TablaProm2008';
class Clausura2008 extends React.Component {

  constructor(props){
    super(props);
        this.comeBack = this.comeBack.bind(this);
	this.handleChange = this.handleChange.bind(this);
	var id_list = [];
	for (var i = 543; i <= 562; i++) {
             if(i !== 560 && i !== 557){
              id_list.push(i);
	}
        }
    this.state = {
	showCards: true,
      path: this.props.path,
      ctype: this.props.ctype,
	kit_path: '/images/kits/todas/',
      kits: [],
      torneo: this.props.torneo,
      id_list: id_list,
      table1:[

		{'color':0,'shortname':'udeconce', 'rivalName':'U. de Concepción', 'pj':18, 'pg':5,'pe':6,'pp':7,'gf':32,'gc':32,'dif':0, 'pts':21},
		{'color':'#295e1e','shortname':'ohiggins', 'rivalName':'O\'Higgins', 'pj':18, 'pg':8,'pe':4,'pp':6,'gf':39,'gc':35,'dif':4, 'pts':28},
		{'color':'#295e1e','shortname':'palestino', 'rivalName':'Palestino', 'pj':18, 'pg':10,'pe':3,'pp':5,'gf':29,'gc':22,'dif':7, 'pts':33},
              {'color':0,'shortname':'morning', 'rivalName':'S. Morning', 'pj':18, 'pg':7,'pe':4,'pp':6,'gf':30,'gc':32,'dif':-2, 'pts':25},
		{'color':0,'shortname':'melipilla', 'rivalName':'Melipilla', 'pj':18, 'pg':3,'pe':2,'pp':14,'gf':19,'gc':38,'dif':-19, 'pts':11}

	],

	table2:[{'color':'#295e1e','shortname':'cobreloa', 'rivalName':'Cobreloa', 'pj':18, 'pg':8,'pe':3,'pp':7,'gf':34,'gc':32,'dif':2, 'pts':27},
              {'color':0,'shortname':'audax', 'rivalName':'Audax Italiano', 'pj':18, 'pg':6,'pe':6,'pp':6,'gf':25,'gc':25,'dif':0, 'pts':24},
              {'color':0,'shortname':'osorno', 'rivalName':'Prov. Osorno', 'pj':18, 'pg':3,'pe':1,'pp':13,'gf':20,'gc':40,'dif':-20, 'pts':10},
	      {'color':0,'shortname':'udeconce', 'rivalName':'U. de Concepción', 'pj':18, 'pg':5,'pe':6,'pp':7,'gf':32,'gc':32,'dif':0, 'pts':21},
              {'color':'#295e1e','shortname':'catolica', 'rivalName':'U. Católica', 'pj':18, 'pg':9,'pe':2,'pp':7,'gf':37,'gc':33,'dif':4, 'pts':29},
              {'color':'#295e1e','shortname':'colocolo', 'rivalName':'Colo Colo', 'pj':18, 'pg':10,'pe':3,'pp':5,'gf':32,'gc':19,'dif':13, 'pts':33},
              {'color':0,'shortname':'cobresal', 'rivalName':'Cobresal', 'pj':18, 'pg':5,'pe':6,'pp':7,'gf':24,'gc':26,'dif':-2, 'pts':21},
              {'color':'#295e1e','shortname':'huachipato', 'rivalName':'Huachipato', 'pj':18, 'pg':8,'pe':3,'pp':7,'gf':33,'gc':25,'dif':8, 'pts':27},
              {'color':'#135683','shortname':'udechile', 'rivalName':'U. de Chile', 'pj':18, 'pg':12,'pe':2,'pp':4,'gf':39,'gc':21,'dif':18, 'pts':38},
              {'color':'#295e1e','shortname':'rangers', 'rivalName':'Rangers', 'pj':18, 'pg':9,'pe':3,'pp':6,'gf':25,'gc':25,'dif':0, 'pts':30},
              {'color':'#295e1e','shortname':'ohiggins', 'rivalName':'O\'Higgins', 'pj':18, 'pg':8,'pe':4,'pp':6,'gf':39,'gc':35,'dif':4, 'pts':28},
              {'color':0,'shortname':'union', 'rivalName':'U. Española', 'pj':18, 'pg':7,'pe':4,'pp':7,'gf':27,'gc':31,'dif':-4, 'pts':25},
              {'color':0,'shortname':'nublense', 'rivalName':'Ñublense', 'pj':18, 'pg':3,'pe':8,'pp':7,'gf':21,'gc':29,'dif':-8, 'pts':17},
	      {'color':0,'shortname':'laserena', 'rivalName':'La Serena', 'pj':18, 'pg':7,'pe':6,'pp':5,'gf':37,'gc':34,'dif':3, 'pts':27},
	      {'color':0,'shortname':'everton', 'rivalName':'Everton', 'pj':18, 'pg':9,'pe':1,'pp':8,'gf':28,'gc':25,'dif':3, 'pts':28},
              {'color':'#295e1e','shortname':'palestino', 'rivalName':'Palestino', 'pj':18, 'pg':10,'pe':3,'pp':5,'gf':29,'gc':22,'dif':7, 'pts':33},
              {'color':0,'shortname':'morning', 'rivalName':'S. Morning', 'pj':18, 'pg':7,'pe':4,'pp':6,'gf':30,'gc':32,'dif':-2, 'pts':25},
	      {'color':0,'shortname':'antofagasta', 'rivalName':'Antofagasta', 'pj':18, 'pg':7,'pe':2,'pp':9,'gf':20,'gc':27,'dif':7, 'pts':23},
              {'color':0,'shortname':'melipilla', 'rivalName':'Melipilla', 'pj':18, 'pg':3,'pe':2,'pp':14,'gf':19,'gc':38,'dif':-19, 'pts':11}
],

		  table3:[
  {'color':0,'shortname':'catolica', 'rivalName':'U. Católica', 'pts07':80, 'pon07':30.4,'pts08':65,'pon08':39,'pon':69.4},
{'color':0,'shortname':'audax', 'rivalName':'Audax Italiano', 'pts07':91, 'pon07':34.6,'pts08':58,'pon08':34.8,'pon':69.4},
{'color':0,'shortname':'colocolo', 'rivalName':'Colo Colo', 'pts07':86, 'pon07':32.7,'pts08':60,'pon08':36,'pon':68.7},
 {'color':0,'shortname':'udechile', 'rivalName':'U. de Chile', 'pts07':70, 'pon07':26.6,'pts08':68,'pon08':40.8,'pon':67.4},
{'color':0,'shortname':'ohiggins', 'rivalName':'O\'Higgins', 'pts07':61, 'pon07':23.2,'pts08':64,'pon08':38.4,'pon':61.6},
{'color':0,'shortname':'huachipato', 'rivalName':'Huachipato', 'pts07':65, 'pon07':24.7,'pts08':54,'pon08':32.4,'pon':57.1},
{'color':0,'shortname':'nublense', 'rivalName':'Ñublense', 'pts07':58, 'pon07':22,'pts08':58,'pon08':34.8,'pon':56.8},
{'color':0,'shortname':'morning', 'rivalName':'S. Morning', 'pts07':0, 'pon07':0,'pts08':56,'pon08':56,'pon':56},
{'color':0,'shortname':'cobresal', 'rivalName':'Cobresal', 'pts07':64, 'pon07':24.3,'pts08':52,'pon08':31.2,'pon':55.5},
{'color':0,'shortname':'cobreloa', 'rivalName':'Cobreloa', 'pts07':66, 'pon07':25.1,'pts08':50,'pon08':30,'pon':55.1},
{'color':0,'shortname':'rangers', 'rivalName':'Rangers', 'pts07':0, 'pon07':0,'pts08':54,'pon08':54,'pon':54},
{'color':0,'shortname':'palestino', 'rivalName':'Palestino', 'pts07':49, 'pon07':18.6,'pts08':57,'pon08':34.2,'pon':52.8},
{'color':0,'shortname':'everton', 'rivalName':'Everton', 'pts07':39, 'pon07':14.8,'pts08':60,'pon08':36,'pon':50.8},
{'color':0,'shortname':'laserena', 'rivalName':'La Serena', 'pts07':48, 'pon07':18.2,'pts08':53,'pon08':31.8,'pon':50},
{'color':'#E4AB27','shortname':'union', 'rivalName':'U. Española', 'pts07':45, 'pon07':17.1,'pts08':49,'pon08':29.4,'pon':46.5},
{'color':'#E4AB27','shortname':'udeconce', 'rivalName':'U. de Concepción', 'pts07':52, 'pon07':19.8,'pts08':42,'pon08':25.2,'pon':45},
{'color':'#E42727','shortname':'antofagasta', 'rivalName':'Antofagasta', 'pts07':47, 'pon07':17.9,'pts08':40,'pon08':24,'pon':41.9},
{'color':'#E42727','shortname':'melipilla', 'rivalName':'Melipilla', 'pts07':55, 'pon07':20.9,'pts08':21,'pon08':12.6,'pon':33.5},
{'color':'#E42727','shortname':'osorno', 'rivalName':'Prov. Osorno', 'pts07':0, 'pon07':0,'pts08':26,'pon08':26,'pon':26}

              ]

    };
  }

  componentDidMount() {
	this.getKits();
		let _newtable = this.sortTable(this.state.table1);
                this.setState({table1:_newtable});
		let newtable = this.sortTable(this.state.table2);
  		this.setState({table2:newtable});
		let newtable2 = this.sortTable(this.state.table3);
                this.setState({table3:newtable2});
	}

getKits(){

        fetch("https://www.registrosudec.com:5000/kits", {

    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      id_list: this.state.id_list
    })
    }).then(
                res => res.json()
                )
  .then(
    (result) => {
        console.log(result)
        this.setState({ 
        kits: result['kits']}) //[{path:"45.png", kit:yellow, short:"blue", soc$
    },
    // Nota: es importante manejar errores aqu   y no en
    // un bloque catch() para que no interceptemos errores
    // de errores reales en los componentes.
    (error) => {
      console.log(error);
      this.setState({fecha: []})
    }
  );
}


  handleChange(val){
    console.log(val);
    if(val===1){
      this.setState({showCards:true});
    }
    else{
      this.setState({showCards:false});
    }
  }

comeBack(){
this.setState({path:this.state.path});
}

subText(color, text){

let comp = [];
comp.push(

	<Row className="justify-content-md-center">
            <Col lg={3} xl={3} >

        <Table bordered={ false } size="sm">
        <tr style={{border: 'none', textAlign: 'rigth' }}>
        <td bgcolor={color} style={{width:'20px'}}>
        </td>
        <td style={{border: 'none', textAlign: 'center'}}> {text} </td>
        </tr>
        </Table>
        </Col>
        </Row>



)
return comp;

}


sortTable(table){
       let aux;
      for(let i=table.length-1;i>0;i--){
             for(let j =0; j<i; j++){
                 if( (table[j].pts < table[j+1].pts) || 
		     ((table[j].pts === table[j+1].pts) && (table[j].pg < table[j+1].pg)) ||
                     ((table[j].pts === table[j+1].pts) && (table[j].pg === table[j+1].pg) && (table[j].dif < table[j+1].dif)) ||
                     ((table[j].pts === table[j+1].pts) && (table[j].pg === table[j+1].pg) && (table[j].dif === table[j+1].dif) && (table[j].gf < table[j+1].gf))){
			aux = table[j+1];
			table[j+1] = table[j];
                        table[j] = aux;
		} 
	     }		
	}
return table;

}


render(){


  let subT = this.subText('#295e1e', 'Clasificado a Play-Off');
  let subC = this.subText('#135683', 'Clasificado a Play-Off y a Libertadores 2009');
  let subP = this.subText('#E4AB27', 'Juega Liguilla Promoción');
  let subD = this.subText('#E42727', 'Desciende a Primera B');
  return(

	<div style={{textAlign: 'center' }}>

	    <div style={{ height: '50px' }}></div>
        <div className="mytitlepartidos">  Indumentaria UDEC Clausura 2008</div>
        <div style={{ height: '50px' }}></div> 

        <div style={{display: 'inline-block' }}>
        <table style={{textAlign: 'center' }}>
        <tr>
           {this.state.kits.map((i) => {
                   return ( <td> <Image  className="escudo-home" src={this.state.kit_path+i['path']+".png"}/> </td>)
            })}
        </tr>
        <tr>
        {this.state.kits.map((i) => {
                   return ( <td> <Image  className="myshort" src={this.state.kit_path+i['short']+".png"}/> </td>)
            })}
        </tr>
        <tr>
        {this.state.kits.map((i) => {
                   return ( <td> <Image  className="myshort" src={this.state.kit_path+'socks-'+i['socks']+".png"}/> </td>)
            })}
        </tr>

        <tr>
                {this.state.kits.map((i) => {
                   return ( <td class="circle"> {i['count']} </td>)
            })}
        </tr>
        </table>
        </div>

	      <div style={{ height: '50px' }}></div>
	<div className="mytitlepartidos"> Grupo 3 </div>
	<div style={{ height: '10px' }}></div>
	{<Tabla teams={this.state.table1} />}
        <div style={{ height: '10px' }}></div>
         {subT}


	<div style={{ height: '50px' }}></div>
	<div className="mytitlepartidos"> Tabla General</div>
	<div style={{ height: '10px' }}></div>
        {<Tabla teams={this.state.table2} />}

	<div style={{ height: '10px' }}></div>
	{subC}
	{subT}
	
	<div style={{ height: '50px' }}></div>
        <div className="mytitlepartidos"> Tabla Descenso 2008</div>
        <div style={{ height: '10px' }}></div>
        {<TablaProm2008 teams={this.state.table3} />}

        <div style={{ height: '10px' }}></div>
	{subP}
        {subD}


	<div style={{ height: '100px' }}></div>
	<div className="mytitlepartidos">  Partidos UDEC Clausura 2008</div>
	<div style={{ height: '10px' }}></div>

	<ToggleButtonGroup type="radio" name="options" defaultValue={1} onChange={this.handleChange}>
            <ToggleButton variant="secondary" value={1}>Tarjetas</ToggleButton>
            <ToggleButton variant="secondary" value={2}>Listado</ToggleButton>
          </ToggleButtonGroup>
	 <div style={{ height: '10px' }}></div>
	<Container>
	<div className={this.state.showCards ? '': 'hiddenCard'}>
           <MatchCardGroup id_list={this.state.id_list}></MatchCardGroup>
         </div>
         <div className={this.state.showCards ? 'hiddenCard': ''}>

           <MatchList id_list={this.state.id_list} showName={true}></MatchList>

         </div>
	</Container>

	<div style={{ height: '100px' }}></div>
        <div className="mytitlepartidos">  Jugadores UDEC Clausura 2008</div>
        <div style={{ height: '10px' }}></div>

	<Players torneo="Clausura 2008" />
     </div>


  )
}

}
export default Clausura2008;
