import React from 'react';
import Container from 'react-bootstrap/Container';
import Image from 'react-bootstrap/Image';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Table from 'react-bootstrap/Table';
import Tabla from '../Tabla';
import MatchList from '../MatchList';
import MatchCardGroup from '../MatchCardGroup';
import Players from '../Players';
import ToggleButtonGroup from 'react-bootstrap/ToggleButtonGroup';
import ToggleButton from 'react-bootstrap/ToggleButton';
class Clausura2017 extends React.Component {

  constructor(props){
    super(props);
        this.comeBack = this.comeBack.bind(this);
	this.handleChange = this.handleChange.bind(this);
	var id_list = [];
	for (var i = 911; i <= 925; i++) {
        	id_list.push(i);
        }
    this.state = {
	showCards: true,
      path: this.props.path,
         kit_path: '/images/kits/todas/',
      kits: [],
      ctype: this.props.ctype,
      torneo: this.props.torneo,
      id_list: id_list,

	table2:[{'color':0,'shortname':'sanluis', 'rivalName':'San Luis', 'pj':15, 'pg':5,'pe':3,'pp':7,'gf':19,'gc':25,'dif':-6, 'pts':18},
              {'color':0,'shortname':'audax', 'rivalName':'Audax Italiano', 'pj':15, 'pg':6,'pe':4,'pp':5,'gf':23,'gc':23,'dif':0, 'pts':22},
              {'color':0,'shortname':'wanderers', 'rivalName':'S. Wanderers', 'pj':15, 'pg':3,'pe':4,'pp':8,'gf':19,'gc':24,'dif':-5, 'pts':13},
	      {'color':'#295e1e','shortname':'udeconce', 'rivalName':'U. de Concepción', 'pj':15, 'pg':6,'pe':6,'pp':3,'gf':16,'gc':13,'dif':3, 'pts':24},
              {'color':0,'shortname':'catolica', 'rivalName':'U. Católica', 'pj':15, 'pg':7,'pe':2,'pp':6,'gf':25,'gc':23,'dif':2, 'pts':23},
              {'color':0,'shortname':'colocolo', 'rivalName':'Colo Colo', 'pj':15, 'pg':8,'pe':5,'pp':2,'gf':29,'gc':13,'dif':16, 'pts':29},
              {'color':0,'shortname':'cobresal', 'rivalName':'Cobresal', 'pj':15, 'pg':2,'pe':2,'pp':11,'gf':16,'gc':37,'dif':-21, 'pts':8},
              {'color':0,'shortname':'huachipato', 'rivalName':'Huachipato', 'pj':15, 'pg':5,'pe':3,'pp':7,'gf':17,'gc':25,'dif':-8, 'pts':18},
              {'color':'#135683','shortname':'udechile', 'rivalName':'U. de Chile', 'pj':15, 'pg':9,'pe':3,'pp':3,'gf':24,'gc':11,'dif':13, 'pts':30},
              {'color':0,'shortname':'everton', 'rivalName':'Everton', 'pj':15, 'pg':5,'pe':6,'pp':4,'gf':19,'gc':14,'dif':5, 'pts':21},
              {'color':0,'shortname':'iquique', 'rivalName':'Dep. Iquique', 'pj':15, 'pg':5,'pe':7,'pp':3,'gf':23,'gc':20,'dif':3, 'pts':22},
              {'color':0,'shortname':'union', 'rivalName':'U. Española', 'pj':15, 'pg':6,'pe':4,'pp':5,'gf':25,'gc':21,'dif':4, 'pts':22},
              {'color':0,'shortname':'palestino', 'rivalName':'Palestino', 'pj':15, 'pg':3,'pe':5,'pp':7,'gf':16,'gc':25,'dif':-9, 'pts':14},
              {'color':0,'shortname':'ohiggins', 'rivalName':'O\'Higgins', 'pj':15, 'pg':6,'pe':4,'pp':5,'gf':20,'gc':20,'dif':0, 'pts':22},
		{'color':0,'shortname':'temuco', 'rivalName':'Dep. Temuco', 'pj':15, 'pg':6,'pe':4,'pp':5,'gf':17,'gc':17,'dif':0, 'pts':22},
              {'color':0,'shortname':'antofagasta', 'rivalName':'Antofagasta', 'pj':15, 'pg':4,'pe':6,'pp':5,'gf':21,'gc':18,'dif':3, 'pts':18}],

        table3:[{'color':0,'shortname':'sanluis', 'rivalName':'San Luis', 'pj':30, 'pg':11,'pe':6,'pp':13,'gf':40,'gc':47,'dif':-7, 'pts':38},
              {'color':0,'shortname':'audax', 'rivalName':'Audax Italiano', 'pj':30, 'pg':10,'pe':7,'pp':13,'gf':40,'gc':51,'dif':-11, 'pts':37},
              {'color':0,'shortname':'wanderers', 'rivalName':'S. Wanderers', 'pj':30, 'pg':8,'pe':7,'pp':15,'gf':32,'gc':44,'dif':-12, 'pts':31},
              {'color':0,'shortname':'udeconce', 'rivalName':'U. de Concepción', 'pj':30, 'pg':10,'pe':8,'pp':12,'gf':31,'gc':38,'dif':-7, 'pts':38},
              {'color':0,'shortname':'catolica', 'rivalName':'U. Católica', 'pj':30, 'pg':16,'pe':6,'pp':8,'gf':62,'gc':41,'dif':21, 'pts':54},
              {'color':0,'shortname':'colocolo', 'rivalName':'Colo Colo', 'pj':30, 'pg':14,'pe':10,'pp':6,'gf':55,'gc':33,'dif':22, 'pts':52},
              {'color':'#E42727','shortname':'cobresal', 'rivalName':'Cobresal', 'pj':30, 'pg':6,'pe':6,'pp':8,'gf':30,'gc':58,'dif':-28, 'pts':24},
              {'color':0,'shortname':'huachipato', 'rivalName':'Huachipato', 'pj':30, 'pg':9,'pe':9,'pp':12,'gf':41,'gc':49,'dif':-8, 'pts':36},
              {'color':0,'shortname':'udechile', 'rivalName':'U. de Chile', 'pj':30, 'pg':14,'pe':9,'pp':7,'gf':47,'gc':33,'dif':14, 'pts':51},
              {'color':0,'shortname':'everton', 'rivalName':'Everton', 'pj':30, 'pg':8,'pe':12,'pp':10,'gf':41,'gc':42,'dif':-1, 'pts':36},
              {'color':0,'shortname':'iquique', 'rivalName':'Dep. Iquique', 'pj':30, 'pg':13,'pe':11,'pp':6,'gf':51,'gc':42,'dif':9, 'pts':50},
              {'color':0,'shortname':'union', 'rivalName':'U. Española', 'pj':30, 'pg':14,'pe':7,'pp':9,'gf':52,'gc':41,'dif':11, 'pts':49},
              {'color':0,'shortname':'palestino', 'rivalName':'Palestino', 'pj':30, 'pg':9,'pe':8,'pp':13,'gf':41,'gc':44,'dif':-3, 'pts':35},
              {'color':0,'shortname':'ohiggins', 'rivalName':'O\'Higgins', 'pj':30, 'pg':13,'pe':9,'pp':8,'gf':43,'gc':35,'dif':8, 'pts':48},
                {'color':0,'shortname':'temuco', 'rivalName':'Dep. Temuco', 'pj':30, 'pg':11,'pe':5,'pp':14,'gf':32,'gc':38,'dif':-6, 'pts':38},
              {'color':0,'shortname':'antofagasta', 'rivalName':'Antofagasta', 'pj':30, 'pg':9,'pe':10,'pp':11,'gf':38,'gc':40,'dif':-2, 'pts':37}],
    };
  }

  componentDidMount() {
		this.getKits();
		let newtable = this.sortTable(this.state.table2);
  		this.setState({table2:newtable});
		let newtable2 = this.sortTable(this.state.table3);
                this.setState({table3:newtable2});
	}


getKits(){

        fetch("https://www.registrosudec.com:5000/kits", {

    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      id_list: this.state.id_list
    })
    }).then(
                res => res.json()
                )
  .then(
    (result) => {
        console.log(result)
        this.setState({ 
        kits: result['kits']}) //[{path:"45.png", kit:yellow, short:"blue", socks:"blue", count:15, 
    },
    // Nota: es importante manejar errores aqu   y no en
    // un bloque catch() para que no interceptemos errores
    // de errores reales en los componentes.
    (error) => {
      console.log(error);
      this.setState({fecha: []})
    }
  );
}


  handleChange(val){
    console.log(val);
    if(val===1){
      this.setState({showCards:true});
    }
    else{
      this.setState({showCards:false});
    }
  }

comeBack(){
this.setState({path:this.state.path});
}

subText(color, text){

let comp = [];
comp.push(

	<Row className="justify-content-md-center">
            <Col lg={3} xl={3} >

        <Table bordered={ false } size="sm">
        <tr style={{border: 'none', textAlign: 'rigth' }}>
        <td bgcolor={color} style={{width:'20px'}}>
        </td>
        <td style={{border: 'none', textAlign: 'center'}}> {text} </td>
        </tr>
        </Table>
        </Col>
        </Row>



)
return comp;

}


sortTable(table){
       let aux;
      for(let i=table.length-1;i>0;i--){
             for(let j =0; j<i; j++){
                 if( (table[j].pts < table[j+1].pts) || 
                     ((table[j].pts === table[j+1].pts) && (table[j].dif < table[j+1].dif)) ||
                     ((table[j].pts === table[j+1].pts) && (table[j].pg === table[j+1].pg) && (table[j].dif < table[j+1].dif)) ||
                     ((table[j].pts === table[j+1].pts) && (table[j].pg === table[j+1].pg) && (table[j].dif === table[j+1].dif) && (table[j].gf < table[j+1].gf))){
                        aux = table[j+1];
                        table[j+1] = table[j];
                        table[j] = aux;
                } 
             }          
        }
return table;

}



render(){


  let subT = this.subText('#295e1e', 'Clasificado a Liguilla Lib/Sud');
  let subC = this.subText('#135683', 'Clasificado a Libertadores 2018');
  let subD = this.subText('#E42727', 'Desciende a Primera B');
  return(

	<div style={{textAlign: 'center' }}>


	<div style={{ height: '50px' }}></div>
        <div className="mytitlepartidos">  Indumentaria UDEC Clausura 2017</div>
        <div style={{ height: '50px' }}></div> 

        <div style={{display: 'inline-block' }}>
        <table style={{textAlign: 'center' }}>
        <tr>
           {this.state.kits.map((i) => {
                   return ( <td> <Image  className="escudo-home" src={this.state.kit_path+i['path']+".png"}/> </td>)
            })}
        </tr>
        <tr>
        {this.state.kits.map((i) => {
                   return ( <td> <Image  className="myshort" src={this.state.kit_path+i['short']+".png"}/> </td>)
            })}
        </tr>
        <tr>
        {this.state.kits.map((i) => {
                   return ( <td> <Image  className="myshort" src={this.state.kit_path+'socks-'+i['socks']+".png"}/> </td>)
            })}
        </tr>

        <tr>
                {this.state.kits.map((i) => {
                   return ( <td class="circle"> {i['count']} </td>)
            })}
        </tr>
        </table>
        </div>


	<div style={{ height: '50px' }}></div>


	<div className="mytitlepartidos"> Tabla General</div>
	<div style={{ height: '10px' }}></div>
        {<Tabla teams={this.state.table2} />}
	<div style={{ height: '10px' }}></div>
        <div style={{ height: '10px' }}></div>
	{subC}
	{subT}
	
	<div style={{ height: '50px' }}></div>
        <div className="mytitlepartidos"> Tabla Anual 2016-2017</div>
        <div style={{ height: '10px' }}></div>
        {<Tabla teams={this.state.table3} />}

        <div style={{ height: '10px' }}></div>
 	{subD}


	<div style={{ height: '100px' }}></div>
	<div className="mytitlepartidos">  Partidos UDEC Clausura 2017</div>
	<div style={{ height: '10px' }}></div>

	<ToggleButtonGroup type="radio" name="options" defaultValue={1} onChange={this.handleChange}>
            <ToggleButton variant="secondary" value={1}>Tarjetas</ToggleButton>
            <ToggleButton variant="secondary" value={2}>Listado</ToggleButton>
          </ToggleButtonGroup>
	 <div style={{ height: '10px' }}></div>
	<Container>
	<div className={this.state.showCards ? '': 'hiddenCard'}>
           <MatchCardGroup id_list={this.state.id_list}></MatchCardGroup>
         </div>
         <div className={this.state.showCards ? 'hiddenCard': ''}>

           <MatchList id_list={this.state.id_list} showName={true}></MatchList>

         </div>
	</Container>

	<div style={{ height: '100px' }}></div>
        <div className="mytitlepartidos">  Jugadores UDEC Clausura 2017</div>
        <div style={{ height: '10px' }}></div>

	<Players torneo="Clausura 2017" />
     </div>


  )
}

}
export default Clausura2017;
