import React from 'react';
import Image from 'react-bootstrap/Image';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Table from 'react-bootstrap/Table';
import Tabla from '../Tabla';
import MatchList from '../MatchList';
import MatchCardGroup from '../MatchCardGroup';
import Players from '../Players';
import ToggleButtonGroup from 'react-bootstrap/ToggleButtonGroup';
import ToggleButton from 'react-bootstrap/ToggleButton';
class Clausura2004 extends React.Component {

  constructor(props){
    super(props);
        this.comeBack = this.comeBack.bind(this);
	this.handleChange = this.handleChange.bind(this);
	var id_list = [];
	for (var i = 374; i <= 397; i++) {
           if([381,382,386,390,375].indexOf(i) === -1){
        	id_list.push(i);
	    }
        }
    this.state = {
	showCards: true,
      path: this.props.path,
	kit_path: '/images/kits/todas/',
      kits: [],
      ctype: this.props.ctype,
      torneo: this.props.torneo,
      id_list: id_list,
      table1:[{'color':'#295e1e','shortname':'udeconce', 'rivalName':'U. de Concepción', 'pj':17, 'pg':8,'pe':4,'pp':5,'gf':22,'gc':16,'dif':6, 'pts':28},
              {'color':'#295e1e','shortname':'cobresal', 'rivalName':'Cobresal', 'pj':17, 'pg':8,'pe':4,'pp':5,'gf':32,'gc':16,'dif':16, 'pts':28},
              {'color':0,'shortname':'huachipato', 'rivalName':'Huachipato', 'pj':17, 'pg':6,'pe':2,'pp':9,'gf':24,'gc':26,'dif':-2, 'pts':20},
	      {'color':'#295e1e','shortname':'catolica', 'rivalName':'U. Católica', 'pj':17, 'pg':9,'pe':5,'pp':3,'gf':32,'gc':19,'dif':13, 'pts':32},
	      {'color':'#295e1e','shortname':'everton', 'rivalName':'Everton', 'pj':17, 'pg':6,'pe':4,'pp':7,'gf':20,'gc':26,'dif':-6, 'pts':22}],

	table2:[{'color':'#295e1e','shortname':'cobreloa', 'rivalName':'Cobreloa', 'pj':17, 'pg':6,'pe':7,'pp':4,'gf':37,'gc':26,'dif':11, 'pts':25},
              {'color':'#295e1e','shortname':'audax', 'rivalName':'Audax Italiano', 'pj':17, 'pg':7,'pe':5,'pp':5,'gf':33,'gc':24,'dif':9, 'pts':26},
              {'color':0,'shortname':'puertomontt', 'rivalName':'Puerto Montt', 'pj':17, 'pg':5,'pe':5,'pp':7,'gf':16,'gc':20,'dif':-4, 'pts':17},
	      {'color':'#295e1e','shortname':'udeconce', 'rivalName':'U. de Concepción', 'pj':17, 'pg':8,'pe':4,'pp':5,'gf':22,'gc':16,'dif':6, 'pts':28},
              {'color':'#295e1e','shortname':'catolica', 'rivalName':'U. Católica', 'pj':17, 'pg':9,'pe':5,'pp':3,'gf':32,'gc':19,'dif':13, 'pts':32},
              {'color':'#295e1e','shortname':'colocolo', 'rivalName':'Colo Colo', 'pj':17, 'pg':9,'pe':5,'pp':3,'gf':28,'gc':18,'dif':10, 'pts':32},
              {'color':'#295e1e','shortname':'cobresal', 'rivalName':'Cobresal', 'pj':17, 'pg':8,'pe':4,'pp':5,'gf':32,'gc':16,'dif':16, 'pts':28},
              {'color':0,'shortname':'huachipato', 'rivalName':'Huachipato', 'pj':17, 'pg':6,'pe':2,'pp':9,'gf':24,'gc':26,'dif':-2, 'pts':20},
              {'color':'#295e1e','shortname':'udechile', 'rivalName':'U. de Chile', 'pj':17, 'pg':9,'pe':6,'pp':2,'gf':27,'gc':14,'dif':13, 'pts':30},
              {'color':0,'shortname':'rangers', 'rivalName':'Rangers', 'pj':17, 'pg':3,'pe':6,'pp':8,'gf':19,'gc':33,'dif':-14, 'pts':15},
              {'color':0,'shortname':'wanderers', 'rivalName':'S. Wanderers', 'pj':17, 'pg':4,'pe':2,'pp':11,'gf':24,'gc':36,'dif':-12, 'pts':14},
              {'color':'#295e1e','shortname':'union', 'rivalName':'U. Española', 'pj':17, 'pg':8,'pe':5,'pp':6,'gf':24,'gc':20,'dif':4, 'pts':23},
              {'color':'#295e1e','shortname':'temuco', 'rivalName':'Dep. Temuco', 'pj':17, 'pg':4,'pe':9,'pp':4,'gf':34,'gc':36,'dif':-2, 'pts':21},
	      {'color':0,'shortname':'laserena', 'rivalName':'La Serena', 'pj':17, 'pg':4,'pe':4,'pp':9,'gf':23,'gc':38,'dif':-15, 'pts':16},
	      {'color':'#295e1e','shortname':'everton', 'rivalName':'Everton', 'pj':17, 'pg':6,'pe':4,'pp':7,'gf':20,'gc':26,'dif':-6, 'pts':22},
              {'color':0,'shortname':'palestino', 'rivalName':'Palestino', 'pj':17, 'pg':6,'pe':3,'pp':8,'gf':23,'gc':28,'dif':-5, 'pts':21},
              {'color':'#295e1e','shortname':'sanfelipe', 'rivalName':'U. San Felipe', 'pj':17, 'pg':5,'pe':6,'pp':6,'gf':18,'gc':25,'dif':-7, 'pts':18},
              {'color':'#295e1e','shortname':'coquimbo', 'rivalName':'Coquimbo Unido', 'pj':17, 'pg':4,'pe':6,'pp':7,'gf':21,'gc':26,'dif':-5, 'pts':18}],

        table3:[{'color':0,'shortname':'cobreloa', 'rivalName':'Cobreloa', 'pj':34, 'pg':17,'pe':11,'pp':6,'gf':76,'gc':45,'dif':31, 'pts':62},
              {'color':0,'shortname':'audax', 'rivalName':'Audax Italiano', 'pj':34, 'pg':12,'pe':11,'pp':11,'gf':64,'gc':52,'dif':12, 'pts':47},
              {'color':0,'shortname':'puertomontt', 'rivalName':'Puerto Montt', 'pj':34, 'pg':10,'pe':10,'pp':14,'gf':43,'gc':56,'dif':-13, 'pts':37},
              {'color':0,'shortname':'udeconce', 'rivalName':'U. de Concepción', 'pj':34, 'pg':17,'pe':9,'pp':8,'gf':59,'gc':33,'dif':26, 'pts':60},
              {'color':0,'shortname':'catolica', 'rivalName':'U. Católica', 'pj':34, 'pg':15,'pe':6,'pp':13,'gf':61,'gc':48,'dif':13, 'pts':51},
              {'color':'#295e1e','shortname':'colocolo', 'rivalName':'Colo Colo', 'pj':34, 'pg':19,'pe':8,'pp':7,'gf':57,'gc':43,'dif':14, 'pts':65},
              {'color':0,'shortname':'cobresal', 'rivalName':'Cobresal', 'pj':34, 'pg':9,'pe':7,'pp':18,'gf':51,'gc':66,'dif':-15, 'pts':34},
              {'color':0,'shortname':'huachipato', 'rivalName':'Huachipato', 'pj':34, 'pg':14,'pe':5,'pp':15,'gf':59,'gc':57,'dif':2, 'pts':47},
              {'color':0,'shortname':'udechile', 'rivalName':'U. de Chile', 'pj':34, 'pg':18,'pe':9,'pp':7,'gf':59,'gc':35,'dif':24, 'pts':57},
              {'color':0,'shortname':'rangers', 'rivalName':'Rangers', 'pj':34, 'pg':8,'pe':9,'pp':17,'gf':40,'gc':66,'dif':-26, 'pts':33},
              {'color':0,'shortname':'wanderers', 'rivalName':'S. Wanderers', 'pj':34, 'pg':15,'pe':5,'pp':14,'gf':61,'gc':51,'dif':10, 'pts':50},
              {'color':0,'shortname':'union', 'rivalName':'U. Española', 'pj':34, 'pg':13,'pe':10,'pp':11,'gf':59,'gc':52,'dif':7, 'pts':49},
              {'color':0,'shortname':'temuco', 'rivalName':'Dep. Temuco', 'pj':34, 'pg':11,'pe':12,'pp':11,'gf':58,'gc':70,'dif':-12, 'pts':45},
              {'color':0,'shortname':'laserena', 'rivalName':'La Serena', 'pj':34, 'pg':9,'pe':7,'pp':18,'gf':55,'gc':84,'dif':-29, 'pts':34},
              {'color':0,'shortname':'everton', 'rivalName':'Everton', 'pj':34, 'pg':12,'pe':7,'pp':15,'gf':42,'gc':51,'dif':-9, 'pts':43},
              {'color':0,'shortname':'palestino', 'rivalName':'Palestino', 'pj':34, 'pg':10,'pe':5,'pp':19,'gf':57,'gc':70,'dif':-13, 'pts':35},
              {'color':0,'shortname':'sanfelipe', 'rivalName':'U. San Felipe', 'pj':34, 'pg':9,'pe':11,'pp':14,'gf':33,'gc':52,'dif':-19, 'pts':32},
              {'color':0,'shortname':'coquimbo', 'rivalName':'Coquimbo Unido', 'pj':34, 'pg':13,'pe':8,'pp':13,'gf':60,'gc':63,'dif':-3, 'pts':47}]

    };
  }

  componentDidMount() {
		this.getKits();
		let _newtable = this.sortTable(this.state.table1);
                this.setState({table1:_newtable});
		let newtable = this.sortTable(this.state.table2);
  		this.setState({table2:newtable});
		let newtable2 = this.sortTable(this.state.table3);
                this.setState({table3:newtable2});
	}

getKits(){

        fetch("https://www.registrosudec.com:5000/kits", {

    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      id_list: this.state.id_list
    })
    }).then(
                res => res.json()
                )
  .then(
    (result) => {
        console.log(result)
        this.setState({ 
        kits: result['kits']}) //[{path:"45.png", kit:yellow, short:"blue", soc$
    },
    // Nota: es importante manejar errores aqu   y no en
    // un bloque catch() para que no interceptemos errores
    // de errores reales en los componentes.
    (error) => {
      console.log(error);
      this.setState({fecha: []})
    }
  );
}


  handleChange(val){
    console.log(val);
    if(val===1){
      this.setState({showCards:true});
    }
    else{
      this.setState({showCards:false});
    }
  }

comeBack(){
this.setState({path:this.state.path});
}

subText(color, text){

let comp = [];
comp.push(

	<Row className="justify-content-md-center">
            <Col lg={2} xl={2} >

        <Table bordered={ false } size="sm">
        <tr style={{border: 'none', textAlign: 'rigth' }}>
        <td bgcolor={color}>
        </td>
        <td style={{border: 'none', textAlign: 'center'}}> {text} </td>
        </tr>
        </Table>
        </Col>
        </Row>



)
return comp;

}


sortTable(table){
       let aux;
      for(let i=table.length-1;i>0;i--){
             for(let j =0; j<i; j++){
                 if( (table[j].pts < table[j+1].pts) || 
                     ((table[j].pts === table[j+1].pts) && (table[j].dif < table[j+1].dif)) ||
                     ((table[j].pts === table[j+1].pts) && (table[j].dif === table[j+1].dif) && (table[j].gf < table[j+1].gf))){
			aux = table[j+1];
			table[j+1] = table[j];
                        table[j] = aux;
		} 
	     }		
	}
return table;

}


render(){


  let subT = this.subText('#295e1e', 'Clasificado a Play-Off');
  let subC = this.subText('#295e1e', 'Clasificado a Libertadores 2005');
  return(

	<div style={{textAlign: 'center' }}>
	 <div style={{ height: '50px' }}></div>
        <div className="mytitlepartidos">  Indumentaria UDEC Clausura 2004</div>
        <div style={{ height: '50px' }}></div> 

        <div style={{display: 'inline-block' }}>
        <table style={{textAlign: 'center' }}>
        <tr>
           {this.state.kits.map((i) => {
                   return ( <td> <Image  className="escudo-home" src={this.state.kit_path+i['path']+".png"}/> </td>)
            })}
        </tr>
        <tr>
        {this.state.kits.map((i) => {
                   return ( <td> <Image  className="myshort" src={this.state.kit_path+i['short']+".png"}/> </td>)
            })}
        </tr>
        <tr>
        {this.state.kits.map((i) => {
                   return ( <td> <Image  className="myshort" src={this.state.kit_path+'socks-'+i['socks']+".png"}/> </td>)
            })}
        </tr>

        <tr>
                {this.state.kits.map((i) => {
                   return ( <td class="circle"> {i['count']} </td>)
            })}
        </tr>
        </table>
        </div>

      <div style={{ height: '50px' }}></div>
	<div className="mytitlepartidos"> Grupo 4 </div>
	<div style={{ height: '10px' }}></div>
	{<Tabla teams={this.state.table1} />}
        <div style={{ height: '10px' }}></div>
         {subT}


	<div style={{ height: '50px' }}></div>
	<div className="mytitlepartidos"> Tabla General</div>
	<div style={{ height: '10px' }}></div>
        {<Tabla teams={this.state.table2} />}

	<div> 3 puntos menos a P. Montt, San Felipe y U. de Chile por no pagar sueldos </div> 
	<div style={{ height: '10px' }}></div>
	{subT}
	
	<div style={{ height: '50px' }}></div>
        <div className="mytitlepartidos"> Tabla Anual 2004</div>
        <div style={{ height: '10px' }}></div>
        {<Tabla teams={this.state.table3} />}

        <div> 3 puntos menos a P. Montt, San Felipe y U. de Chile por no pagar sueldos </div> 
        <div style={{ height: '10px' }}></div>
        {subC}


	<div style={{ height: '100px' }}></div>
	<div className="mytitlepartidos">  Partidos UDEC Clausura 2004</div>
	<div style={{ height: '10px' }}></div>

	<ToggleButtonGroup type="radio" name="options" defaultValue={1} onChange={this.handleChange}>
            <ToggleButton variant="secondary" value={1}>Tarjetas</ToggleButton>
            <ToggleButton variant="secondary" value={2}>Listado</ToggleButton>
          </ToggleButtonGroup>
	 <div style={{ height: '10px' }}></div>
	<Container>
	<div className={this.state.showCards ? '': 'hiddenCard'}>
           <MatchCardGroup id_list={this.state.id_list}></MatchCardGroup>
         </div>
         <div className={this.state.showCards ? 'hiddenCard': ''}>

           <MatchList id_list={this.state.id_list} showName={true}></MatchList>

         </div>
	</Container>

	<div style={{ height: '100px' }}></div>
        <div className="mytitlepartidos">  Jugadores UDEC Clausura 2004</div>
        <div style={{ height: '10px' }}></div>

	<Players torneo="Clausura 2004" />
     </div>


  )
}

}
export default Clausura2004;
