import React from 'react';
import Container from 'react-bootstrap/Container';
import Image from 'react-bootstrap/Image';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Table from 'react-bootstrap/Table';
import Tabla from '../Tabla';
import MatchList from '../MatchList';
import MatchCardGroup from '../MatchCardGroup';
import Players from '../Players';
import ToggleButtonGroup from 'react-bootstrap/ToggleButtonGroup';
import ToggleButton from 'react-bootstrap/ToggleButton';
class Campeonato2018 extends React.Component {

  constructor(props){
    super(props);
        this.comeBack = this.comeBack.bind(this);
	this.handleChange = this.handleChange.bind(this);
	var id_list = [];
	for (var i = 946; i <= 978; i++) {
		if(i!==947 && i!==962 && i!==963){
        	id_list.push(i);
		}
        }
    this.state = {
	showCards: true,
	                kit_path: '/images/kits/todas/',
      kits: [],
      path: this.props.path,
      ctype: this.props.ctype,
      torneo: this.props.torneo,
      id_list: id_list,


        table3:[{'color':'#E42727','shortname':'sanluis', 'rivalName':'San Luis', 'pj':30, 'pg':5,'pe':8,'pp':17,'gf':24,'gc':48,'dif':-24, 'pts':23},
              {'color':0,'shortname':'audax', 'rivalName':'Audax Italiano', 'pj':30, 'pg':8,'pe':10,'pp':12,'gf':40,'gc':40,'dif':0, 'pts':34},
              {'color':'#295e1e','shortname':'lacalera', 'rivalName':'U. La Calera', 'pj':30, 'pg':13,'pe':4,'pp':13,'gf':38,'gc':38,'dif':0, 'pts':43},
              {'color':'#135683','shortname':'udeconce', 'rivalName':'U. de Concepción', 'pj':30, 'pg':18,'pe':4,'pp':8,'gf':45,'gc':31,'dif':14, 'pts':58},
              {'color':'#135683','shortname':'catolica', 'rivalName':'U. Católica', 'pj':30, 'pg':17,'pe':10,'pp':3,'gf':39,'gc':25,'dif':14, 'pts':61},
              {'color':'#295e1e','shortname':'colocolo', 'rivalName':'Colo Colo', 'pj':30, 'pg':12,'pe':7,'pp':11,'gf':40,'gc':38,'dif':2, 'pts':43},
              {'color':0,'shortname':'curico', 'rivalName':'Curicó', 'pj':30, 'pg':8,'pe':10,'pp':12,'gf':35,'gc':41,'dif':-6, 'pts':34},
              {'color':0,'shortname':'huachipato', 'rivalName':'Huachipato', 'pj':30, 'pg':10,'pe':9,'pp':11,'gf':39,'gc':32,'dif':7, 'pts':39},
              {'color':'#135683','shortname':'udechile', 'rivalName':'U. de Chile', 'pj':30, 'pg':18,'pe':3,'pp':9,'gf':45,'gc':37,'dif':8, 'pts':57},
              {'color':0,'shortname':'everton', 'rivalName':'Everton', 'pj':30, 'pg':9,'pe':7,'pp':14,'gf':39,'gc':44,'dif':-5, 'pts':34},
              {'color':0,'shortname':'iquique', 'rivalName':'Dep. Iquique', 'pj':30, 'pg':7,'pe':11,'pp':12,'gf':34,'gc':42,'dif':-8, 'pts':32},
              {'color':'#295e1e','shortname':'union', 'rivalName':'U. Española', 'pj':30, 'pg':9,'pe':14,'pp':7,'gf':44,'gc':42,'dif':2, 'pts':41},
              {'color':0,'shortname':'palestino', 'rivalName':'Palestino', 'pj':30, 'pg':7,'pe':11,'pp':12,'gf':41,'gc':45,'dif':-4, 'pts':32},
              {'color':0,'shortname':'ohiggins', 'rivalName':'O\'Higgins', 'pj':30, 'pg':12,'pe':5,'pp':13,'gf':41,'gc':41,'dif':0, 'pts':41},
                {'color':'#E42727','shortname':'temuco', 'rivalName':'Dep. Temuco', 'pj':30, 'pg':6,'pe':10,'pp':14,'gf':29,'gc':45,'dif':-16, 'pts':28},
              {'color':'#295e1e','shortname':'antofagasta', 'rivalName':'Antofagasta', 'pj':30, 'pg':14,'pe':11,'pp':5,'gf':48,'gc':33,'dif':15, 'pts':53}],
    };
  }

  componentDidMount() {
		this.getKits();
		let newtable2 = this.sortTable(this.state.table3);
                this.setState({table3:newtable2});
	}


getKits(){

        fetch("https://www.registrosudec.com:5000/kits", {

    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      id_list: this.state.id_list
    })
    }).then(
                res => res.json()
                )
  .then(
    (result) => {
        console.log(result)
        this.setState({ 
        kits: result['kits']}) //[{path:"45.png", kit:yellow, short:"blue", socks:"blue", count:15, 
    },
    // Nota: es importante manejar errores aqu   y no en
    // un bloque catch() para que no interceptemos errores
    // de errores reales en los componentes.
    (error) => {
      console.log(error);
      this.setState({fecha: []})
    }
  );
}


  handleChange(val){
    console.log(val);
    if(val===1){
      this.setState({showCards:true});
    }
    else{
      this.setState({showCards:false});
    }
  }

comeBack(){
this.setState({path:this.state.path});
}

subText(color, text){

let comp = [];
comp.push(

	<Row className="justify-content-md-center">
            <Col lg={3} xl={3} >

        <Table bordered={ false } size="sm">
        <tr style={{border: 'none', textAlign: 'rigth' }}>
        <td bgcolor={color} style={{width:'20px'}}>
        </td>
        <td style={{border: 'none', textAlign: 'center'}}> {text} </td>
        </tr>
        </Table>
        </Col>
        </Row>



)
return comp;

}


sortTable(table){
       let aux;
      for(let i=table.length-1;i>0;i--){
             for(let j =0; j<i; j++){
                 if( (table[j].pts < table[j+1].pts) || 
                     ((table[j].pts === table[j+1].pts) && (table[j].dif < table[j+1].dif)) ||
                     ((table[j].pts === table[j+1].pts) && (table[j].pg === table[j+1].pg) && (table[j].dif < table[j+1].dif)) ||
                     ((table[j].pts === table[j+1].pts) && (table[j].pg === table[j+1].pg) && (table[j].dif === table[j+1].dif) && (table[j].gf < table[j+1].gf))){
                        aux = table[j+1];
                        table[j+1] = table[j];
                        table[j] = aux;
                } 
             }          
        }
return table;

}



render(){


  let subT = this.subText('#295e1e', 'Clasificado a Sudamericana 2019');
  let subC = this.subText('#135683', 'Clasificado a Libertadores 2019');
  let subD = this.subText('#E42727', 'Desciende a Primera B');
  return(

	<div style={{textAlign: 'center' }}>
	   <div style={{ height: '50px' }}></div>
        <div className="mytitlepartidos">  Indumentaria UDEC Campeonato 2018</div>
        <div style={{ height: '50px' }}></div> 

        <div style={{display: 'inline-block' }}>
        <table style={{textAlign: 'center' }}>
        <tr>
           {this.state.kits.map((i) => {
                   return ( <td> <Image  className="escudo-home" src={this.state.kit_path+i['path']+".png"}/> </td>)
            })}
        </tr>
        <tr>
        {this.state.kits.map((i) => {
                   return ( <td> <Image  className="myshort" src={this.state.kit_path+i['short']+".png"}/> </td>)
            })}
        </tr>
        <tr>
        {this.state.kits.map((i) => {
                   return ( <td> <Image  className="myshort" src={this.state.kit_path+'socks-'+i['socks']+".png"}/> </td>)
            })}
        </tr>

        <tr>
                {this.state.kits.map((i) => {
                   return ( <td class="circle"> {i['count']} </td>)
            })}
        </tr>
        </table>
        </div>


	<div style={{ height: '50px' }}></div>
	<div className="mytitlepartidos"> Tabla General</div>
	<div style={{ height: '10px' }}></div>
        {<Tabla teams={this.state.table3} />}
	<div style={{ height: '10px' }}></div>
        <div style={{ height: '10px' }}></div>
	{subC}
	{subT}
 	{subD}


	<div style={{ height: '100px' }}></div>
	<div className="mytitlepartidos">  Partidos UDEC Campeonato 2018</div>
	<div style={{ height: '10px' }}></div>

	<ToggleButtonGroup type="radio" name="options" defaultValue={1} onChange={this.handleChange}>
            <ToggleButton variant="secondary" value={1}>Tarjetas</ToggleButton>
            <ToggleButton variant="secondary" value={2}>Listado</ToggleButton>
          </ToggleButtonGroup>
	 <div style={{ height: '10px' }}></div>
	<Container>
	<div className={this.state.showCards ? '': 'hiddenCard'}>
           <MatchCardGroup id_list={this.state.id_list}></MatchCardGroup>
         </div>
         <div className={this.state.showCards ? 'hiddenCard': ''}>

           <MatchList id_list={this.state.id_list} showName={true}></MatchList>

         </div>
	</Container>

	<div style={{ height: '100px' }}></div>
        <div className="mytitlepartidos">  Jugadores UDEC Campeonato 2018</div>
        <div style={{ height: '10px' }}></div>

	<Players torneo="Campeonato 2018" />
     </div>


  )
}

}
export default Campeonato2018;
