import React from 'react';
import Container from 'react-bootstrap/Container';
import Image from 'react-bootstrap/Image';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Table from 'react-bootstrap/Table';
import Tabla from '../Tabla';
import MatchList from '../MatchList';
import MatchCardGroup from '../MatchCardGroup';
import Players from '../Players';
import ToggleButtonGroup from 'react-bootstrap/ToggleButtonGroup';
import ToggleButton from 'react-bootstrap/ToggleButton';
import TablaProm2015 from '../TablaProm2015';
class Clausura2015 extends React.Component {

  constructor(props){
    super(props);
        this.comeBack = this.comeBack.bind(this);
	this.handleChange = this.handleChange.bind(this);
	var id_list = [];
	for (var i = 824; i <= 843; i++) {
		if(i != 826 && i != 828 && i != 838){
	        	id_list.push(i);
		}
        }
    this.state = {
	showCards: true,
      path: this.props.path,
	kit_path: '/images/kits/todas/',
      kits: [],
      ctype: this.props.ctype,
      torneo: this.props.torneo,
      id_list: id_list,

	table2:[{'color':0,'shortname':'cobreloa', 'rivalName':'Cobreloa', 'pj':17, 'pg':6,'pe':4,'pp':7,'gf':22,'gc':23,'dif':-1, 'pts':19},
              {'color':0,'shortname':'audax', 'rivalName':'Audax Italiano', 'pj':17, 'pg':5,'pe':6,'pp':6,'gf':23,'gc':24,'dif':-1, 'pts':21},
              {'color':0,'shortname':'wanderers', 'rivalName':'S. Wanderers', 'pj':17, 'pg':4,'pe':5,'pp':8,'gf':21,'gc':26,'dif':-5, 'pts':17},
	      {'color':0,'shortname':'udeconce', 'rivalName':'U. de Concepción', 'pj':17, 'pg':8,'pe':3,'pp':6,'gf':27,'gc':26,'dif':1, 'pts':27},
              {'color':'#295e1e','shortname':'catolica', 'rivalName':'U. Católica', 'pj':17, 'pg':8,'pe':5,'pp':4,'gf':40,'gc':31,'dif':9, 'pts':29},
              {'color':0,'shortname':'colocolo', 'rivalName':'Colo Colo', 'pj':17, 'pg':10,'pe':2,'pp':5,'gf':32,'gc':21,'dif':11, 'pts':32},
              {'color':'#135683','shortname':'cobresal', 'rivalName':'Cobresal', 'pj':17, 'pg':10,'pe':4,'pp':3,'gf':29,'gc':20,'dif':9, 'pts':34},
              {'color':0,'shortname':'huachipato', 'rivalName':'Huachipato', 'pj':17, 'pg':9,'pe':4,'pp':4,'gf':29,'gc':28,'dif':1, 'pts':31},
              {'color':0,'shortname':'udechile', 'rivalName':'U. de Chile', 'pj':17, 'pg':8,'pe':2,'pp':7,'gf':36,'gc':28,'dif':8, 'pts':26},
              {'color':'#295e1e','shortname':'lacalera', 'rivalName':'U. La Calera', 'pj':17, 'pg':8,'pe':3,'pp':6,'gf':33,'gc':26,'dif':7, 'pts':27},
              {'color':0,'shortname':'iquique', 'rivalName':'Dep. Iquique', 'pj':17, 'pg':6,'pe':3,'pp':8,'gf':27,'gc':30,'dif':-3, 'pts':21},
              {'color':0,'shortname':'union', 'rivalName':'U. Española', 'pj':17, 'pg':6,'pe':5,'pp':6,'gf':20,'gc':23,'dif':-3, 'pts':23},
              {'color':0,'shortname':'barnechea', 'rivalName':'Barnechea', 'pj':17, 'pg':2,'pe':2,'pp':13,'gf':13,'gc':40,'dif':-27, 'pts':8},
	      {'color':'#295e1e','shortname':'arica', 'rivalName':'San Marcos', 'pj':17, 'pg':6,'pe':6,'pp':5,'gf':25,'gc':19,'dif':6, 'pts':24},
	      {'color':0,'shortname':'nublense', 'rivalName':'Ñublense', 'pj':17, 'pg':4,'pe':4,'pp':8,'gf':19,'gc':21,'dif':-2, 'pts':19},
              {'color':0,'shortname':'palestino', 'rivalName':'Palestino', 'pj':17, 'pg':5,'pe':4,'pp':8,'gf':26,'gc':34,'dif':-8, 'pts':19},
              {'color':'#295e1e','shortname':'ohiggins', 'rivalName':'O\'Higgins', 'pj':17, 'pg':6,'pe':6,'pp':5,'gf':21,'gc':23,'dif':-2, 'pts':24},
              {'color':0,'shortname':'antofagasta', 'rivalName':'Antofagasta', 'pj':17, 'pg':6,'pe':2,'pp':9,'gf':25,'gc':27,'dif':-2, 'pts':20}],

        table3:[{'color':0,'shortname':'cobreloa', 'rivalName':'Cobreloa', 'pj':34, 'pg':9,'pe':6,'pp':19,'gf':41,'gc':62,'dif':-21, 'pts':30},
              {'color':0,'shortname':'audax', 'rivalName':'Audax Italiano', 'pj':34, 'pg':11,'pe':11,'pp':12,'gf':49,'gc':45,'dif':4, 'pts':44},
              {'color':0,'shortname':'arica', 'rivalName':'San Marcos', 'pj':34, 'pg':11,'pe':9,'pp':14,'gf':38,'gc':40,'dif':-2, 'pts':42},
              {'color':0,'shortname':'udeconce', 'rivalName':'U. de Concepción', 'pj':34, 'pg':12,'pe':9,'pp':13,'gf':45,'gc':49,'dif':-4, 'pts':45},
              {'color':0,'shortname':'catolica', 'rivalName':'U. Católica', 'pj':34, 'pg':13,'pe':7,'pp':14,'gf':60,'gc':56,'dif':4, 'pts':46},
              {'color':0,'shortname':'colocolo', 'rivalName':'Colo Colo', 'pj':34, 'pg':23,'pe':4,'pp':7,'gf':66,'gc':32,'dif':34, 'pts':73},
              {'color':0,'shortname':'cobresal', 'rivalName':'Cobresal', 'pj':34, 'pg':14,'pe':9,'pp':11,'gf':53,'gc':50,'dif':3, 'pts':51},
              {'color':'#135683','shortname':'huachipato', 'rivalName':'Huachipato', 'pj':34, 'pg':17,'pe':6,'pp':11,'gf':59,'gc':53,'dif':6, 'pts':57},
              {'color':0,'shortname':'udechile', 'rivalName':'U. de Chile', 'pj':34, 'pg':22,'pe':4,'pp':8,'gf':73,'gc':40,'dif':33, 'pts':70},
              {'color':0,'shortname':'wanderers', 'rivalName':'S. Wanderers', 'pj':34, 'pg':18,'pe':6,'pp':10,'gf':52,'gc':40,'dif':12, 'pts':60},
              {'color':0,'shortname':'iquique', 'rivalName':'Dep. Iquique', 'pj':34, 'pg':9,'pe':10,'pp':15,'gf':49,'gc':61,'dif':-12, 'pts':37},
              {'color':0,'shortname':'union', 'rivalName':'U. Española', 'pj':34, 'pg':14,'pe':6,'pp':14,'gf':42,'gc':47,'dif':-5, 'pts':48},
              {'color':0,'shortname':'barnechea', 'rivalName':'Barnechea', 'pj':34, 'pg':8,'pe':4,'pp':22,'gf':29,'gc':66,'dif':-37, 'pts':28},
              {'color':0,'shortname':'nublense', 'rivalName':'Ñublense', 'pj':34, 'pg':10,'pe':8,'pp':16,'gf':40,'gc':50,'dif':-10, 'pts':38},
              {'color':0,'shortname':'lacalera', 'rivalName':'U. La Calera', 'pj':34, 'pg':14,'pe':7,'pp':13,'gf':58,'gc':48,'dif':10, 'pts':49},
              {'color':0,'shortname':'palestino', 'rivalName':'Palestino', 'pj':34, 'pg':15,'pe':5,'pp':14,'gf':54,'gc':55,'dif':-1, 'pts':50},
              {'color':0,'shortname':'ohiggins', 'rivalName':'O\'Higgins', 'pj':34, 'pg':12,'pe':11,'pp':11,'gf':48,'gc':48,'dif':0, 'pts':47},
              {'color':0,'shortname':'antofagasta', 'rivalName':'Antofagasta', 'pj':34, 'pg':10,'pe':6,'pp':18,'gf':38,'gc':52,'dif':-14, 'pts':36}],
 

		            table4:[
                        
               {'color':0,'shortname':'colocolo', 'rivalName':'Colo Colo', 'pts13':66, 'pts14':73,'pts':139,'pj':68,'pon':2.044},
               {'color':0,'shortname':'catolica', 'rivalName':'U. Católica', 'pts13':72, 'pts14':46,'pts':118,'pj':68,'pon':1.735},
                {'color':0,'shortname':'udechile', 'rivalName':'U. de Chile', 'pts13':47, 'pts14':70,'pts':117,'pj':68,'pon':1.720},
                {'color':0,'shortname':'ohiggins', 'rivalName':'O\'Higgins', 'pts13':69, 'pts14':47,'pts':116,'pj':68,'pon':1.705},
                 {'color':0,'shortname':'palestino', 'rivalName':'Palestino', 'pts13':53, 'pts14':50,'pts':103,'pj':68,'pon':1.514},
                  {'color':0,'shortname':'wanderers', 'rivalName':'S. Wanderers', 'pts13':40, 'pts14':60,'pts':100,'pj':68,'pon':1.470},
                   {'color':0,'shortname':'union', 'rivalName':'U. Española', 'pts13':50, 'pts14':48,'pts':98,'pj':68,'pon':1.441},
                     {'color':0,'shortname':'cobresal', 'rivalName':'Cobresal', 'pts13':45, 'pts14':51,'pts':96,'pj':68,'pon':1.411},
                      {'color':0,'shortname':'huachipato', 'rivalName':'Huachipato', 'pts13':38, 'pts14':56,'pts':95,'pj':68,'pon':1.397},
              {'color':0,'shortname':'udeconce', 'rivalName':'U. de Concepción', 'pts13':49, 'pts14':45,'pts':94,'pj':68,'pon':1.382},
                  {'color':0,'shortname':'iquique', 'rivalName':'Dep. Iquique', 'pts13':51, 'pts14':37,'pts':88,'pj':68,'pon':1.294},
               {'color':0,'shortname':'lacalera', 'rivalName':'U. La Calera', 'pts13':35, 'pts14':49,'pts':84,'pj':68,'pon':1.235},
                 {'color':0,'shortname':'arica', 'rivalName':'San Marcos', 'pts13':0, 'pts14':42,'pts':42,'pj':34,'pon':1.235},
                {'color':0,'shortname':'audax', 'rivalName':'Audax Italiano', 'pts13':36, 'pts14':44,'pts':80,'pj':68,'pon':1.176},
             {'color':0,'shortname':'antofagasta', 'rivalName':'Antofagasta', 'pts13':44, 'pts14':36,'pts':80,'pj':68,'pon':1.176},
                 {'color':'#E42727','shortname':'nublense', 'rivalName':'Ñublense', 'pts13':41, 'pts14':38,'pts':79,'pj':68,'pon':1.161},
              {'color':'#E42727','shortname':'cobreloa', 'rivalName':'Cobreloa', 'pts13':45, 'pts14':30,'pts':75,'pj':68,'pon':1.102},
                {'color':'#E42727','shortname':'barnechea', 'rivalName':'Barnechea', 'pts13':0, 'pts14':28,'pts':28,'pj':34,'pon':0.823}
              
              ]

  };
  }

  componentDidMount() {
		this.getKits();
		let newtable = this.sortTable(this.state.table2);
  		this.setState({table2:newtable});
		let newtable2 = this.sortTable(this.state.table3);
                this.setState({table3:newtable2});
	}


getKits(){

        fetch("https://www.registrosudec.com:5000/kits", {

    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      id_list: this.state.id_list
    })
    }).then(
                res => res.json()
                )
  .then(
    (result) => {
        console.log(result)
        this.setState({ 
        kits: result['kits']}) //[{path:"45.png", kit:yellow, short:"blue", socks:"blue", count:15, 
    },
    // Nota: es importante manejar errores aqu   y no en
    // un bloque catch() para que no interceptemos errores
    // de errores reales en los componentes.
    (error) => {
      console.log(error);
      this.setState({fecha: []})
    }
  );
}


  handleChange(val){
    console.log(val);
    if(val===1){
      this.setState({showCards:true});
    }
    else{
      this.setState({showCards:false});
    }
  }

comeBack(){
this.setState({path:this.state.path});
}

subText(color, text){

let comp = [];
comp.push(

	<Row className="justify-content-md-center">
            <Col lg={3} xl={3} >

        <Table bordered={ false } size="sm">
        <tr style={{border: 'none', textAlign: 'rigth' }}>
        <td bgcolor={color} style={{width:'20px'}}>
        </td>
        <td style={{border: 'none', textAlign: 'center'}}> {text} </td>
        </tr>
        </Table>
        </Col>
        </Row>



)
return comp;

}


sortTable(table){
       let aux;
      for(let i=table.length-1;i>0;i--){
             for(let j =0; j<i; j++){
                 if( (table[j].pts < table[j+1].pts) || 
                     ((table[j].pts === table[j+1].pts) && (table[j].dif < table[j+1].dif)) ||
                     ((table[j].pts === table[j+1].pts) && (table[j].pg === table[j+1].pg) && (table[j].dif < table[j+1].dif)) ||
                     ((table[j].pts === table[j+1].pts) && (table[j].pg === table[j+1].pg) && (table[j].dif === table[j+1].dif) && (table[j].gf < table[j+1].gf))){
                        aux = table[j+1];
                        table[j+1] = table[j];
                        table[j] = aux;
                } 
             }          
        }
return table;

}



render(){


  let subT = this.subText('#295e1e', 'Clasificado a Liguilla Sudamericana');
  let subC = this.subText('#135683', 'Clasificado a Libertadores 2016');
  let subD = this.subText('#E42727', 'Desciende a Primera B');
let subL = this.subText('#135683', 'Clasificado a Sudamericana 2015');
  return(

	<div style={{textAlign: 'center' }}>

	            <div style={{ height: '50px' }}></div>
        <div className="mytitlepartidos">  Indumentaria UDEC Clausura 2015</div>
        <div style={{ height: '50px' }}></div> 

        <div style={{display: 'inline-block' }}>
        <table style={{textAlign: 'center' }}>
        <tr>
           {this.state.kits.map((i) => {
                   return ( <td> <Image  className="escudo-home" src={this.state.kit_path+i['path']+".png"}/> </td>)
            })}
        </tr>
        <tr>
        {this.state.kits.map((i) => {
                   return ( <td> <Image  className="myshort" src={this.state.kit_path+i['short']+".png"}/> </td>)
            })}
        </tr>
        <tr>
        {this.state.kits.map((i) => {
                   return ( <td> <Image  className="myshort" src={this.state.kit_path+'socks-'+i['socks']+".png"}/> </td>)
            })}
        </tr>

        <tr>
                {this.state.kits.map((i) => {
                   return ( <td class="circle"> {i['count']} </td>)
            })}
        </tr>
        </table>
        </div>



	<div style={{ height: '50px' }}></div>
	<div className="mytitlepartidos"> Tabla General</div>
	<div style={{ height: '10px' }}></div>
        {<Tabla teams={this.state.table2} />}
	<div> * Cobreloa tiene 3 puntos menos</div>
        <div style={{ height: '10px' }}></div>
	{subC}
	{subT}
	
	<div style={{ height: '50px' }}></div>
        <div className="mytitlepartidos"> Tabla Anual 2014-2015</div>
        <div style={{ height: '10px' }}></div>
        {<Tabla teams={this.state.table3} />}
	<div> * Cobreloa tiene 3 puntos menos</div>
        <div style={{ height: '10px' }}></div>
        {subL}

	        <div style={{ height: '50px' }}></div>
        <div className="mytitlepartidos"> Tabla Descenso 2015</div>
        <div style={{ height: '10px' }}></div>
        {<TablaProm2015 teams={this.state.table4} />}

        <div style={{ height: '10px' }}></div>
        {subD}


	<div style={{ height: '100px' }}></div>
	<div className="mytitlepartidos">  Partidos UDEC Clausura 2015</div>
	<div style={{ height: '10px' }}></div>

	<ToggleButtonGroup type="radio" name="options" defaultValue={1} onChange={this.handleChange}>
            <ToggleButton variant="secondary" value={1}>Tarjetas</ToggleButton>
            <ToggleButton variant="secondary" value={2}>Listado</ToggleButton>
          </ToggleButtonGroup>
	 <div style={{ height: '10px' }}></div>
	<Container>
	<div className={this.state.showCards ? '': 'hiddenCard'}>
           <MatchCardGroup id_list={this.state.id_list}></MatchCardGroup>
         </div>
         <div className={this.state.showCards ? 'hiddenCard': ''}>

           <MatchList id_list={this.state.id_list} showName={true}></MatchList>

         </div>
	</Container>

	<div style={{ height: '100px' }}></div>
        <div className="mytitlepartidos">  Jugadores UDEC Clausura 2015</div>
        <div style={{ height: '10px' }}></div>

	<Players torneo="Clausura 2015" />
     </div>


  )
}

}
export default Clausura2015;
