import React from 'react';
import Card from 'react-bootstrap/Card';
import Container from 'react-bootstrap/Container';
import ToggleButtonGroup from 'react-bootstrap/ToggleButtonGroup';
import ToggleButton from 'react-bootstrap/ToggleButton';
import fondo from './images/fondo1.jpg';
import MatchCardGroup from './MatchCardGroup';



class Home extends React.Component {

  constructor(props){
    super(props);
    this.state = {
      shouldHide: false,
      matches: []
    };
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount(){

    var matches = [];
    var id1 = this.getRandomInt();
    matches.push(id1);

    var id2 = this.getRandomInt();
    while(id1 === id2){
       id2 = this.getRandomInt();
       console.log("obteniendo otro id2");
    }
    matches.push(id2);

    var id3 = this.getRandomInt();
    while (id3 === id1 || id3 === id2){
	id3 = this.getRandomInt();
	console.log("obteniendo otro id3");
    }
    matches.push(id3);

    matches.sort((a, b) => a - b);
    this.setState({matches:matches});
  }
  getRandomInt(){
    var min = 1;
    var max = 1165;
    return Math.floor(Math.random() * (max - min)) + min;
  }

  handleChange(val){
    console.log(val);
    if(val===1){
      this.setState({shouldHide:false});
    }
    else{
      this.setState({shouldHide:true});
    }
  }




render(){

  return(
    <div>

<div style={{ height: '100px' }}></div>
<Container>
<div>
<h2 className="text-center">
    Algunos recuerdos
  </h2>
</div>
<div style={{ height: '50px' }}></div>

<div className={this.state.shouldHide ? 'hiddenCard' : ''}>
<MatchCardGroup id_list={this.state.matches}></MatchCardGroup>
</div>

<div style={{ height: '30px' }}></div>

<span className="text-center">
    * Datos recopilados de distintas fuentes, especial agradecimiento para @CampanilDatos
  </span>


  </Container>




<div style={{ height: '100px' }}></div>


</div>


  )
}
}

export default Home;
