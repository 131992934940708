
import React from 'react';
import Carousel from 'react-bootstrap/Carousel'
class CarouselImg extends React.Component {


constructor(props){
    super(props);
console.log(this.props.id);
console.log(this.props.torneo);
console.log(this.props.n);
}

createElements(number){
    var elements = [];
    for(let i =1; i <= this.props.n; i++){
        elements.push(<Carousel.Item>

    	<img alt="" className="caritem d-block "  src={this.props.torneo+'/'+i+'.png'} />
  </Carousel.Item>)
    }
    return elements;
}

  render(){

    return(


	<Carousel slide={false} variant="dark"  >

		{this.createElements(10)}

	</Carousel>



    )

  }
}
export default CarouselImg;
















