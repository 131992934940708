import React from 'react';
import Container from 'react-bootstrap/Container';
import Image from 'react-bootstrap/Image';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Table from 'react-bootstrap/Table';
import Tabla from '../Tabla';
import MatchList from '../MatchList';
import MatchCardGroup from '../MatchCardGroup';
import Players from '../Players';
import ToggleButtonGroup from 'react-bootstrap/ToggleButtonGroup';
import ToggleButton from 'react-bootstrap/ToggleButton';
class Apertura2013 extends React.Component {

  constructor(props){
    super(props);
        this.comeBack = this.comeBack.bind(this);
	this.handleChange = this.handleChange.bind(this);
	var id_list = [];
	for (var i = 759; i <= 777; i++) {
              if(i!==765 && i!==770){
        	id_list.push(i);
		}
        }
    this.state = {
	showCards: true,
	kit_path: '/images/kits/todas/',
      kits: [],
      path: this.props.path,
      ctype: this.props.ctype,
      torneo: this.props.torneo,
      id_list: id_list,

	table2:[{'color':0,'shortname':'cobreloa', 'rivalName':'Cobreloa', 'pj':17, 'pg':6,'pe':5,'pp':6,'gf':19,'gc':18,'dif':1, 'pts':23},
              {'color':0,'shortname':'audax', 'rivalName':'Audax Italiano', 'pj':17, 'pg':4,'pe':5,'pp':8,'gf':23,'gc':25,'dif':-2, 'pts':17},
	     {'color':0,'shortname':'rangers', 'rivalName':'Rangers', 'pj':17, 'pg':4,'pe':3,'pp':10,'gf':20,'gc':32,'dif':-12, 'pts':15},
              {'color':'#295e1e','shortname':'iquique', 'rivalName':'Dep. Iquique', 'pj':17, 'pg':7,'pe':5,'pp':5,'gf':22,'gc':21,'dif':1, 'pts':26},
	      {'color':0,'shortname':'udeconce', 'rivalName':'U. de Concepción', 'pj':17, 'pg':5,'pe':7,'pp':5,'gf':21,'gc':19,'dif':2, 'pts':22},
              {'color':'#295e1e','shortname':'catolica', 'rivalName':'U. Católica', 'pj':17, 'pg':12,'pe':3,'pp':2,'gf':37,'gc':13,'dif':24, 'pts':39},
              {'color':0,'shortname':'colocolo', 'rivalName':'Colo Colo', 'pj':17, 'pg':7,'pe':3,'pp':7,'gf':22,'gc':23,'dif':-1, 'pts':24},
              {'color':0,'shortname':'cobresal', 'rivalName':'Cobresal', 'pj':17, 'pg':7,'pe':3,'pp':7,'gf':18,'gc':25,'dif':-7, 'pts':24},
              {'color':0,'shortname':'huachipato', 'rivalName':'Huachipato', 'pj':17, 'pg':3,'pe':4,'pp':10,'gf':9,'gc':20,'dif':-11, 'pts':13},
              {'color':'#295e1e','shortname':'udechile', 'rivalName':'U. de Chile', 'pj':17, 'pg':7,'pe':6,'pp':4,'gf':31,'gc':16,'dif':15, 'pts':27},
              {'color':0,'shortname':'lacalera', 'rivalName':'U. La Calera', 'pj':17, 'pg':3,'pe':6,'pp':8,'gf':15,'gc':24,'dif':-9, 'pts':15},
              {'color':0,'shortname':'antofagasta', 'rivalName':'Antofagasta', 'pj':17, 'pg':6,'pe':5,'pp':6,'gf':18,'gc':22,'dif':-4, 'pts':23},
              {'color':0,'shortname':'union', 'rivalName':'U. Española', 'pj':17, 'pg':9,'pe':1,'pp':7,'gf':21,'gc':15,'dif':6, 'pts':28},
              {'color':0,'shortname':'ohiggins', 'rivalName':'O\'Higgins', 'pj':17, 'pg':12,'pe':3,'pp':2,'gf':28,'gc':13,'dif':15, 'pts':39},
	      {'color':0,'shortname':'everton', 'rivalName':'Everton', 'pj':17, 'pg':5,'pe':1,'pp':11,'gf':16,'gc':29,'dif':-13, 'pts':16},
	      {'color':0,'shortname':'nublense', 'rivalName':'Ñublense', 'pj':17, 'pg':7,'pe':3,'pp':7,'gf':28,'gc':28,'dif':0, 'pts':24},
              {'color':'#295e1e','shortname':'palestino', 'rivalName':'Palestino', 'pj':17, 'pg':8,'pe':3,'pp':6,'gf':26,'gc':26,'dif':0, 'pts':27},
              {'color':0,'shortname':'wanderers', 'rivalName':'S. Wanderers', 'pj':17, 'pg':5,'pe':6,'pp':6,'gf':20,'gc':25,'dif':-5, 'pts':21}]

    };
  }

  componentDidMount() {
	this.getKits();
		let newtable = this.sortTable(this.state.table2);
  		this.setState({table2:newtable});

	}

getKits(){

        fetch("https://www.registrosudec.com:5000/kits", {

    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      id_list: this.state.id_list
    })
    }).then(
                res => res.json()
                )
  .then(
    (result) => {
        console.log(result)
        this.setState({ 
        kits: result['kits']}) //[{path:"45.png", kit:yellow, short:"blue", socks:"blue", count:15, 
    },
    // Nota: es importante manejar errores aqu   y no en
    // un bloque catch() para que no interceptemos errores
    // de errores reales en los componentes.
    (error) => {
      console.log(error);
      this.setState({fecha: []})
    }
  );
}



  handleChange(val){
    console.log(val);
    if(val===1){
      this.setState({showCards:true});
    }
    else{
      this.setState({showCards:false});
    }
  }

comeBack(){
this.setState({path:this.state.path});
}

subText(color, text){

let comp = [];
comp.push(

	<Row className="justify-content-md-center">
            <Col lg={3} xl={3} >

        <Table bordered={ false } size="sm">
        <tr style={{border: 'none', textAlign: 'rigth' }}>
        <td bgcolor={color} style={{width:'20px'}}>
        </td>
        <td style={{border: 'none', textAlign: 'center'}}> {text} </td>
        </tr>
        </Table>
        </Col>
        </Row>



)
return comp;

}


sortTable(table){
       let aux;
      for(let i=table.length-1;i>0;i--){
             for(let j =0; j<i; j++){
                 if( (table[j].pts < table[j+1].pts) || 
                     ((table[j].pts === table[j+1].pts) && (table[j].dif < table[j+1].dif)) ||
                     ((table[j].pts === table[j+1].pts) && (table[j].dif === table[j+1].dif) && (table[j].gf < table[j+1].gf))){
			aux = table[j+1];
			table[j+1] = table[j];
                        table[j] = aux;
		} 
	     }		
	}
return table;

}


render(){


  let subT = this.subText('#295e1e', 'Clasificado a Liguilla');
  return(

	<div style={{textAlign: 'center' }}>

	     <div style={{ height: '50px' }}></div>
        <div className="mytitlepartidos">  Indumentaria UDEC Apertura 2013</div>
        <div style={{ height: '50px' }}></div> 

        <div style={{display: 'inline-block' }}>
        <table style={{textAlign: 'center' }}>
        <tr>
           {this.state.kits.map((i) => {
                   return ( <td> <Image  className="escudo-home" src={this.state.kit_path+i['path']+".png"}/> </td>)
            })}
        </tr>
        <tr>
        {this.state.kits.map((i) => {
                   return ( <td> <Image  className="myshort" src={this.state.kit_path+i['short']+".png"}/> </td>)
            })}
        </tr>
        <tr>
        {this.state.kits.map((i) => {
                   return ( <td> <Image  className="myshort" src={this.state.kit_path+'socks-'+i['socks']+".png"}/> </td>)
            })}
        </tr>

        <tr>
                {this.state.kits.map((i) => {
                   return ( <td class="circle"> {i['count']} </td>)
            })}
        </tr>
        </table>
        </div>

	<div style={{ height: '50px' }}></div>
	<div className="mytitlepartidos"> Tabla General</div>
	<div style={{ height: '10px' }}></div>
        {<Tabla teams={this.state.table2} />}
        <div style={{ height: '10px' }}></div>
         {subT}
	 
	
	<div style={{ height: '100px' }}></div>
	<div className="mytitlepartidos">  Partidos UDEC Apertura 2013</div>
	<div style={{ height: '10px' }}></div>

	<ToggleButtonGroup type="radio" name="options" defaultValue={1} onChange={this.handleChange}>
            <ToggleButton variant="secondary" value={1}>Tarjetas</ToggleButton>
            <ToggleButton variant="secondary" value={2}>Listado</ToggleButton>
          </ToggleButtonGroup>
	 <div style={{ height: '10px' }}></div>
	<Container>
	<div className={this.state.showCards ? '': 'hiddenCard'}>
           <MatchCardGroup id_list={this.state.id_list}></MatchCardGroup>
         </div>
         <div className={this.state.showCards ? 'hiddenCard': ''}>

           <MatchList id_list={this.state.id_list} showName={true}></MatchList>

         </div>
	</Container>

	<div style={{ height: '100px' }}></div>
        <div className="mytitlepartidos">  Jugadores UDEC Apertura 2013</div>
        <div style={{ height: '10px' }}></div>

	<Players torneo="Apertura 2013" />
     </div>


  )
}

}
export default Apertura2013;
