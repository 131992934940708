import React from 'react';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import NavDropdown from 'react-bootstrap/NavDropdown';

import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import udeconce from './images/udeconce.png';

import MyCard from './MyCard';
import Equipos from './Equipos';
import Partido from './Partido';
import Partidos from './Partidos';
import Campeonatos from './Campeonatos';
import Torneo from './Torneo';
import Goleadores from './Goleadores';
import Goles from './Goles';

  class MyNavBar extends React.Component {
    constructor(props){
      super(props);
      this.state = {
        team_list: [],
        tournament_list: [],
	goleadores:[],
	torneo_list: [],
        partidos: []
      };
    }

    componentDidMount() {

	for (var i = 1; i <= 181; i++) {
                this.state.partidos.push(i);
      }

//      this.getlastmatch();
      this.getEquipos();
      this.getTorneos();
      this.getAllTorneos();
	this.getGoleadores();
    }

    getlastmatch(){
	fetch("https://www.registrosudec.com:5000/lastmatch_femenino")
     .then(res => res.json())
     .then(
       (result) => {
	let n =  result[0]['id'];
	for (var i = 1; i <= n; i++) {
	        this.state.partidos.push(i);
      }
	console.log(this.state.partidos);
       },
       // Nota: es importante manejar errores aqu   y no en
       // un bloque catch() para que no interceptemos errores
       // de errores reales en los componentes.
       (error) => {
         console.log(error);
         this.setState({tournament_list: []})
       }
     );



	}

	 getGoleadores(){

        fetch("https://www.registrosudec.com:5000/goleadores_femenino", {

    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      id: 0
    })
    }).then(
                res => res.json()
                )
  .then(
    (result) => {
        console.log(result)
        this.setState({ 
        goleadores: result['goles']}) //[{player:"name", goles:int, 
    },
    // Nota: es importante manejar errores aqu   y no en
    // un bloque catch() para que no interceptemos errores
    // de errores reales en los componentes.
    (error) => {
      console.log(error);
      this.setState({fecha: []})
    }
  );


}


    getAllTorneos(){
      fetch("https://www.registrosudec.com:5000/alltorneos_femenino")
     .then(res => res.json())
     .then(
       (result) => {
        this.setState({torneo_list: result})
       },
       // Nota: es importante manejar errores aqu   y no en
       // un bloque catch() para que no interceptemos errores
       // de errores reales en los componentes.
       (error) => {
         console.log(error);
         this.setState({torneo_list: []})
       }
     );
    }


    getTorneos(){
      fetch("https://www.registrosudec.com:5000/torneos_femenino")
     .then(res => res.json())
     .then(
       (result) => {
        this.setState({tournament_list: result})
       },
       // Nota: es importante manejar errores aquí y no en
       // un bloque catch() para que no interceptemos errores
       // de errores reales en los componentes.
       (error) => {
         console.log(error);
         this.setState({tournament_list: []})
       }
     );
    }


    getEquipos(){
      fetch("https://www.registrosudec.com:5000/equipos_femenino")
     .then(res => res.json())
     .then(
       (result) => {
        this.setState({team_list: result})
       },
       // Nota: es importante manejar errores aquí y no en
       // un bloque catch() para que no interceptemos errores
       // de errores reales en los componentes.
       (error) => {
         console.log(error);
         this.setState({team_list: []})
       }
     );
    }

   render(){

     return(
     <Router>

       <Navbar  bg="light" expand="lg">
         <Navbar.Brand href="/"><img
          src={udeconce}
          width="30"
          height="30"
          className="d-inline-block align-top"
          alt="React Bootstrap logo"/>
         </Navbar.Brand>
         <Navbar.Toggle aria-controls="basic-navbar-nav" />
         <Navbar.Collapse id="basic-navbar-nav">
         <Nav className="mr-auto">
           <Nav.Link className="mynav" href="/">Inicio</Nav.Link>

           <Nav.Link className="mynav" href="/partidos">Partidos</Nav.Link>
	   <Nav.Link className="mynav" href="/campeonatos">Campeonatos</Nav.Link>

           <NavDropdown className="mynav" title="Equipos" id="basic-nav-dropdown">
           <NavDropdown.Item href="/equipos">Todos</NavDropdown.Item>
           <NavDropdown.Divider />
           {this.state.team_list.map((team, i) => {
                          return (<NavDropdown.Item key={team['path']} href={"/equipos/" + team['path']}>{team['name']}</NavDropdown.Item>)
                   })}
            </NavDropdown>

          {/* <Nav.Link className="mynav" href="/estadios">Estadios</Nav.Link> */}
          { <Nav.Link className="mynav" href="/goleadores">Goleadores</Nav.Link> }
          {/* <Nav.Link className="mynav" href="/arbitros">Árbitros</Nav.Link> */}
          {/* <Nav.Link className="mynav" href="/records">Records</Nav.Link> */}



         </Nav>

       </Navbar.Collapse>
     </Navbar>

     <Switch>
       <Route exact path="/partidos">
         <Partidos />
       </Route>
       {this.state.partidos.map((id) => {
                  return (<Route path={"/partido/" + id}> <Partido key={id} id={id}/></Route>)
               }
             )
       }

       {this.state.tournament_list.map((torneo, i) => {
                  return (<Route path={"/campeonatos/" + torneo['path']}> <Campeonatos key={torneo['path']} path={torneo['path']} torneo={torneo['name']}/></Route>)
               }
             )
       }
	{this.state.goleadores.map((gol) => {
                  return (<Route path={"/goles/" + gol['name']}> <Goles key={gol['name']} path={gol['name']}  name={gol['player']}/></Route>)
               }
             )
       }

	{this.state.torneo_list.map((torneo, i) => {
                  return (<Route path={"/campeonatos/" + torneo['path']}> <Torneo key={torneo['path']} path={torneo['path']} torneo={torneo['name']}/></Route>)
               }
             )
       }
        


       <Route exact path="/campeonatos">
         <Campeonatos key="todos" path="todos" torneo="Todos"/>
       </Route>

       {this.state.team_list.map((team, i) => {
                  return (<Route path={"/equipos/" + team['path']}> <Equipos key={team['path']} short={team['path']} equipo={team['name']}/></Route>)
               }
             )
       }
       <Route exact path="/equipos">
         <Equipos key="todos" short="Todos" equipo="Todos"/>
       </Route>
	<Route exact path="/goleadores">
         <Goleadores />
       </Route>
       <Route path="/estadios">
         <Estadios />
       </Route>
	
       <Route path="/arbitros">
         <Arbitros />
       </Route>
       <Route path="/records">
         <Records />
       </Route>


        <Route exact path="/">
          <Home />
        </Route>


     </Switch>
     </Router>
   )

   }
}

function Home() {
  return (
      <MyCard />

  );
}








function Estadios() {
  return <h2>Estadios</h2>;
}
function Arbitros() {
  return <h2>Arbitros</h2>;
}
function Records() {
  return <h2>Records</h2>;
}
export default MyNavBar;
