import React from 'react';
import TableGroup from './TableGroup';


class Teams extends React.Component {

  constructor(props){
    super(props);
    this.state = {
      team_list: []
    };
  }

  componentDidMount(){

      this.getEquipos();

  }

   getEquipos(){
      fetch("https://www.registrosudec.com:5000/equipos")
     .then(res => res.json())
     .then(
       (result) => {
        result.push({'path':'todos', 'name':'Total'});
        this.setState({team_list: result})
       },
       // Nota: es importante manejar errores aquí y no en
       // un bloque catch() para que no interceptemos errores
       // de errores reales en los componentes.
       (error) => {
         console.log(error);
         this.setState({team_list: []})
       }
     );
    }



  render(){

    return(
      <div>
      <div style={{ height: '100px' }}></div>
       <div>
           <TableGroup team_list={this.state.team_list}></TableGroup>
         </div>

      </div>
    )

}
}
export default Teams;
