import React from 'react';
import Image from 'react-bootstrap/Image';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Table from 'react-bootstrap/Table';
import Tabla from '../Tabla';
import MatchList from '../MatchList';
import MatchCardGroup from '../MatchCardGroup';
import Players from '../Players';
import ToggleButtonGroup from 'react-bootstrap/ToggleButtonGroup';
import ToggleButton from 'react-bootstrap/ToggleButton';
class Clausura2003 extends React.Component {

  constructor(props){
    super(props);
        this.comeBack = this.comeBack.bind(this);
	this.handleChange = this.handleChange.bind(this);
	var id_list = [];
	for (var i = 323; i <= 341; i++) {
        	id_list.push(i);
        }
    this.state = {
	showCards: true,
      path: this.props.path,
      ctype: this.props.ctype,
	kit_path: '/images/kits/todas/',
      kits: [],
      torneo: this.props.torneo,
      id_list: id_list,
      table1:[{'color':'#295e1e','shortname':'udeconce', 'rivalName':'U. de Concepción', 'pj':15, 'pg':9,'pe':5,'pp':1,'gf':35,'gc':15,'dif':20, 'pts':32},
	      {'color':'#295e1e','shortname':'cobresal', 'rivalName':'Cobresal', 'pj':15, 'pg':6,'pe':4,'pp':5,'gf':29,'gc':26,'dif':3, 'pts':22},
	      {'color':'#295e1e','shortname':'rangers', 'rivalName':'Rangers', 'pj':15, 'pg':6,'pe':1,'pp':8,'gf':20,'gc':36,'dif':-16, 'pts':19},
	      {'color':0,'shortname':'sanfelipe', 'rivalName':'U. San Felipe', 'pj':15, 'pg':3,'pe':3,'pp':9,'gf':22,'gc':33,'dif':-11, 'pts':9}],

	table2:[{'color':'#295e1e','shortname':'cobreloa', 'rivalName':'Cobreloa', 'pj':15, 'pg':6,'pe':4,'pp':5,'gf':30,'gc':24,'dif':6, 'pts':22},
              {'color':'#295e1e','shortname':'audax', 'rivalName':'Audax Italiano', 'pj':15, 'pg':5,'pe':2,'pp':8,'gf':15,'gc':22,'dif':-7, 'pts':17},
              {'color':0,'shortname':'puertomontt', 'rivalName':'Puerto Montt', 'pj':15, 'pg':5,'pe':0,'pp':10,'gf':23,'gc':34,'dif':-11, 'pts':15},
	      {'color':'#295e1e','shortname':'udeconce', 'rivalName':'U. de Concepción', 'pj':15, 'pg':9,'pe':5,'pp':1,'gf':35,'gc':15,'dif':20, 'pts':32},
              {'color':'#295e1e','shortname':'catolica', 'rivalName':'U. Católica', 'pj':15, 'pg':6,'pe':2,'pp':7,'gf':22,'gc':24,'dif':-2, 'pts':20},
              {'color':'#295e1e','shortname':'colocolo', 'rivalName':'Colo Colo', 'pj':15, 'pg':9,'pe':5,'pp':1,'gf':39,'gc':19,'dif':20, 'pts':32},
              {'color':'#295e1e','shortname':'cobresal', 'rivalName':'Cobresal', 'pj':15, 'pg':6,'pe':4,'pp':5,'gf':29,'gc':26,'dif':3, 'pts':22},
              {'color':'#295e1e','shortname':'huachipato', 'rivalName':'Huachipato', 'pj':15, 'pg':6,'pe':2,'pp':7,'gf':30,'gc':33,'dif':-3, 'pts':20},
              {'color':'#295e1e','shortname':'udechile', 'rivalName':'U. de Chile', 'pj':15, 'pg':7,'pe':2,'pp':6,'gf':28,'gc':21,'dif':7, 'pts':23},
              {'color':'#295e1e','shortname':'rangers', 'rivalName':'Rangers', 'pj':15, 'pg':6,'pe':1,'pp':8,'gf':20,'gc':36,'dif':-16, 'pts':19},
              {'color':'#295e1e','shortname':'wanderers', 'rivalName':'S. Wanderers', 'pj':15, 'pg':7,'pe':6,'pp':2,'gf':27,'gc':22,'dif':5, 'pts':27},
              {'color':'#295e1e','shortname':'union', 'rivalName':'U. Española', 'pj':15, 'pg':9,'pe':4,'pp':2,'gf':36,'gc':22,'dif':14, 'pts':31},
              {'color':0,'shortname':'temuco', 'rivalName':'Dep. Temuco', 'pj':15, 'pg':3,'pe':1,'pp':11,'gf':18,'gc':38,'dif':-20, 'pts':10},
              {'color':'#295e1e','shortname':'palestino', 'rivalName':'Palestino', 'pj':15, 'pg':8,'pe':2,'pp':5,'gf':35,'gc':28,'dif':7, 'pts':26},
              {'color':0,'shortname':'sanfelipe', 'rivalName':'U. San Felipe', 'pj':15, 'pg':3,'pe':3,'pp':9,'gf':22,'gc':33,'dif':-11, 'pts':9},
              {'color':0,'shortname':'coquimbo', 'rivalName':'Coquimbo Unido', 'pj':15, 'pg':2,'pe':3,'pp':10,'gf':15,'gc':27,'dif':-12, 'pts':9}],

        table3:[{'color':0,'shortname':'cobreloa', 'rivalName':'Cobreloa', 'pj':30, 'pg':16,'pe':5,'pp':9,'gf':56,'gc':40,'dif':16, 'pts':53},
              {'color':0,'shortname':'audax', 'rivalName':'Audax Italiano', 'pj':30, 'pg':13,'pe':4,'pp':13,'gf':52,'gc':52,'dif':0, 'pts':43},
              {'color':0,'shortname':'puertomontt', 'rivalName':'Puerto Montt', 'pj':30, 'pg':12,'pe':4,'pp':14,'gf':49,'gc':56,'dif':-7, 'pts':40},
              {'color':'#295e1e','shortname':'udeconce', 'rivalName':'U. de Concepción', 'pj':30, 'pg':16,'pe':8,'pp':6,'gf':64,'gc':36,'dif':28, 'pts':56},
              {'color':0,'shortname':'catolica', 'rivalName':'U. Católica', 'pj':30, 'pg':12,'pe':8,'pp':10,'gf':49,'gc':44,'dif':5, 'pts':44},
              {'color':0,'shortname':'colocolo', 'rivalName':'Colo Colo', 'pj':30, 'pg':15,'pe':10,'pp':5,'gf':67,'gc':42,'dif':25, 'pts':55},
              {'color':0,'shortname':'cobresal', 'rivalName':'Cobresal', 'pj':30, 'pg':12,'pe':7,'pp':11,'gf':56,'gc':45,'dif':11, 'pts':43},
              {'color':0,'shortname':'huachipato', 'rivalName':'Huachipato', 'pj':30, 'pg':12,'pe':5,'pp':13,'gf':52,'gc':55,'dif':-3, 'pts':41},
              {'color':0,'shortname':'udechile', 'rivalName':'U. de Chile', 'pj':30, 'pg':11,'pe':10,'pp':9,'gf':58,'gc':45,'dif':13, 'pts':43},
              {'color':0,'shortname':'rangers', 'rivalName':'Rangers', 'pj':30, 'pg':9,'pe':12,'pp':9,'gf':48,'gc':62,'dif':-14, 'pts':39},
              {'color':0,'shortname':'wanderers', 'rivalName':'S. Wanderers', 'pj':30, 'pg':12,'pe':10,'pp':8,'gf':51,'gc':45,'dif':6, 'pts':46},
              {'color':0,'shortname':'union', 'rivalName':'U. Española', 'pj':30, 'pg':14,'pe':7,'pp':9,'gf':60,'gc':54,'dif':6, 'pts':49},
              {'color':0,'shortname':'temuco', 'rivalName':'Dep. Temuco', 'pj':30, 'pg':8,'pe':4,'pp':18,'gf':42,'gc':71,'dif':-29, 'pts':28},
              {'color':0,'shortname':'palestino', 'rivalName':'Palestino', 'pj':30, 'pg':13,'pe':4,'pp':13,'gf':53,'gc':56,'dif':-3, 'pts':43},
              {'color':0,'shortname':'sanfelipe', 'rivalName':'U. San Felipe', 'pj':30, 'pg':6,'pe':7,'pp':17,'gf':35,'gc':59,'dif':-24, 'pts':22},
              {'color':0,'shortname':'coquimbo', 'rivalName':'Coquimbo Unido', 'pj':30, 'pg':4,'pe':5,'pp':21,'gf':27,'gc':57,'dif':-30, 'pts':17}]





    };
  }

  componentDidMount() {
		this.getKits();
		let newtable = this.sortTable(this.state.table2);
  		this.setState({table2:newtable});
		let newtable2 = this.sortTable(this.state.table3);
                this.setState({table3:newtable2});
	}

getKits(){

        fetch("https://www.registrosudec.com:5000/kits", {

    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      id_list: this.state.id_list
    })
    }).then(
                res => res.json()
                )
  .then(
    (result) => {
        console.log(result)
        this.setState({ 
        kits: result['kits']}) //[{path:"45.png", kit:yellow, short:"blue", soc$
    },
    // Nota: es importante manejar errores aqu   y no en
    // un bloque catch() para que no interceptemos errores
    // de errores reales en los componentes.
    (error) => {
      console.log(error);
      this.setState({fecha: []})
    }
  );
}


  handleChange(val){
    console.log(val);
    if(val===1){
      this.setState({showCards:true});
    }
    else{
      this.setState({showCards:false});
    }
  }

comeBack(){
this.setState({path:this.state.path});
}

subText(color, text){

let comp = [];
comp.push(

	<Row className="justify-content-md-center">
            <Col lg={2} xl={2} >

        <Table bordered={ false } size="sm">
        <tr style={{border: 'none', textAlign: 'rigth' }}>
        <td bgcolor={color}>
        </td>
        <td style={{border: 'none', textAlign: 'center'}}> {text} </td>
        </tr>
        </Table>
        </Col>
        </Row>



)
return comp;

}


sortTable(table){
       let aux;
      for(let i=table.length-1;i>0;i--){
             for(let j =0; j<i; j++){
                 if( (table[j].pts < table[j+1].pts) || 
                     ((table[j].pts === table[j+1].pts) && (table[j].dif < table[j+1].dif)) ||
                     ((table[j].pts === table[j+1].pts) && (table[j].dif === table[j+1].dif) && (table[j].gf < table[j+1].gf))){
			aux = table[j+1];
			table[j+1] = table[j];
                        table[j] = aux;
		} 
	     }		
	}
return table;

}


render(){


  let subT = this.subText('#295e1e', 'Clasificado a Play-Off');
  let subC = this.subText('#295e1e', 'Clasificado a Libertadores 2004');
  return(

	<div style={{textAlign: 'center' }}>
	 <div style={{ height: '50px' }}></div>
        <div className="mytitlepartidos">  Indumentaria UDEC Clausura 2003</div>
        <div style={{ height: '50px' }}></div> 

        <div style={{display: 'inline-block' }}>
        <table style={{textAlign: 'center' }}>
        <tr>
           {this.state.kits.map((i) => {
                   return ( <td> <Image  className="escudo-home" src={this.state.kit_path+i['path']+".png"}/> </td>)
            })}
        </tr>
        <tr>
        {this.state.kits.map((i) => {
                   return ( <td> <Image  className="myshort" src={this.state.kit_path+i['short']+".png"}/> </td>)
            })}
        </tr>
        <tr>
        {this.state.kits.map((i) => {
                   return ( <td> <Image  className="myshort" src={this.state.kit_path+'socks-'+i['socks']+".png"}/> </td>)
            })}
        </tr>

        <tr>
                {this.state.kits.map((i) => {
                   return ( <td class="circle"> {i['count']} </td>)
            })}
        </tr>
        </table>
        </div>

      <div style={{ height: '50px' }}></div>
	<div className="mytitlepartidos"> Grupo 4 </div>
	<div style={{ height: '10px' }}></div>
	{<Tabla teams={this.state.table1} />}
        <div style={{ height: '10px' }}></div>
         {subT}


	<div style={{ height: '50px' }}></div>
	<div className="mytitlepartidos"> Tabla General</div>
	<div style={{ height: '10px' }}></div>
        {<Tabla teams={this.state.table2} />}
	<div> 3 puntos menos a San Felipe por no pagar sueldos </div>
        <div style={{ height: '10px' }}></div>
         {subT}

	<div style={{ height: '50px' }}></div>
        <div className="mytitlepartidos"> Tabla Anual 2003</div>
        <div style={{ height: '10px' }}></div>
        {<Tabla teams={this.state.table3} />}
        <div> 3 puntos menos a San Felipe por no pagar sueldos </div>
        <div style={{ height: '10px' }}></div>
         {subC}

	
	<div style={{ height: '100px' }}></div>
	<div className="mytitlepartidos">  Partidos UDEC Clausura 2003</div>
	<div style={{ height: '10px' }}></div>

	<ToggleButtonGroup type="radio" name="options" defaultValue={1} onChange={this.handleChange}>
            <ToggleButton variant="secondary" value={1}>Tarjetas</ToggleButton>
            <ToggleButton variant="secondary" value={2}>Listado</ToggleButton>
          </ToggleButtonGroup>
	 <div style={{ height: '10px' }}></div>
	<Container>
	<div className={this.state.showCards ? '': 'hiddenCard'}>
           <MatchCardGroup id_list={this.state.id_list}></MatchCardGroup>
         </div>
         <div className={this.state.showCards ? 'hiddenCard': ''}>

           <MatchList id_list={this.state.id_list} showName={true}></MatchList>

         </div>
	</Container>

	<div style={{ height: '100px' }}></div>
        <div className="mytitlepartidos">  Jugadores UDEC Clausura 2003</div>
        <div style={{ height: '10px' }}></div>

	<Players torneo="Clausura 2003" />
     </div>


  )
}

}
export default Clausura2003;
