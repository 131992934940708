import React from 'react';
import Image from 'react-bootstrap/Image';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Table from 'react-bootstrap/Table';
import Tabla from '../Tabla';
import MatchList from '../MatchList';
import MatchCardGroup from '../MatchCardGroup';
import Players from '../Players';
import ToggleButtonGroup from 'react-bootstrap/ToggleButtonGroup';
import ToggleButton from 'react-bootstrap/ToggleButton';
class PrimeraB2002 extends React.Component {

  constructor(props){
    super(props);
        this.comeBack = this.comeBack.bind(this);
	this.handleChange = this.handleChange.bind(this);
	var id_list = [];
	for (var i = 259; i <= 300; i++) {
        	id_list.push(i);
        }
    this.state = {
	showCards: true,
      path: this.props.path,
	kit_path: '/images/kits/todas/',
      kits: [],
      ctype: this.props.ctype,
      torneo: this.props.torneo,
      id_list: id_list,
      table0:[
              {'color':'#ff3355','shortname':'vial', 'rivalName':'Fernández Vial', 'pj':14, 'pg':5,'pe':4,'pp':5,'gf':21,'gc':18,'dif':3, 'pts':19},
              {'color':'#295e1e','shortname':'udeconce', 'rivalName':'U. de Concepción', 'pj':14, 'pg':9,'pe':2,'pp':3,'gf':28,'gc':14,'dif':14, 'pts':29},
	      {'color':'#295e1e','shortname':'melipilla', 'rivalName':'Melipilla', 'pj':14, 'pg':7,'pe':1,'pp':6,'gf':16,'gc':5,'dif':1, 'pts':22},
	      {'color':'#295e1e','shortname':'naval', 'rivalName':'Dep. Talcahuano', 'pj':14, 'pg':6,'pe':3,'pp':5,'gf':22,'gc':15,'dif':7, 'pts':21},
              {'color':'#ff3355','shortname':'osorno', 'rivalName':'Prov. Osorno', 'pj':14, 'pg':6,'pe':1,'pp':7,'gf':19,'gc':25,'dif':-6, 'pts':19},
	      {'color':'#295e1e','shortname':'puertomontt', 'rivalName':'Puerto Montt', 'pj':14, 'pg':6,'pe':3,'pp':5,'gf':17,'gc':15,'dif':2, 'pts':21},
              {'color':'#ff3355','shortname':'ohiggins', 'rivalName':'O\'Higgins', 'pj':14, 'pg':5,'pe':0,'pp':9,'gf':16,'gc':24,'dif':-8, 'pts':6},
	      {'color':'#ff3355','shortname':'lota', 'rivalName':'Lota Schwager', 'pj':14, 'pg':4,'pe':2,'pp':8,'gf':14,'gc':27,'dif':-13, 'pts':14}],

	table1:[
              {'color':'#295e1e','shortname':'lacalera', 'rivalName':'U. La Calera', 'pj':14, 'pg':6,'pe':2,'pp':6,'gf':24,'gc':24,'dif':0, 'pts':20},
              {'color':'#295e1e','shortname':'udeconce', 'rivalName':'U. de Concepción', 'pj':14, 'pg':5,'pe':5,'pp':4,'gf':23,'gc':19,'dif':4, 'pts':20},
              {'color':'#295e1e','shortname':'melipilla', 'rivalName':'Melipilla', 'pj':14, 'pg':4,'pe':5,'pp':5,'gf':16,'gc':17,'dif':-1, 'pts':17},
              {'color':'#ff3355','shortname':'laserena', 'rivalName':'La Serena', 'pj':14, 'pg':4,'pe':5,'pp':5,'gf':16,'gc':17,'dif':-1, 'pts':17},
              {'color':'#ff3355','shortname':'ovalle', 'rivalName':'Dep. Ovalle', 'pj':14, 'pg':2,'pe':3,'pp':9,'gf':21,'gc':35,'dif':-6, 'pts':9},
              {'color':'#295e1e','shortname':'puertomontt', 'rivalName':'Puerto Montt', 'pj':14, 'pg':6,'pe':2,'pp':6,'gf':18,'gc':18,'dif':0, 'pts':20},
              {'color':'#295e1e','shortname':'naval', 'rivalName':'Dep. Talcahuano', 'pj':14, 'pg':8,'pe':4,'pp':2,'gf':19,'gc':8,'dif':11, 'pts':28},
              {'color':'#295e1e','shortname':'everton', 'rivalName':'Everton', 'pj':14, 'pg':5,'pe':6,'pp':3,'gf':23,'gc':21,'dif':2, 'pts':21}],

	table2:[
              {'color':0,'shortname':'lacalera', 'rivalName':'U. La Calera', 'pj':14, 'pg':4,'pe':3,'pp':7,'gf':15,'gc':28,'dif':-13, 'pts':15},
              {'color':'#295e1e','shortname':'udeconce', 'rivalName':'U. de Concepción', 'pj':14, 'pg':6,'pe':5,'pp':3,'gf':21,'gc':16,'dif':5, 'pts':23},
              {'color':0,'shortname':'melipilla', 'rivalName':'Melipilla', 'pj':14, 'pg':5,'pe':6,'pp':3,'gf':20,'gc':14,'dif':6, 'pts':21},
	      {'color':0,'shortname':'ohiggins', 'rivalName':'O\'Higgins', 'pj':14, 'pg':5,'pe':4,'pp':5,'gf':20,'gc':18,'dif':2, 'pts':19},
              {'color':0,'shortname':'antofagasta', 'rivalName':'Antofagasta', 'pj':14, 'pg':4,'pe':4,'pp':6,'gf':17,'gc':19,'dif':-2, 'pts':13},
              {'color':'#295e1e','shortname':'puertomontt', 'rivalName':'Puerto Montt', 'pj':14, 'pg':8,'pe':3,'pp':3,'gf':24,'gc':17,'dif':7, 'pts':27},
              {'color':0,'shortname':'naval', 'rivalName':'Dep. Talcahuano', 'pj':14, 'pg':3,'pe':3,'pp':8,'gf':17,'gc':25,'dif':-8, 'pts':12},
              {'color':0,'shortname':'everton', 'rivalName':'Everton', 'pj':14, 'pg':5,'pe':4,'pp':5,'gf':19,'gc':16,'dif':3, 'pts':19}],

    };
  }

  componentDidMount() {
		this.getKits();
	let newtable = this.sortTable(this.state.table1);


                this.setState({table1:newtable});

	let newtable2 = this.sortTable(this.state.table2);
                this.setState({table2:newtable2});
	let newtable0 = this.sortTable(this.state.table0);
                this.setState({table0:newtable0});
        }


getKits(){

        fetch("https://www.registrosudec.com:5000/kits", {

    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      id_list: this.state.id_list
    })
    }).then(
                res => res.json()
                )
  .then(
    (result) => {
        console.log(result)
        this.setState({ 
        kits: result['kits']}) //[{path:"45.png", kit:yellow, short:"blue", soc$
    },
    // Nota: es importante manejar errores aqu   y no en
    // un bloque catch() para que no interceptemos errores
    // de errores reales en los componentes.
    (error) => {
      console.log(error);
      this.setState({fecha: []})
    }
  );
}

  handleChange(val){
    console.log(val);
    if(val===1){
      this.setState({showCards:true});
    }
    else{
      this.setState({showCards:false});
    }
  }

comeBack(){
this.setState({path:this.state.path});
}

subText(color, text){

let comp = [];
comp.push(

	<Row className="justify-content-md-center">
            <Col lg={2} xl={2} >

        <Table bordered={ false } size="sm">
        <tr style={{border: 'none', textAlign: 'rigth' }}>
        <td bgcolor={color}>
        </td>
        <td style={{border: 'none', textAlign: 'center'}}> {text} </td>
        </tr>
        </Table>
        </Col>
        </Row>



)
return comp;

}

sortTable(table){
       let aux;
      for(let i=table.length-1;i>0;i--){
             for(let j =0; j<i; j++){
                 if( (table[j].pts < table[j+1].pts) || 
                     ((table[j].pts === table[j+1].pts) && (table[j].dif < table[j+1].dif)) ||
                     ((table[j].pts === table[j+1].pts) && (table[j].dif === table[j+1].dif) && (table[j].gf < table[j+1].gf))){
                        aux = table[j+1];
                        table[j+1] = table[j];
                        table[j] = aux;
                } 
             }          
        }
return table;

}




render(){


  let subT = this.subText('#295e1e', 'Asciende a Primera A 2003');
  let subA = this.subText('#295e1e', 'Clasifica a Grupo A');
  let subB = this.subText('#ff3355', 'Clasifica a Grupo B');
  let subP = this.subText('#295e1e', 'Clasifica a Grupo Ascenso');
  let subD = this.subText('#ff3355', 'Clasifica a Grupo Descenso');

console.log(this.state.id_list)

  return(

	<div style={{textAlign: 'center' }}>
	 <div style={{ height: '50px' }}></div>
        <div className="mytitlepartidos">  Indumentaria UDEC Primera B 2002</div>
        <div style={{ height: '50px' }}></div> 

        <div style={{display: 'inline-block' }}>
        <table style={{textAlign: 'center' }}>
        <tr>
           {this.state.kits.map((i) => {
                   return ( <td> <Image  className="escudo-home" src={this.state.kit_path+i['path']+".png"}/> </td>)
            })}
        </tr>
        <tr>
        {this.state.kits.map((i) => {
                   return ( <td> <Image  className="myshort" src={this.state.kit_path+i['short']+".png"}/> </td>)
            })}
        </tr>
        <tr>
        {this.state.kits.map((i) => {
                   return ( <td> <Image  className="myshort" src={this.state.kit_path+'socks-'+i['socks']+".png"}/> </td>)
            })}
        </tr>

        <tr>
                {this.state.kits.map((i) => {
                   return ( <td class="circle"> {i['count']} </td>)
            })}
        </tr>
        </table>
        </div>

      <div style={{ height: '50px' }}></div>
	<div className="mytitlepartidos"> Grupo Sur </div>
	<div style={{ height: '10px' }}></div>
	{<Tabla teams={this.state.table0} />}
	<div style={{ height: '10px' }}></div>
	{subA}
	{subB}
	<div style={{ height: '50px' }}></div>
	<div className="mytitlepartidos"> Grupo A</div>
	<div style={{ height: '10px' }}></div>
        {<Tabla teams={this.state.table1} />}
        <div style={{ height: '10px' }}></div>
         {subP}
	 {subD}
	<div style={{ height: '50px' }}></div>
        <div className="mytitlepartidos"> Grupo Ascenso</div>
        <div style={{ height: '10px' }}></div>
        {<Tabla teams={this.state.table2} />}
        <div style={{ height: '10px' }}></div>
         {subT}

	
	<div style={{ height: '100px' }}></div>
	<div className="mytitlepartidos">  Partidos UDEC Primera B 2002</div>
	<div style={{ height: '10px' }}></div>

	<ToggleButtonGroup type="radio" name="options" defaultValue={1} onChange={this.handleChange}>
            <ToggleButton variant="secondary" value={1}>Tarjetas</ToggleButton>
            <ToggleButton variant="secondary" value={2}>Listado</ToggleButton>
          </ToggleButtonGroup>
	 <div style={{ height: '10px' }}></div>
	<Container>
	<div className={this.state.showCards ? '': 'hiddenCard'}>
           <MatchCardGroup id_list={this.state.id_list}></MatchCardGroup>
         </div>
         <div className={this.state.showCards ? 'hiddenCard': ''}>

           <MatchList id_list={this.state.id_list} showName={true}></MatchList>

         </div>
	</Container>

	<div style={{ height: '100px' }}></div>
        <div className="mytitlepartidos">  Jugadores UDEC Primera B 2002</div>
        <div style={{ height: '10px' }}></div>

	<Players torneo="Primera B 2002" />
     </div>


  )
}

}
export default PrimeraB2002;
