import React from 'react';
import Image from 'react-bootstrap/Image';
import Container from 'react-bootstrap/Container';


import Table from 'react-bootstrap/Table';


class Players extends React.Component {


constructor(props){
    super(props);
    this.state = {
      players: []
    };
 }

componentDidMount(){

     this.getPartidos();

  }


  getPartidos(){
    fetch("https://www.registrosudec.com:5000/players_femenino", {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      torneo: this.props.torneo
    })
    }).then(res => res.json())
  .then(
    (result) => {

     this.setState({players: result})
    },
    (error) => {
      console.log(error);
      this.setState({players: []})
    }
  )
  }




render(){


  return(

<Container>
	<Table responsive variant="dark"  striped bordered hover size="sm">
     <thead>
     <tr>
       <th>Nombre</th>
       <th>Posición</th>
       <th>Partidos</th>
       <th>Banca</th>
       <th>Amarillas</th>
       <th>Rojas</th>
        <th>Asistencias</th>
	<th>Goles</th>
     </tr>
   </thead>

     <tbody>


	{this.state.players.map((player, i) => {

        return(<tr style={{textAlign: 'center' }}>
                 <td ><Image   data-tip data-for={'udeconce-escudo'} className="list-bandera" src={'/images_fem/escudos/'+player.pais+'.png'}/>
                  {" " + player.name}
                 </td>
                 <td >{player.posicion}</td>
                 <td >{player.jugados}</td>
                 <td >{player.banca}</td>
                 <td >{player.amarillas}</td>
                 <td >{player.rojas}</td>
                 <td >{player.asistencias}</td>
                 <td >{player.goles}</td>
              </tr>);
        })}
	

            </tbody>


         </Table>

</Container>

  )
}
}
export default Players;
