import React from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Image from 'react-bootstrap/Image';


import Table from 'react-bootstrap/Table';



class Tabla extends React.Component {





render(){


  return(
    <Row className="justify-content-md-center">
    <Col lg={6} xl={6}  >
    <Table variant="dark" striped bordered hover size="sm">
  <thead>
    <tr style={{textAlign: 'center' }}>
	<th > </th>
	<th>Pos</th>
      <th>Equipo</th>
      <th>PJ</th>
      <th>PG</th>
      <th>PE</th>
      <th>PP</th>
      <th>GF</th>
      <th>GC</th>
	<th>DIF</th>
      <th>PTS</th>
    </tr>
  </thead>
  <tbody>
{this.props.teams.map((team, i) => {

	return(<tr style={{textAlign: 'center' }}>
		 {team.color !== 0 && <td bgcolor={team.color}></td>}
		 {team.color === 0 && <td></td>}
  		 <td >{i + 1}</td>
                 <td className="d-none d-sm-block"><Image   data-tip data-for={team.shortname+'-escudo'} className="list-escudo" src={'/images/escudos/'+team.shortname+'.png'}/>
                  {" " + team.rivalName}
                 </td>
		<td className="d-sm-none"><Image   data-tip data-for={team.shortname+'-escudo'} className="list-escudo" src={'/images/escudos/'+team.shortname+'.png'}/>
                 </td>
                 <td >{team.pj}</td>
                 <td >{team.pg}</td>
                 <td >{team.pe}</td>
                 <td >{team.pp}</td>
                 <td >{team.gf}</td>
                 <td >{team.gc}</td>
                 <td >{team.dif}</td>
		<td >{team.pts}</td>
              </tr>);
	})}

</tbody>
</Table>
</Col>
</Row>
  )
}
}
export default Tabla;
