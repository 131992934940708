import React from 'react';
import Image from 'react-bootstrap/Image';
import ReactTooltip from 'react-tooltip';
import { Link } from 'react-router-dom';


class RowComp extends React.Component {

  constructor(props){
    super(props);
    this.state = {
      rivalName: '',
      pj: 0,
      pg: 0,
      pe:0,
      pp: 0,
      gf: 0,
      gc: 0,
      dif: 0,
      h2h: 0,
      team_image:'',
    };
  }

  componentDidMount() {

    console.log(this.props.shortname);
    fetch("https://www.registrosudec.com:5000/vshistory", {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      team: this.props.shortname
    })
    }).then(res => res.json())
  .then(
    (result) => {
     this.setState({rivalName: this.props.name,
                    pj: result['pj'],
                    pg: result['pg'],
                    pe: result['pe'],
                    pp: result['pp'],
                    gf: result['gf'],
                    gc: result['gc'],
                    dif: result['dif'],
                    team_image: '/images/escudos/'+this.props.shortname+'.png'
                    })
    if(result['pg'] > result['pp']){
       this.setState({h2h:1})
    }
    else if(result['pg'] < result['pp']){
       this.setState({h2h:-1})
    }
    else{
       this.setState({h2h:0})
    }
    if(this.props.shortname === 'todos'){
       this.setState({team_image:''});
    }

    },
    // Nota: es importante manejar errores aquí y no en
    // un bloque catch() para que no interceptemos errores
    // de errores reales en los componentes.
    (error) => {
      console.log(error);
      this.setState({fecha: []})
    }
  )


}



normalize = (function() {
  var from = "ÃÀÁÄÂÈÉËÊÌÍÏÎÒÓÖÔÙÚÜÛãàáäâèéëêìíïîòóöôùúüûÑñÇç",
      to   = "AAAAAEEEEIIIIOOOOUUUUaaaaaeeeeiiiioooouuuunncc",
      mapping = {};

  for(var i = 0, j = from.length; i < j; i++ )
      mapping[ from.charAt( i ) ] = to.charAt( i );

  return function( str ) {
      var ret = [];
      for( var i = 0, j = str.length; i < j; i++ ) {
          var c = str.charAt( i );
          if( mapping.hasOwnProperty( str.charAt( i ) ) )
              ret.push( mapping[ c ] );
          else
              ret.push( c );
      }
      return ret.join( '' );
  }
 });

render(){

  return(

              <tr style={{textAlign: 'center' }}>
                 {this.state.team_image !== '' &&
                 <td ><Image   data-tip data-for={this.props.shortname+'-escudo'} className="list-escudo" src={this.state.team_image}/>
                  <ReactTooltip id={this.props.shortname+'-escudo'} type='light' effect='float'>
                    <span >{this.state.rivalName}</span>
                  </ReactTooltip>
                  <Link className="link-name" to={"/equipos/" + this.props.shortname}>{" " + this.state.rivalName}</Link>
                 </td>}
                 {this.state.team_image === '' &&
                 <td >
                  <ReactTooltip id={this.props.shortname+'-escudo'} type='light' effect='float'>
                    <span >{this.state.rivalName}</span>
                  </ReactTooltip>
                 {" " + this.state.rivalName}
                 </td>}
                 <td >{this.state.pj}</td>
                 <td >{this.state.pg}</td>
                 <td >{this.state.pe}</td>
                 <td >{this.state.pp}</td>
                 <td >{this.state.gf}</td>
                 <td >{this.state.gc}</td>
                 <td >{this.state.dif}</td>
              </tr>


  )
}
}
export default RowComp;
