import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Table from 'react-bootstrap/Table';
import Tabla from '../Tabla';
import MatchList from '../MatchList';
import MatchCardGroup from '../MatchCardGroup';
import Players from '../Players';
import ToggleButtonGroup from 'react-bootstrap/ToggleButtonGroup';
import ToggleButton from 'react-bootstrap/ToggleButton';
class Tercera1995 extends React.Component {

  constructor(props){
    super(props);
        this.comeBack = this.comeBack.bind(this);
	this.handleChange = this.handleChange.bind(this);
	var id_list = [];
	for (var i = 31; i <= 54; i++) {
        	id_list.push(i);
        }
    this.state = {
	showCards: true,
      path: this.props.path,
      ctype: this.props.ctype,
      torneo: this.props.torneo,
      id_list: id_list,
      table0:[
              {'color':'#ff3355','shortname':'iberia', 'rivalName':'Iberia', 'pj':14, 'pg':0,'pe':7,'pp':7,'gf':15,'gc':26,'dif':-11, 'pts':7},
              {'color':'#ff3355','shortname':'udeconce', 'rivalName':'U. de Concepción', 'pj':14, 'pg':4,'pe':6,'pp':4,'gf':21,'gc':19,'dif':2, 'pts':18},
	      {'color':'#295e1e','shortname':'malleco', 'rivalName':'Malleco', 'pj':14, 'pg':6,'pe':4,'pp':4,'gf':15,'gc':13,'dif':2, 'pts':22},
	      {'color':'#295e1e','shortname':'naval', 'rivalName':'Dep. Talcahuano', 'pj':14, 'pg':5,'pe':4,'pp':5,'gf':18,'gc':16,'dif':2, 'pts':19},
	      {'color':'#295e1e','shortname':'animas', 'rivalName':'Dep. Las Ánimas', 'pj':14, 'pg':5,'pe':4,'pp':5,'gf':18,'gc':20,'dif':-2, 'pts':19},
	      {'color':'#ff3355','shortname':'uss', 'rivalName':'U. San Sebastián', 'pj':14, 'pg':5,'pe':4,'pp':5,'gf':18,'gc':20,'dif':-2, 'pts':19},
	      {'color':'#295e1e','shortname':'mulchen', 'rivalName':'Mulchén U.', 'pj':14, 'pg':6,'pe':4,'pp':4,'gf':17,'gc':17,'dif':0, 'pts':22},
              {'color':'#295e1e','shortname':'laja', 'rivalName':'Dep. Laja', 'pj':14, 'pg':5,'pe':6,'pp':3,'gf':15,'gc':12,'dif':3, 'pts':21}],

	table1:[
              {'color':0,'shortname':'uss', 'rivalName':'U. San Sebastián', 'pj':10, 'pg':7,'pe':1,'pp':2,'gf':21,'gc':12,'dif':9, 'pts':22},
              {'color':0,'shortname':'udeconce', 'rivalName':'U. de Concepción', 'pj':10, 'pg':8,'pe':0,'pp':2,'gf':26,'gc':7,'dif':19, 'pts':24},
              {'color':0,'shortname':'iberia', 'rivalName':'Iberia', 'pj':10, 'pg':6,'pe':1,'pp':3,'gf':14,'gc':14,'dif':0, 'pts':19},
              {'color':0,'shortname':'arturoprat', 'rivalName':'Arturo Prat', 'pj':10, 'pg':3,'pe':2,'pp':5,'gf':11,'gc':15,'dif':-4, 'pts':11},
              {'color':'#ff3355','shortname':'maipo', 'rivalName':'Dep. Maipo', 'pj':10, 'pg':2,'pe':3,'pp':5,'gf':12,'gc':20,'dif':-8, 'pts':9},
              {'color':'#ff3355','shortname':'uveterana', 'rivalName':'U. Veterana', 'pj':10, 'pg':0,'pe':1,'pp':9,'gf':8,'gc':24,'dif':-16, 'pts':1}],


    };
  }

  componentDidMount() {
	let newtable = this.sortTable(this.state.table1);
                this.setState({table1:newtable});

	let newtable0 = this.sortTable(this.state.table0);
                this.setState({table0:newtable0});
        }



  handleChange(val){
    console.log(val);
    if(val===1){
      this.setState({showCards:true});
    }
    else{
      this.setState({showCards:false});
    }
  }

comeBack(){
this.setState({path:this.state.path});
}

subText(color, text){

let comp = [];
comp.push(

	<Row className="justify-content-md-center">
            <Col lg={2} xl={2} >

        <Table bordered={ false } size="sm">
        <tr style={{border: 'none', textAlign: 'rigth' }}>
        <td bgcolor={color}>
        </td>
        <td style={{border: 'none', textAlign: 'center'}}> {text} </td>
        </tr>
        </Table>
        </Col>
        </Row>



)
return comp;

}

sortTable(table){
       let aux;
      for(let i=table.length-1;i>0;i--){
             for(let j =0; j<i; j++){
                 if( (table[j].pts < table[j+1].pts) || 
                     ((table[j].pts === table[j+1].pts) && (table[j].dif < table[j+1].dif)) ||
                     ((table[j].pts === table[j+1].pts) && (table[j].dif === table[j+1].dif) && (table[j].gf < table[j+1].gf))){
                        aux = table[j+1];
                        table[j+1] = table[j];
                        table[j] = aux;
                } 
             }          
        }
return table;

}




render(){

  let subA = this.subText('#295e1e', 'Clasifica a Fase 2 Ascenso');
  let subP = this.subText('#ff3355', 'Clasifica a Liguilla de Descenso');
  let subD = this.subText('#ff3355', 'Clasifica a Fase 2 Descenso');

console.log(this.state.id_list)

  return(

	<div style={{textAlign: 'center' }}>
      <div style={{ height: '50px' }}></div>
	<div className="mytitlepartidos"> Fase 1 Grupo Sur </div>
	<div style={{ height: '10px' }}></div>
	{<Tabla teams={this.state.table0} />}
	<div style={{ height: '10px' }}></div>
	{subA}
	{subD}
	<div style={{ height: '50px' }}></div>
	<div className="mytitlepartidos"> Fase 2 Grupo Sur</div>
	<div style={{ height: '10px' }}></div>
        {<Tabla teams={this.state.table1} />}
        <div style={{ height: '10px' }}></div>
         {subP}

	
	<div style={{ height: '100px' }}></div>
	<div className="mytitlepartidos">  Partidos UDEC Tercera 1995</div>
	<div style={{ height: '10px' }}></div>

	<ToggleButtonGroup type="radio" name="options" defaultValue={1} onChange={this.handleChange}>
            <ToggleButton variant="secondary" value={1}>Tarjetas</ToggleButton>
            <ToggleButton variant="secondary" value={2}>Listado</ToggleButton>
          </ToggleButtonGroup>
	 <div style={{ height: '10px' }}></div>
	<Container>
	<div className={this.state.showCards ? '': 'hiddenCard'}>
           <MatchCardGroup id_list={this.state.id_list}></MatchCardGroup>
         </div>
         <div className={this.state.showCards ? 'hiddenCard': ''}>

           <MatchList id_list={this.state.id_list} showName={true}></MatchList>

         </div>
	</Container>

	<div style={{ height: '100px' }}></div>
        <div className="mytitlepartidos">  Jugadores UDEC Tercera 1995</div>
        <div style={{ height: '10px' }}></div>

	<Players torneo="Tercera 1995" />
     </div>


  )
}

}
export default Tercera1995;
