import React from 'react';
import Container from 'react-bootstrap/Container';
import Image from 'react-bootstrap/Image';
import Table from 'react-bootstrap/Table';
import AllMatch from './AllMatch';
import Button from 'react-bootstrap/Button';

class Goles extends React.Component {

  constructor(props){
    super(props);
    this.state = {
      showCards: false,
      matches: []
    };
  }




  componentDidMount(){
	console.log("nombre");
	console.log(this.props.path);
     this.getPartidos();

  }


  getPartidos(){
    fetch("https://www.registrosudec.com:5000/lista_allpartidos_byname", {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({

      name: this.props.path
    })
    }).then(res => res.json())
  .then(
    (result) => {
     
     this.setState({matches: result['ids']})
    },
    (error) => {
      console.log(error);
      this.setState({matches: []})
    }
  )
  }


  render(){

	console.log(this.state.matches);


    return(

	<Container>
      <div style={{textAlign: 'center' }}>
      <div style={{ height: '20px' }}></div>
	<div className="mytitlepartidos"> Goles de {this.props.name}</div>
 <div style={{ height: '10px' }}></div>
      <div style={{textAlign: 'center' }}>
      <Image  className="myescudo-away-main" data-tip data-for={'udeconce-escudo'} src={'/images/escudos/udeconce.png'}/>
      </div>

        <div style={{ height: '70px' }}></div>

<div style={{ height: '10px' }}></div>
	<Table responsive variant="dark"  striped bordered hover size="sm">
     <thead>
     <tr>
       <th>Fecha</th>
       <th>Local</th>
       <th>vs.</th>
       <th>Visita</th>
       <th>Estadio</th>
       <th>TV</th>
	<th>Video</th>
     </tr>
   </thead>

     <tbody>

    {this.state.matches.map((i) => {
                   return ( <AllMatch shortname={i['shortname']} fixture={i['fixture']}
                                state={i['state']} rivalName={i['rivalName']}
	    		        goal_home={i['goal_home']} id={i['id']}
				goal_away={i['goal_away']} stadium={i['stadium']}
			        tv={i['tv']} video={i['video']}team_image={"/images/escudos/"+i['shortname']+".png"} ></AllMatch>   )
            })}
            </tbody>


         </Table>
<div style={{ height: '70px' }}></div>
	</div>


</Container>


    )

}
}
export default Goles;
