import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';


import Table from 'react-bootstrap/Table';



class TablaProm2015 extends React.Component {





render(){


  return(
    <Row className="justify-content-md-center">
    <Col lg={6} xl={6}  >
    <Table variant="dark" striped bordered hover size="sm">
  <thead>
    <tr style={{textAlign: 'center' }}>
	<th > </th>
	<th>Pos</th>
      <th>Equipo</th>
      <th>Pts 14</th>
      <th>Pts 15</th>
      <th>Pts Total</th>
      <th>PJ</th>
	<th>Pon Total</th>
    </tr>
  </thead>
  <tbody>
{this.props.teams.map((team, i) => {

	return(<tr style={{textAlign: 'center' }}>
		 {team.color !== 0 && <td bgcolor={team.color}></td>}
		 {team.color === 0 && <td></td>}
  		 <td >{i + 1}</td>
                 <td ><Image   data-tip data-for={team.shortname+'-escudo'} className="list-escudo" src={'/images/escudos/'+team.shortname+'.png'}/>
                  {" " + team.rivalName}
                 </td>
                 <td >{team.pts13}</td>
                 <td >{team.pts14}</td>
                 <td >{team.pts}</td>
                 <td >{team.pj}</td>
                 <td >{team.pon}</td>
              </tr>);
	})}

</tbody>
</Table>
</Col>
</Row>
  )
}
}
export default TablaProm2015;
