import React from 'react';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Table from 'react-bootstrap/Table';




class SummaryTable extends React.Component {

  constructor(props){
    super(props);
    this.state = {
      rivalName: '',
      tournament: []
    };
  }

  componentDidMount() {

    console.log(this.props.shortname);
    fetch("https://www.registrosudec.com:5000/vshistorybytournament", {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      team: this.props.shortname
    })
    }).then(res => res.json())
  .then(
    (result) => {
     this.setState({rivalName: this.props.shortname,
                    tournament: result['tournament']
                    })

    },
    // Nota: es importante manejar errores aquí y no en
    // un bloque catch() para que no interceptemos errores
    // de errores reales en los componentes.
    (error) => {
      console.log(error);
    }
  )


}





render(){


  return(
    <Row className="justify-content-md-center">
    <Col lg={6} xl={6}  >
    <Table variant="dark" striped bordered hover size="sm">
  <thead>
    <tr style={{textAlign: 'center' }}>
    <th>Torneo</th>
      <th>PJ</th>
      <th>PG</th>
      <th>PE</th>
      <th>PP</th>
      <th>GF</th>
      <th>GC</th>
      <th>DIF</th>
    </tr>
  </thead>
  <tbody>
    {this.state.tournament.map((_type, i) => {
                   return ( _type.pj !== 0 &&  <tr style={{textAlign: 'center' }}>
                   <td >{_type.name}</td>
                               <td >{_type.pj}</td>
                               <td >{_type.pg}</td>
                               <td >{_type.pe}</td>
                               <td >{_type.pp}</td>
                               <td >{_type.gf}</td>
                               <td >{_type.gc}</td>
                               <td >{_type.gf - _type.gc}</td>
                                 </tr>

                     )
            })}
  </tbody>
  </Table>
  </Col>
  </Row>
  )
}

}
export default SummaryTable;
