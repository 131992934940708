import React from 'react';
import Image from 'react-bootstrap/Image';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Table from 'react-bootstrap/Table';
import Tabla from '../Tabla';
import MatchList from '../MatchList';
import MatchCardGroup from '../MatchCardGroup';
import Players from '../Players';
import ToggleButtonGroup from 'react-bootstrap/ToggleButtonGroup';
import ToggleButton from 'react-bootstrap/ToggleButton';
class PrimeraB2024 extends React.Component {

  constructor(props){
    super(props);
        this.comeBack = this.comeBack.bind(this);
	this.handleChange = this.handleChange.bind(this);
	var id_list = [];
	for (var i = 1153; i <= 1185; i++) {
		if(i!==1168 && i!=1169 && i!=1170){
        	id_list.push(i);
		}
        }
    this.state = {
	kits:[],
	kit_path: '/images/kits/todas/',
	showCards: true,
      path: this.props.path,
      ctype: this.props.ctype,
      torneo: this.props.torneo,
      id_list: id_list,


table2:[{'color':'#ffa500','shortname':'recoleta', 'rivalName':'Dep. Recoleta', 'pj':30, 'pg':11,'pe':7,'pp':12,'gf':38,'gc':35,'dif':3, 'pts':40},
              {'color':'#295e1e','shortname':'laserena', 'rivalName':'La Serena', 'pj':30, 'pg':21,'pe':7,'pp':2,'gf':42,'gc':15,'dif':27, 'pts':70},
              {'color':'#ff3355','shortname':'barnechea', 'rivalName':'Barnechea', 'pj':30, 'pg':14,'pe':8,'pp':8,'gf':51,'gc':39,'dif':12, 'pts':5},
		{'color':0,'shortname':'sanfelipe', 'rivalName':'U. San Felipe', 'pj':30, 'pg':6,'pe':8,'pp':16, 'gf':27,'gc':47,'dif':-20, 'pts':26},
              {'color':0,'shortname':'udeconce', 'rivalName':'U. de Concepción', 'pj':30, 'pg':11,'pe':7,'pp':12,'gf':33,'gc':39,'dif':-6, 'pts':40},
              {'color':'#ffa500','shortname':'antofagasta', 'rivalName':'Antofagasta', 'pj':30, 'pg':11,'pe':10,'pp':9,'gf':43, 'gc':36,'dif':7, 'pts':43},
              {'color':0,'shortname':'wanderers', 'rivalName':'S. Wanderers', 'pj':30, 'pg':9,'pe':11,'pp':10,'gf':45,'gc':39,'dif':6, 'pts':38},
              {'color':0,'shortname':'curico', 'rivalName':'Curicó U.', 'pj':30, 'pg':9,'pe':7,'pp':14,'gf':29,'gc':41,'dif':-12, 'pts':25},
              {'color':'#ffa500','shortname':'rangers', 'rivalName':'Rangers', 'pj':30, 'pg':14,'pe':8,'pp':8,'gf':42,'gc':32,'dif':10, 'pts':50},
              {'color':0,'shortname':'arica', 'rivalName':'San Marcos Arica', 'pj':30, 'pg':9,'pe':8,'pp':13,'gf':32,'gc':35,'dif':-3, 'pts':35},
              {'color':'#ffa500','shortname':'morning', 'rivalName':'S. Morning', 'pj':30, 'pg':11,'pe':7,'pp':12,'gf':35,'gc':35,'dif':0, 'pts':40},
              {'color':0,'shortname':'sanluis', 'rivalName':'San Luis', 'pj':30, 'pg':7,'pe':7,'pp':16,'gf':29,'gc':58,'dif':-29, 'pts':28},
              {'color':0,'shortname':'temuco', 'rivalName':'Dep. Temuco', 'pj':30, 'pg':9,'pe':6,'pp':15,'gf':30,'gc':37,'dif':-7, 'pts':33},
              {'color':'#ffa500','shortname':'santacruz', 'rivalName':'Dep. Santa Cruz', 'pj':30, 'pg':11, 'pe':7,'pp':12,'gf':43,'gc':47,'dif':-4, 'pts':40},
              {'color':'#ffa500','shortname':'limache', 'rivalName':'Dep. Limache', 'pj':30, 'pg':11,'pe':7,'pp':12,'gf':42,'gc':43,'dif':-1, 'pts':40},
	      {'color':'#ffa500','shortname':'magallanes', 'rivalName':'Magallanes', 'pj':30, 'pg':15,'pe':7,'pp':8,'gf':46,'gc':29,'dif':17, 'pts':52}]
    };


/*
table2:[{'color':0,'shortname':'recoleta', 'rivalName':'Dep. Recoleta', 'pj':14, 'pg':2,'pe':3,'pp':9,'gf':19,'gc':24,'dif':-5, 'pts':9},
              {'color':'#295e1e','shortname':'laserena', 'rivalName':'La Serena', 'pj':14, 'pg':4,'pe':1,'pp':9,'gf':12,'gc':24,'dif':-12, 'pts':13},
              {'color':0,'shortname':'barnechea', 'rivalName':'Barnechea', 'pj':14, 'pg':6,'pe':4,'pp':4,'gf':20,'gc':18,'dif':2, 'pts':22},
              {'color':0,'shortname':'udeconce', 'rivalName':'U. de Concepción', 'pj':14, 'pg':6,'pe':6,'pp':3,'gf':23,'gc':20,'dif':3, 'pts':24},
              {'color':'#ffa500','shortname':'antofagasta', 'rivalName':'Antofagasta', 'pj':14, 'pg':6,'pe':2,'pp':6,'gf':21, 'gc':21,'dif':0, 'pts':20},
              {'color':'#ffa500','shortname':'wanderers', 'rivalName':'S. Wanderers', 'pj':14, 'pg':9,'pe':4,'pp':1,'gf':18,'gc':8,'dif':10, 'pts':31},
              {'color':'#ffa500','shortname':'cobreloa', 'rivalName':'Cobreloa', 'pj':14, 'pg':7,'pe':3,'pp':4,'gf':19,'gc':16,'dif':3, 'pts':24},
              {'color':0,'shortname':'rangers', 'rivalName':'Rangers', 'pj':14, 'pg':5,'pe':3,'pp':6,'gf':16,'gc':22,'dif':-6, 'pts':18},
              {'color':0,'shortname':'arica', 'rivalName':'San Marcos Arica', 'pj':14, 'pg':6,'pe':3,'pp':5,'gf':25,'gc':26,'dif':-1, 'pts':21},
              {'color':0,'shortname':'morning', 'rivalName':'S. Morning', 'pj':14, 'pg':5,'pe':4,'pp':5,'gf':17,'gc':16,'dif':1, 'pts':19},
              {'color':'#ffa500','shortname':'sanluis', 'rivalName':'San Luis', 'pj':14, 'pg':3,'pe':5,'pp':6,'gf':16,'gc':15,'dif':1, 'pts':14},
              {'color':'#ffa500','shortname':'sanfelipe', 'rivalName':'U. San Felipe', 'pj':14, 'pg':5,'pe':4,'pp':5,'gf':16,'gc':13,'dif':3, 'pts':19},
              {'color':'#ffa500','shortname':'temuco', 'rivalName':'Dep. Temuco', 'pj':14, 'pg':6,'pe':3,'pp':5,'gf':19,'gc':17,'dif':2, 'pts':21},
              {'color':'#ff3355','shortname':'puertomontt', 'rivalName':'Puerto Montt', 'pj':14, 'pg':3,'pe':5,'pp':7,'gf':14,'gc':23,'dif':-9, 'pts':14},
              {'color':0,'shortname':'santacruz', 'rivalName':'Dep. Santa Cruz', 'pj':14, 'pg':4, 'pe':4,'pp':6,'gf':14,'gc':15,'dif':-1, 'pts':16},
              {'color':'#ffa500','shortname':'iquique', 'rivalName':'Dep. Iquique', 'pj':14, 'pg':7,'pe':4,'pp':3,'gf':26,'gc':19,'dif':7, 'pts':25}]
	}; */
  }

  componentDidMount() {
	this.getKits();
	let newtable2 = this.sortTable(this.state.table2);
                this.setState({table2:newtable2});
	}



 getKits(){

        fetch("https://www.registrosudec.com:5000/kits", {

    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      id_list: this.state.id_list
    })
    }).then(
                res => res.json()
                )
  .then(
    (result) => {
        console.log(result)
        this.setState({ 
        kits: result['kits']}) //[{path:"45.png", kit:yellow, short:"blue", socks:"blue", count:15, 
    },
    // Nota: es importante manejar errores aqu   y no en
    // un bloque catch() para que no interceptemos errores
    // de errores reales en los componentes.
    (error) => {
      console.log(error);
      this.setState({fecha: []})
    }
  );
}


  handleChange(val){
    console.log(val);
    if(val===1){
      this.setState({showCards:true});
    }
    else{
      this.setState({showCards:false});
    }
  }

comeBack(){
this.setState({path:this.state.path});
}

subText(color, text){

let comp = [];
comp.push(

	<Row className="justify-content-md-center">
            <Col lg={2} xl={2} >

        <Table bordered={ false } size="sm">
        <tr style={{border: 'none', textAlign: 'rigth' }}>
        <td bgcolor={color}>
        </td>
        <td style={{border: 'none', textAlign: 'center'}}> {text} </td>
        </tr>
        </Table>
        </Col>
        </Row>



)
return comp;

}

sortTable(table){
       let aux;
      for(let i=table.length-1;i>0;i--){
             for(let j =0; j<i; j++){
                 if( (table[j].pts < table[j+1].pts) || 
                     ((table[j].pts === table[j+1].pts) && (table[j].dif < table[j+1].dif)) ||
                     ((table[j].pts === table[j+1].pts) && (table[j].dif === table[j+1].dif) && (table[j].gf < table[j+1].gf))){
                        aux = table[j+1];
                        table[j+1] = table[j];
                        table[j] = aux;
                } 
             }          
        }
return table;

}




render(){


  let subT = this.subText('#295e1e', 'Asciende a Primera A 2025');
  let subD = this.subText('#ff3355', 'Desciende a Segunda División 2025');
console.log(this.state.id_list);
let subP = this.subText('#ffa500', 'Clasifica a liguilla de ascenso');

  return(

	<div style={{textAlign: 'center' }}>

	<div style={{ height: '50px' }}></div>
        <div className="mytitlepartidos">  Indumentaria UDEC Primera B 2024</div>
        <div style={{ height: '50px' }}></div> 

        <div style={{display: 'inline-block' }}>
        <table style={{textAlign: 'center' }}>
        <tr>
           {this.state.kits.map((i) => {
                   return ( <td> <Image  className="escudo-home" src={this.state.kit_path+i['path']+".png"}/> </td>)
            })}
        </tr>
        <tr>
        {this.state.kits.map((i) => {
                   return ( <td> <Image  className="myshort" src={this.state.kit_path+i['short']+".png"}/> </td>)
            })}
        </tr>
        <tr>
        {this.state.kits.map((i) => {
                   return ( <td> <Image  className="myshort" src={this.state.kit_path+'socks-'+i['socks']+".png"}/> </td>)
            })}
        </tr>

        <tr>
                {this.state.kits.map((i) => {
                   return ( <td class="circle"> {i['count']} </td>)
            })}
        </tr>
        </table>
        </div>

	<div style={{ height: '50px' }}></div>
	<div className="mytitlepartidos"> Tabla General</div>
	<div style={{ height: '10px' }}></div>
        {<Tabla teams={this.state.table2} />}
        <div style={{ height: '10px' }}></div>
	<div> * Curicó tiene 9 puntos menos por deudas </div>
	<div> * Barnechea tiene 45 puntos menos por no presentación </div>

	<div style={{ height: '10px' }}></div>
         {subT}
	 {subP}
	 {subD}
	<div style={{ height: '100px' }}></div>
	<div className="mytitlepartidos">  Partidos UDEC Primera B 2024</div>
	<div style={{ height: '10px' }}></div>

	<ToggleButtonGroup type="radio" name="options" defaultValue={1} onChange={this.handleChange}>
            <ToggleButton variant="secondary" value={1}>Tarjetas</ToggleButton>
            <ToggleButton variant="secondary" value={2}>Listado</ToggleButton>
          </ToggleButtonGroup>
	 <div style={{ height: '10px' }}></div>
	<Container>
	<div className={this.state.showCards ? '': 'hiddenCard'}>
           <MatchCardGroup id_list={this.state.id_list}></MatchCardGroup>
         </div>
         <div className={this.state.showCards ? 'hiddenCard': ''}>

           <MatchList id_list={this.state.id_list} showName={true}></MatchList>

         </div>
	</Container>

	<div style={{ height: '100px' }}></div>
        <div className="mytitlepartidos">  Jugadores UDEC Primera B 2024</div>
        <div style={{ height: '10px' }}></div>

	<Players torneo="Primera B 2024" />
     </div>


  )
}

}
export default PrimeraB2024;
