import React from "react";

import Image from 'react-bootstrap/Image';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import PitchCanvas from './PitchCanvas';



class BodyModal extends React.Component {

  constructor(props){
    super(props);
    this.state = {goal_home: this.props.goal_home, goal_away: this.props.goal_away, key: 0, matchid: this.props.matchid
    };
  }

 goalHomeClick = (i) => {
              console.log('gol home');
		console.log(this.props.goal_home[i]);

        	console.log(i);
            } 

ownGoalHomeClick = (i) => {
              console.log('own gol home');
            console.log(i);
            } 

goalAwayClick = (i) => {
              console.log('gol away');
            console.log(i);
            } 

ownGoalAwayClick = (i) => {
              console.log('own gol away');
            console.log(i);
            } 

  componentDidMount(){
this.setState({message: '\n'});

}



render(){


  var goalHomeLen = this.props.goal_home.length;
  var goalAwayLen = this.props.goal_away.length;

  if(this.state.matchid == 266){
     goalHomeLen = 0;
     goalAwayLen = 2;
  }
  if(this.state.matchid == 600){
     goalHomeLen = 3;
     goalAwayLen = 0;
  }
  if(this.state.matchid == 678){
     goalHomeLen = 3;
     goalAwayLen = 0;
  }
  if(this.state.matchid == 800){
     goalHomeLen = 0;
     goalAwayLen = 3;
  }

  return(


  <Row>
  <Col lg={3} xl={3} md={6} xs={6}>
  {
  this.props.lineup_home.map((elem, i) => {

  	var rows = [];
          var own = [];
          var reds = [];
          var yellows = [];
          var sub = [];
	  var assists = [];

          this.props.goal_home.map((gh,ith) =>{
                
  		if(gh.player === elem.player){
  			rows.push(<Image  className="my-pelota" onClick={() => this.goalHomeClick(ith)} src={"/images/iconos/pelota.png"}/>);
  		}
		if(gh.assist === elem.player){
			assists.push(<Image  className="my-pelota" src={"/images/iconos/assist.png"}/>);
		}
             return 0;
          });

  	this.props.goal_away.map((ga,ita) => {
  		if(ga.player === elem.player){
  			own.push(<Image  className="my-own_goal"  onClick={() => this.ownGoalHomeClick(ita)} src={"/images/iconos/own_goal.png"}/>);
          	}
	return 0;
  	});

          this.props.red_home.map((rh) => {
                  if(rh.player === elem.player){
                          reds.push(<Image  className="my-red"  src={"/images/iconos/red.png"}/>);
                  }
		return 0;

          });

          this.props.yellow_home.map((yh) => {
                  if(yh.player === elem.player){
  			yellows.push(<Image  className="my-yellow"  src={"/images/iconos/yellow.png"}/>);
                  }
		return 0;
          });


  	if(elem.minute){
  		sub.push(<span>({elem.minute}')</span>);
  	}

/*
          if(i === 0){
           return <div class="escudoA"> <div class="ghome" > {this.props.goal_home.length} </div> <div class="new-line">{this.state.message}</div><Image  className="escudo-home"  
	src={"/images/escudos/"+this.props.home_name+".png"}/> <div class="lineup_home"> {yellows} {reds} {elem.player} {sub} {rows} {own} {assists}

  	</div> </div>
  	}

*/




	if(i === 0){
           return <div class="escudoA"> <div class="ghome" > {goalHomeLen} </div> <div class="new-line">{this.state.message}</div><Image  className="escudo-home"  
        src={this.props.kit_home_path+".png"}/> <div class="lineup_home"> {yellows} {reds} {elem.player} {sub} {rows} {own} {assists}

        </div> </div>
        }

          else if(i === 11){
             return <div><div class="new-line">{this.state.message}</div> <div class="lineup_home">Banca: </div><div class="new-line">{this.state.message}</div> <div class="lineup_home"> {yellows} {reds} {elem.player} {sub} {rows} {own} {assists}

  		</div> </div>
  	} 
  	 else if(i === this.props.lineup_home.length -1){
  	 return <div><div class="new-line">{this.state.message}</div> <div class="lineup_home">DT: {elem.player}</div> </div>
  	}
  	else{
           return  <div class="lineup_home"> {yellows} {reds} {elem.player} {sub} {rows} {own} {assists}


  	</div>  
  	}
  }
              )}
  </Col>
  <Col className="d-none d-lg-block pitchdiv" lg={6} xl={6} md={0} xs={0}>

  <PitchCanvas key={this.state.key} goal_home={this.state.goal_home} goal_away={this.state.goal_away} local={this.props.local} home_name={this.props.home_name} away_name={this.props.away_name}/>


  </Col>
  <Col lg={3} xl={3} md={6} xs={6}>
  {
  this.props.lineup_away.map((elem, i) => {


  	var rows = [];
          var own = [];
          var reds = [];
          var yellows = [];
	  var assists = [];

          this.props.goal_away.map((gh,ith) =>{

                  if(gh.player === elem.player){
                          rows.push(<Image  className="my-pelota" onClick={() => this.goalAwayClick(ith)}  src={"/images/iconos/pelota.png"}/>);
                  }
	          if(gh.assist === elem.player){
                        assists.push(<Image  className="my-pelota" src={"/images/iconos/assist.png"}/>);
                }
		return 0;

          });

          this.props.goal_home.map((ga,ita) => {
                  if(ga.player === elem.player){
  			own.push(<Image  className="my-own_goal" onClick={() => this.ownGoalAwayClick(ita)}  src={"/images/iconos/own_goal.png"}/>);
                  }
		return 0;

          });

  	this.props.red_away.map((ra) => {
                  if(ra.player === elem.player){
  			reds.push(<Image  className="my-red"  src={"/images/iconos/red.png"}/>);
                  }

		return 0;

          });
          this.props.yellow_away.map((ya) => {
                  if(ya.player === elem.player){
  			yellows.push(<Image  className="my-yellow"  src={"/images/iconos/yellow.png"}/>);
                  }
		return 0;

          });

  	var sub = [];
          if(elem.minute){
                  sub.push(<span>({elem.minute}')</span>);
          }



/*          if(i === 0){
           return <div class="escudoA"> <div class="ghome" > {this.props.goal_away.length} </div> <div class="new-line">{this.state.message}</div><Image  className="escudo-away"  
		src={"/images/escudos/"+this.props.away_name+".png"}/> <div class="lineup_away"> {yellows} {reds} {elem.player} {sub} {rows} {own} {assists} </div> </div>
          } */
	
	if(i === 0){
           return <div class="escudoA"> <div class="ghome" > {goalAwayLen} </div> <div class="new-line">{this.state.message}</div><Image  className="escudo-away"  
                src={this.props.kit_away_path+".png"}/> <div class="lineup_away"> {yellows} {reds} {elem.player} {sub} {rows} {own} {assists} </div> </div>
          }

  	else if(i === 11){
             return <div><div class="new-line">{this.state.message}</div> <div class="lineup_away">Banca: </div><div class="new-line">{this.state.message}</div> <div class="lineup_away"> {yellows} {reds} {elem.player} {sub} {rows} {own} {assists}</div> </div>
          } 
           else if(i === this.props.lineup_away.length -1){
           return <div><div class="new-line">{this.state.message}</div> <div class="lineup_away">DT: {elem.player}</div> </div>
          }
          else{
           return  <div class="lineup_away"> {yellows} {reds} {elem.player} {sub} {rows} {own} {assists}</div>   
          }
  }
              )}

  </Col>
  </Row>

  )

}
}
export default BodyModal;



