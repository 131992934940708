import React from 'react';
import SummaryTournament from './SummaryTournament';
import Image from 'react-bootstrap/Image';


class Campeonatos extends React.Component {

  constructor(props){
    super(props);
    this.state = {
      showCards: true,
      matches: []
    };
  }

  componentDidMount(){
console.log("esto es")
console.log(this.props);

  }



  render(){

    return(

      <div style={{textAlign: 'center' }}>
      <div style={{ height: '30px' }}></div>
	<div className="mytitlepartidos"> Torneos jugados </div>
      <div style={{textAlign: 'center' }}>
	<div style={{ height: '20px' }}></div>
      <Image  className="myescudo-away-main"  src={'/images_fem/escudos/udeconce.png'}/>
      </div>

      <div style={{ height: '50px' }}></div>

        <SummaryTournament key={this.props.path} path={this.props.path}></SummaryTournament>
        <div style={{ height: '70px' }}></div>

     </div>

    )

}
}
export default Campeonatos;
