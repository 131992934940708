import React from 'react';
import Image from 'react-bootstrap/Image';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Table from 'react-bootstrap/Table';
import Tabla from '../Tabla';
import MatchList from '../MatchList';
import MatchCardGroup from '../MatchCardGroup';
import Players from '../Players';
import ToggleButtonGroup from 'react-bootstrap/ToggleButtonGroup';
import ToggleButton from 'react-bootstrap/ToggleButton';
class Apertura2005 extends React.Component {

  constructor(props){
    super(props);
        this.comeBack = this.comeBack.bind(this);
	this.handleChange = this.handleChange.bind(this);
	var id_list = [];
	for (var i = 398; i <= 416; i++) {
        	id_list.push(i);
        }
    this.state = {
	showCards: true,
      path: this.props.path,
	kit_path: '/images/kits/todas/',
      kits: [],
      ctype: this.props.ctype,
      torneo: this.props.torneo,
      id_list: id_list,
      table1:[
		{'color':0,'shortname':'udeconce', 'rivalName':'U. de Concepción', 'pj':19, 'pg':6,'pe':4,'pp':9,'gf':23,'gc':31,'dif':-8, 'pts':22},
		{'color':'#295e1e','shortname':'union', 'rivalName':'U. Española', 'pj':19, 'pg':7,'pe':5,'pp':7,'gf':32,'gc':31,'dif':1, 'pts':26},
              {'color':0,'shortname':'temuco', 'rivalName':'Dep. Temuco', 'pj':19, 'pg':3,'pe':8,'pp':8,'gf':24,'gc':31,'dif':-7, 'pts':17},
		{'color':0,'shortname':'palestino', 'rivalName':'Palestino', 'pj':19, 'pg':6,'pe':3,'pp':10,'gf':21,'gc':28,'dif':-7, 'pts':21},
               {'color':'#295e1e','shortname':'concepcion', 'rivalName':'Dep. Concepción', 'pj':19, 'pg':6,'pe':7,'pp':6,'gf':20,'gc':21,'dif':-1, 'pts':25}],

	table2:[{'color':'#295e1e','shortname':'cobreloa', 'rivalName':'Cobreloa', 'pj':19, 'pg':10,'pe':3,'pp':6,'gf':35,'gc':29,'dif':6, 'pts':33},
              {'color':0,'shortname':'audax', 'rivalName':'Audax Italiano', 'pj':19, 'pg':3,'pe':7,'pp':9,'gf':22,'gc':32,'dif':-10, 'pts':16},
		{'color':'#295e1e','shortname':'concepcion', 'rivalName':'Dep. Concepción', 'pj':19, 'pg':6,'pe':7,'pp':6,'gf':20,'gc':21,'dif':-1, 'pts':25},
	     {'color':0,'shortname':'melipilla', 'rivalName':'Melipilla', 'pj':19, 'pg':4,'pe':5,'pp':10,'gf':14,'gc':30,'dif':-16, 'pts':17},
              {'color':0,'shortname':'puertomontt', 'rivalName':'Puerto Montt', 'pj':19, 'pg':5,'pe':3,'pp':11,'gf':30,'gc':36,'dif':-6, 'pts':18},
	      {'color':0,'shortname':'udeconce', 'rivalName':'U. de Concepción', 'pj':19, 'pg':6,'pe':4,'pp':9,'gf':23,'gc':31,'dif':-8, 'pts':22},
              {'color':'#135683','shortname':'catolica', 'rivalName':'U. Católica', 'pj':19, 'pg':14,'pe':2,'pp':3,'gf':39,'gc':13,'dif':26, 'pts':44},
              {'color':'#295e1e','shortname':'colocolo', 'rivalName':'Colo Colo', 'pj':19, 'pg':9,'pe':5,'pp':5,'gf':34,'gc':24,'dif':10, 'pts':32},
              {'color':0,'shortname':'cobresal', 'rivalName':'Cobresal', 'pj':19, 'pg':9,'pe':5,'pp':5,'gf':32,'gc':25,'dif':7, 'pts':32},
              {'color':'#295e1e','shortname':'huachipato', 'rivalName':'Huachipato', 'pj':19, 'pg':10,'pe':1,'pp':8,'gf':31,'gc':28,'dif':3, 'pts':31},
              {'color':'#135683','shortname':'udechile', 'rivalName':'U. de Chile', 'pj':19, 'pg':12,'pe':3,'pp':4,'gf':36,'gc':19,'dif':17, 'pts':39},
              {'color':0,'shortname':'rangers', 'rivalName':'Rangers', 'pj':19, 'pg':7,'pe':7,'pp':5,'gf':24,'gc':23,'dif':1, 'pts':28},
              {'color':0,'shortname':'wanderers', 'rivalName':'S. Wanderers', 'pj':19, 'pg':7,'pe':2,'pp':10,'gf':28,'gc':30,'dif':-2, 'pts':23},
              {'color':'#295e1e','shortname':'union', 'rivalName':'U. Española', 'pj':19, 'pg':7,'pe':5,'pp':7,'gf':32,'gc':31,'dif':1, 'pts':26},
              {'color':0,'shortname':'temuco', 'rivalName':'Dep. Temuco', 'pj':19, 'pg':3,'pe':8,'pp':8,'gf':24,'gc':31,'dif':-7, 'pts':17},
	      {'color':0,'shortname':'laserena', 'rivalName':'La Serena', 'pj':19, 'pg':7,'pe':5,'pp':7,'gf':28,'gc':35,'dif':-7, 'pts':26},
	      {'color':0,'shortname':'everton', 'rivalName':'Everton', 'pj':19, 'pg':8,'pe':6,'pp':6,'gf':32,'gc':27,'dif':5, 'pts':30},
              {'color':0,'shortname':'palestino', 'rivalName':'Palestino', 'pj':19, 'pg':6,'pe':3,'pp':10,'gf':21,'gc':28,'dif':-7, 'pts':21},
              {'color':0,'shortname':'sanfelipe', 'rivalName':'U. San Felipe', 'pj':19, 'pg':5,'pe':3,'pp':11,'gf':16,'gc':26,'dif':-10, 'pts':18},
              {'color':'#295e1e','shortname':'coquimbo', 'rivalName':'Coquimbo Unido', 'pj':19, 'pg':9,'pe':2,'pp':8,'gf':27,'gc':29,'dif':-2, 'pts':29}]

    };
  }

  componentDidMount() {
		this.getKits();
		let _newtable = this.sortTable(this.state.table1);
                this.setState({table1:_newtable});
		let newtable = this.sortTable(this.state.table2);
  		this.setState({table2:newtable});

	}

getKits(){

        fetch("https://www.registrosudec.com:5000/kits", {

    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      id_list: this.state.id_list
    })
    }).then(
                res => res.json()
                )
  .then(
    (result) => {
        console.log(result)
        this.setState({ 
        kits: result['kits']}) //[{path:"45.png", kit:yellow, short:"blue", soc$
    },
    // Nota: es importante manejar errores aqu   y no en
    // un bloque catch() para que no interceptemos errores
    // de errores reales en los componentes.
    (error) => {
      console.log(error);
      this.setState({fecha: []})
    }
  );
}


  handleChange(val){
    console.log(val);
    if(val===1){
      this.setState({showCards:true});
    }
    else{
      this.setState({showCards:false});
    }
  }

comeBack(){
this.setState({path:this.state.path});
}

subText(color, text){

let comp = [];
comp.push(

	<Row className="justify-content-md-center">
            <Col lg={3} xl={3} >

        <Table bordered={ false } size="sm">
        <tr style={{border: 'none', textAlign: 'rigth' }}>
        <td bgcolor={color} style={{width:'20px'}}>
        </td>
        <td style={{border: 'none', textAlign: 'center'}}> {text} </td>
        </tr>
        </Table>
        </Col>
        </Row>



)
return comp;

}


sortTable(table){
       let aux;
      for(let i=table.length-1;i>0;i--){
             for(let j =0; j<i; j++){
                 if( (table[j].pts < table[j+1].pts) || 
                     ((table[j].pts === table[j+1].pts) && (table[j].dif < table[j+1].dif)) ||
                     ((table[j].pts === table[j+1].pts) && (table[j].dif === table[j+1].dif) && (table[j].gf < table[j+1].gf))){
			aux = table[j+1];
			table[j+1] = table[j];
                        table[j] = aux;
		} 
	     }		
	}
return table;

}


render(){


  let subT = this.subText('#295e1e', 'Clasificado a Play-Off');
  let subC = this.subText('#135683', 'Clasificado a Play-Off y a Sudamericana 2005');
  return(

	<div style={{textAlign: 'center' }}>

	 <div style={{ height: '50px' }}></div>
        <div className="mytitlepartidos">  Indumentaria UDEC Apertura 2005</div>
        <div style={{ height: '50px' }}></div> 

        <div style={{display: 'inline-block' }}>
        <table style={{textAlign: 'center' }}>
        <tr>
           {this.state.kits.map((i) => {
                   return ( <td> <Image  className="escudo-home" src={this.state.kit_path+i['path']+".png"}/> </td>)
            })}
        </tr>
        <tr>
        {this.state.kits.map((i) => {
                   return ( <td> <Image  className="myshort" src={this.state.kit_path+i['short']+".png"}/> </td>)
            })}
        </tr>
        <tr>
        {this.state.kits.map((i) => {
                   return ( <td> <Image  className="myshort" src={this.state.kit_path+'socks-'+i['socks']+".png"}/> </td>)
            })}
        </tr>

        <tr>
                {this.state.kits.map((i) => {
                   return ( <td class="circle"> {i['count']} </td>)
            })}
        </tr>
        </table>
        </div>

      <div style={{ height: '50px' }}></div>
	<div className="mytitlepartidos"> Grupo 3 </div>
	<div style={{ height: '10px' }}></div>
	{<Tabla teams={this.state.table1} />}
        <div style={{ height: '10px' }}></div>
         {subT}


	<div style={{ height: '50px' }}></div>
	<div className="mytitlepartidos"> Tabla General</div>
	<div style={{ height: '10px' }}></div>
        {<Tabla teams={this.state.table2} />}
        <div style={{ height: '10px' }}></div>
         {subC}
         {subT}

	
	<div style={{ height: '100px' }}></div>
	<div className="mytitlepartidos">  Partidos UDEC Apertura 2005</div>
	<div style={{ height: '10px' }}></div>

	<ToggleButtonGroup type="radio" name="options" defaultValue={1} onChange={this.handleChange}>
            <ToggleButton variant="secondary" value={1}>Tarjetas</ToggleButton>
            <ToggleButton variant="secondary" value={2}>Listado</ToggleButton>
          </ToggleButtonGroup>
	 <div style={{ height: '10px' }}></div>
	<Container>
	<div className={this.state.showCards ? '': 'hiddenCard'}>
           <MatchCardGroup id_list={this.state.id_list}></MatchCardGroup>
         </div>
         <div className={this.state.showCards ? 'hiddenCard': ''}>

           <MatchList id_list={this.state.id_list} showName={true}></MatchList>

         </div>
	</Container>

	<div style={{ height: '100px' }}></div>
        <div className="mytitlepartidos">  Jugadores UDEC Apertura 2005</div>
        <div style={{ height: '10px' }}></div>

	<Players torneo="Apertura 2005" />
     </div>


  )
}

}
export default Apertura2005;
