import React from 'react';
import Image from 'react-bootstrap/Image';
import ReactTooltip from 'react-tooltip';
import { Link } from 'react-router-dom';




class AllMatch extends React.Component {

  constructor(props){
    super(props);
	console.log("props")
  }

  componentDidMount() {

}

render(){
 console.log(this.props.shortname)
console.log(this.props.team_image)

  return(

    <tr style={{textAlign: 'center' }}>

       <td style={{minWidth: '220px'}}>{this.props.fixture}</td>

       {this.props.state === 0 &&
       <td ><Image   data-tip data-for="udeconce-escudo" className="list-escudo" src="/images/escudos/udeconce.png"/>
	<span> U. de Concepción</span>   
     <ReactTooltip id="udeconce-escudo" type='light' effect='float'>
          <span >U. de Concepción</span>
        </ReactTooltip>
          {/*  {" U. de Concepción" */}

       </td>}
       {this.props.state === 1 &&
       <td ><Image   data-tip data-for={this.props.shortname+'-escudo'} className="list-escudo" src={this.props.team_image}/>
	<span >{" "+this.props.rivalName}</span>
       <ReactTooltip id={this.props.shortname+'-escudo'} type='light' effect='float'>
          {this.props.rivalName}
        </ReactTooltip>
          {/* {" " + this.state.rivalName}*/}
       </td>}

       <td style={{minWidth: '45px'}}><Link className="link-name" to={"/partido/" + this.props.id}>{this.props.goal_home + " - " + this.props.goal_away}</Link>
       </td>
       {this.props.state === 1 &&
       <td >
        {"U. de Concepción " } 

       <Image   data-tip data-for="udeconce-escudo" className="list-escudo" src="/images/escudos/udeconce.png"/>
        <ReactTooltip id="udeconce-escudo" type='light' effect='float'>
          <span >U. de Concepción</span>
        </ReactTooltip>
       </td>}
       {this.props.state === 0 &&
       <td >
          {this.props.rivalName + " "}
       <Image   data-tip data-for={this.props.shortname+'-escudo'} className="list-escudo" src={this.props.team_image}/>
       <ReactTooltip id={this.props.shortname+'-escudo'} type='light' effect='float'>
          <span >{this.props.rivalName}</span>
        </ReactTooltip>

       </td>}
       <td style={{minWidth: '220px'}}>{this.props.stadium}</td>
       {this.props.tv === 1 && <td >{"TV"}</td>}
       {this.props.tv === 0 && <td >{""}</td>}
       {this.props.video === 1 && <td ><Image  className="list-escudo" src="/images/iconos/button.png"/></td>}
       {this.props.video !== 1 && <td >{""}</td>}

    </tr>




  )
}
}
export default AllMatch;
