import React from 'react';
import Image from 'react-bootstrap/Image';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Table from 'react-bootstrap/Table';
import Tabla from '../Tabla';
import MatchList from '../MatchList';
import MatchCardGroup from '../MatchCardGroup';
import Players from '../Players';
import ToggleButtonGroup from 'react-bootstrap/ToggleButtonGroup';
import ToggleButton from 'react-bootstrap/ToggleButton';
class Apertura2016 extends React.Component {

  constructor(props){
    super(props);
        this.comeBack = this.comeBack.bind(this);
	this.handleChange = this.handleChange.bind(this);
	var id_list = [];
	for (var i = 1; i <= 9; i++) {
        	id_list.push(i);
        }
    this.state = {
	showCards: true,
	kits:[],
        kit_path: '/images_fem/kits/todas/',
      path: this.props.path,
      ctype: this.props.ctype,
      torneo: this.props.torneo,
      id_list: id_list,
      table1:[{'color':0,'shortname':'udeconce', 'rivalName':'U. de Concepción', 'pj':9, 'pg':5,'pe':0,'pp':4,'gf':18,'gc':17,'dif':1, 'pts':15},
	      {'color':0,'shortname':'cobresal', 'rivalName':'Cobresal', 'pj':9, 'pg':1,'pe':1,'pp':7,'gf':13,'gc':44,'dif':-31, 'pts':4},
	      {'color':'#295e1e','shortname':'rangers', 'rivalName':'Rangers', 'pj':9, 'pg':8,'pe':0,'pp':1,'gf':23,'gc':9,'dif':14, 'pts':24},
	      {'color':0,'shortname':'magallanes', 'rivalName':'Magallanes', 'pj':9, 'pg':0,'pe':0,'pp':9,'gf':5,'gc':45,'dif':-40, 'pts':0},
	      {'color':0,'shortname':'ptovaras', 'rivalName':'Pto. Varas', 'pj':9, 'pg':2,'pe':2,'pp':5,'gf':13,'gc':27,'dif':-14, 'pts':8},
	      {'color':0,'shortname':'uaustral', 'rivalName':'U. Austral', 'pj':9, 'pg':3,'pe':2,'pp':4,'gf':24,'gc':35,'dif':-11, 'pts':11},
	      {'color':'#295e1e','shortname':'vial', 'rivalName':'Fernández Vial', 'pj':9, 'pg':7,'pe':1,'pp':1,'gf':38,'gc':14,'dif':24, 'pts':22},
	      {'color':'#295e1e','shortname':'temuco', 'rivalName':'Dep. Temuco', 'pj':9, 'pg':7,'pe':0,'pp':2,'gf':43,'gc':12,'dif':31, 'pts':21},
	      {'color':'#295e1e','shortname':'puertomontt', 'rivalName':'Pto. Montt', 'pj':9, 'pg':5,'pe':2,'pp':2,'gf':40,'gc':12,'dif':28, 'pts':17},
	      {'color':0,'shortname':'curico', 'rivalName':'Curicó U.', 'pj':9, 'pg':4,'pe':0,'pp':5,'gf':25,'gc':30,'dif':-5, 'pts':12}]
    };
  }

  componentDidMount() {
		this.getKits();
		let newtable = this.sortTable(this.state.table1);
  		this.setState({table1:newtable});
	}

 getKits(){

        fetch("https://www.registrosudec.com:5000/kits_femenino", {

    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      id_list: this.state.id_list
    })
    }).then(
                res => res.json()
                )
  .then(
    (result) => {
        console.log(result)
        this.setState({ 
        kits: result['kits']}) //[{path:"45.png", kit:yellow, short:"blue", socks:"blue", count:15, 
    },
    // Nota: es importante manejar errores aqu   y no en
    // un bloque catch() para que no interceptemos errores
    // de errores reales en los componentes.
    (error) => {
      console.log(error);
      this.setState({fecha: []})
    }
  );
}



  handleChange(val){
    console.log(val);
    if(val===1){
      this.setState({showCards:true});
    }
    else{
      this.setState({showCards:false});
    }
  }

comeBack(){
this.setState({path:this.state.path});
}

subText(color, text){

let comp = [];
comp.push(

	<Row className="justify-content-md-center">
            <Col lg={2} xl={2} >

        <Table bordered={ false } size="sm">
        <tr style={{border: 'none', textAlign: 'rigth' }}>
        <td bgcolor={color}>
        </td>
        <td style={{border: 'none', textAlign: 'center'}}> {text} </td>
        </tr>
        </Table>
        </Col>
        </Row>



)
return comp;

}


sortTable(table){
       let aux;
      for(let i=table.length-1;i>0;i--){
             for(let j =0; j<i; j++){
                 if( (table[j].pts < table[j+1].pts) || 
                     ((table[j].pts === table[j+1].pts) && (table[j].dif < table[j+1].dif)) ||
                     ((table[j].pts === table[j+1].pts) && (table[j].dif === table[j+1].dif) && (table[j].gf < table[j+1].gf))){
			aux = table[j+1];
			table[j+1] = table[j];
                        table[j] = aux;
		} 
	     }		
	}
return table;

}


render(){


  let subT = this.subText('#295e1e', 'Clasificado a Play-Off');
  return(

	<div style={{textAlign: 'center' }}>
	      <div style={{ height: '50px' }}></div>
        <div className="mytitlepartidos">  Indumentaria UDEC Apertura 2016</div>
        <div style={{ height: '50px' }}></div> 

        <div style={{display: 'inline-block' }}>
        <table style={{textAlign: 'center' }}>
        <tr>
           {this.state.kits.map((i) => {
                   return ( <td> <Image  className="escudo-home" src={this.state.kit_path+i['path']+".png"}/> </td>)
            })}
        </tr>
        <tr>
        {this.state.kits.map((i) => {
                   return ( <td> <Image  className="myshort" src={this.state.kit_path+i['short']+".png"}/> </td>)
            })}
        </tr>
        <tr>
        {this.state.kits.map((i) => {
                   return ( <td> <Image  className="myshort" src={this.state.kit_path+'socks-'+i['socks']+".png"}/> </td>)
            })}
        </tr>

        <tr>
                {this.state.kits.map((i) => {
                   return ( <td class="circle"> {i['count']} </td>)
            })}
        </tr>
        </table>
        </div>

      <div style={{ height: '50px' }}></div>
	<div className="mytitlepartidos"> Grupo Sur </div>
	<div style={{ height: '10px' }}></div>
	{<Tabla teams={this.state.table1} />}
        <div style={{ height: '10px' }}></div>
         {subT}


	<div style={{ height: '100px' }}></div>
	<div className="mytitlepartidos">  Partidos UDEC Apertura 2016</div>
	<div style={{ height: '10px' }}></div>

	<ToggleButtonGroup type="radio" name="options" defaultValue={1} onChange={this.handleChange}>
            <ToggleButton variant="secondary" value={1}>Tarjetas</ToggleButton>
            <ToggleButton variant="secondary" value={2}>Listado</ToggleButton>
          </ToggleButtonGroup>
	 <div style={{ height: '10px' }}></div>
	<Container>
	<div className={this.state.showCards ? '': 'hiddenCard'}>
           <MatchCardGroup id_list={this.state.id_list}></MatchCardGroup>
         </div>
         <div className={this.state.showCards ? 'hiddenCard': ''}>

           <MatchList id_list={this.state.id_list} showName={true}></MatchList>

         </div>
	</Container>

	<div style={{ height: '100px' }}></div>
        <div className="mytitlepartidos">  Jugadores UDEC Apertura 2016 </div>
        <div style={{ height: '10px' }}></div>

	<Players torneo="Apertura 2016" />
     </div>


  )
}

}
export default Apertura2016;
