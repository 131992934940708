import React from 'react';
import Container from 'react-bootstrap/Container';
import Image from 'react-bootstrap/Image';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Table from 'react-bootstrap/Table';
import Tabla from '../Tabla';
import MatchList from '../MatchList';
import MatchCardGroup from '../MatchCardGroup';
import Players from '../Players';
import ToggleButtonGroup from 'react-bootstrap/ToggleButtonGroup';
import ToggleButton from 'react-bootstrap/ToggleButton';
class PrimeraB1999 extends React.Component {

  constructor(props){
    super(props);
        this.comeBack = this.comeBack.bind(this);
	this.handleChange = this.handleChange.bind(this);
	var id_list = [];
	for (var i = 147; i <= 186; i++) {
        	id_list.push(i);
        }
    this.state = {
	showCards: true,
      path: this.props.path,
	kit_path: '/images/kits/todas/',
      kits: [],
      ctype: this.props.ctype,
      torneo: this.props.torneo,
      id_list: id_list,
      table1:[{'color':0,'shortname':'union', 'rivalName':'U. Española', 'pj':12, 'pg':7,'pe':3,'pp':2,'gf':26,'gc':14,'dif':12, 'pts':24},
	      {'color':0,'shortname':'vial', 'rivalName':'Fernández Vial', 'pj':12, 'pg':4,'pe':4,'pp':4,'gf':15,'gc':9,'dif':6, 'pts':16},
		{'color':0,'shortname':'linares', 'rivalName':'Dep. Linares', 'pj':12, 'pg':2,'pe':3,'pp':7,'gf':8,'gc':29,'dif':-21, 'pts':9},
		{'color':0,'shortname':'nublense', 'rivalName':'Ñublense', 'pj':12, 'pg':3,'pe':3,'pp':6,'gf':19,'gc':21,'dif':-2, 'pts':12},
		{'color':0,'shortname':'osorno', 'rivalName':'Prov. Osorno', 'pj':12, 'pg':5,'pe':3,'pp':4,'gf':24,'gc':24,'dif':0, 'pts':18},
	      {'color':0,'shortname':'udeconce', 'rivalName':'U. de Concepción', 'pj':12, 'pg':4,'pe':5,'pp':3,'gf':24,'gc':21,'dif':3, 'pts':17},
	      {'color':0,'shortname':'colchagua', 'rivalName':'Dep. Colchagua', 'pj':12, 'pg':5,'pe':3,'pp':4,'gf':19,'gc':17,'dif':2, 'pts':18}],

	table2:[{'color':0,'shortname':'nublense', 'rivalName':'Ñublense', 'pj':28, 'pg':8,'pe':5,'pp':15,'gf':28,'gc':46,'dif':-18, 'pts':36},
              {'color':'#0033ff','shortname':'osorno', 'rivalName':'Prov. Osorno', 'pj':28, 'pg':14,'pe':4,'pp':10,'gf':45,'gc':36,'dif':9, 'pts':56},
              {'color':0,'shortname':'ovalle', 'rivalName':'Dep. Ovalle', 'pj':28, 'pg':12,'pe':7,'pp':9,'gf':43,'gc':32,'dif':11, 'pts':51},
	      {'color':0,'shortname':'udeconce', 'rivalName':'U. de Concepción', 'pj':28, 'pg':10,'pe':5,'pp':13,'gf':42,'gc':43,'dif':-1, 'pts':45},
              {'color':0,'shortname':'antofagasta', 'rivalName':'Antofagasta', 'pj':28, 'pg':12,'pe':9,'pp':9,'gf':36,'gc':34,'dif':2, 'pts':48},
              {'color':0,'shortname':'melipilla', 'rivalName':'Melipilla', 'pj':28, 'pg':10,'pe':8,'pp':10,'gf':45,'gc':36,'dif':9, 'pts':47},
              {'color':'#ff3355','shortname':'colchagua', 'rivalName':'Dep. Colchagua', 'pj':28, 'pg':4,'pe':6,'pp':18,'gf':31,'gc':47,'dif':-16, 'pts':28},
              {'color':0,'shortname':'magallanes', 'rivalName':'Magallanes', 'pj':28, 'pg':8,'pe':6,'pp':14,'gf':30,'gc':45,'dif':-15, 'pts':42},
              {'color':0,'shortname':'arica', 'rivalName':'Dep. Arica', 'pj':28, 'pg':11,'pe':6,'pp':11,'gf':35,'gc':41,'dif':-6, 'pts':49},
              {'color':'#295e1e','shortname':'union', 'rivalName':'U. Española', 'pj':28, 'pg':16,'pe':8,'pp':4,'gf':56,'gc':26,'dif':30, 'pts':70},
              {'color':0,'shortname':'vial', 'rivalName':'Fernández Vial', 'pj':28, 'pg':8,'pe':10,'pp':10,'gf':28,'gc':38,'dif':-10, 'pts':43},
              {'color':'#295e1e','shortname':'wanderers', 'rivalName':'S. Wanderers', 'pj':28, 'pg':16,'pe':8,'pp':4,'gf':48,'gc':22,'dif':26, 'pts':68},
              {'color':0,'shortname':'linares', 'rivalName':'Dep. Linares', 'pj':28, 'pg':7,'pe':9,'pp':12,'gf':35,'gc':42,'dif':-7, 'pts':35},
              {'color':0,'shortname':'sanfelipe', 'rivalName':'U. San Felipe', 'pj':28, 'pg':6,'pe':9,'pp':13,'gf':31,'gc':47,'dif':-16, 'pts':34},
              {'color':'#0033ff','shortname':'everton', 'rivalName':'Everton', 'pj':28, 'pg':16,'pe':6,'pp':6,'gf':40,'gc':26,'dif':14, 'pts':68}]

    };
  }

  componentDidMount() {
	this.getKits();
	let newtable = this.sortTable(this.state.table1);
                this.setState({table1:newtable});

	let newtable2 = this.sortTable(this.state.table2);
                this.setState({table2:newtable2});
	}

getKits(){

        fetch("https://www.registrosudec.com:5000/kits", {

    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      id_list: this.state.id_list
    })
    }).then(
                res => res.json()
                )
  .then(
    (result) => {
        console.log(result)
        this.setState({ 
        kits: result['kits']}) //[{path:"45.png", kit:yellow, short:"blue", soc$
    },
    // Nota: es importante manejar errores aqu   y no en
    // un bloque catch() para que no interceptemos errores
    // de errores reales en los componentes.
    (error) => {
      console.log(error);
      this.setState({fecha: []})
    }
  );
}


  handleChange(val){
    console.log(val);
    if(val===1){
      this.setState({showCards:true});
    }
    else{
      this.setState({showCards:false});
    }
  }

comeBack(){
this.setState({path:this.state.path});
}

subText(color, text){

let comp = [];
comp.push(

	<Row className="justify-content-md-center">
            <Col lg={2} xl={2} >

        <Table bordered={ false } size="sm">
        <tr style={{border: 'none', textAlign: 'rigth' }}>
        <td bgcolor={color}>
        </td>
        <td style={{border: 'none', textAlign: 'center'}}> {text} </td>
        </tr>
        </Table>
        </Col>
        </Row>



)
return comp;

}

sortTable(table){
       let aux;
      for(let i=table.length-1;i>0;i--){
             for(let j =0; j<i; j++){
                 if( (table[j].pts < table[j+1].pts) || 
                     ((table[j].pts === table[j+1].pts) && (table[j].dif < table[j+1].dif)) ||
                     ((table[j].pts === table[j+1].pts) && (table[j].dif === table[j+1].dif) && (table[j].gf < table[j+1].gf))){
                        aux = table[j+1];
                        table[j+1] = table[j];
                        table[j] = aux;
                } 
             }          
        }
return table;

}




render(){


  let subT = this.subText('#295e1e', 'Asciende a Primera A 2000');
  let subD = this.subText('#ff3355', 'Desciende a Tercera División 2000');
  let subP = this.subText('#0033ff', 'Juego Liguilla Promoción');
console.log(this.state.id_list)

  return(


	<div style={{textAlign: 'center' }}>
	 <div style={{ height: '50px' }}></div>
        <div className="mytitlepartidos">  Indumentaria UDEC Primera B 1999</div>
        <div style={{ height: '50px' }}></div> 

        <div style={{display: 'inline-block' }}>
        <table style={{textAlign: 'center' }}>
        <tr>
           {this.state.kits.map((i) => {
                   return ( <td> <Image  className="escudo-home" src={this.state.kit_path+i['path']+".png"}/> </td>)
            })}
        </tr>
        <tr>
        {this.state.kits.map((i) => {
                   return ( <td> <Image  className="myshort" src={this.state.kit_path+i['short']+".png"}/> </td>)
            })}
        </tr>
        <tr>
        {this.state.kits.map((i) => {
                   return ( <td> <Image  className="myshort" src={this.state.kit_path+'socks-'+i['socks']+".png"}/> </td>)
            })}
        </tr>

        <tr>
                {this.state.kits.map((i) => {
                   return ( <td class="circle"> {i['count']} </td>)
            })}
        </tr>
        </table>
        </div>

      <div style={{ height: '50px' }}></div>
	<div className="mytitlepartidos"> Grupo Sur </div>
	<div style={{ height: '10px' }}></div>
	{<Tabla teams={this.state.table1} />}
        <div style={{ height: '10px' }}></div>

       <div> Cada equipo empieza la Segunda Fase con la mitad de los puntos obtenidos en la Primera Fase</div> 
	<div style={{ height: '50px' }}></div>
	<div className="mytitlepartidos"> Tabla General</div>
	<div style={{ height: '10px' }}></div>
        {<Tabla teams={this.state.table2} />}
        <div style={{ height: '10px' }}></div>
         {subT}
	 {subP}
	{subD}
	<div style={{ height: '100px' }}></div>
	<div className="mytitlepartidos">  Partidos UDEC Primera B 1999</div>
	<div style={{ height: '10px' }}></div>

	<ToggleButtonGroup type="radio" name="options" defaultValue={1} onChange={this.handleChange}>
            <ToggleButton variant="secondary" value={1}>Tarjetas</ToggleButton>
            <ToggleButton variant="secondary" value={2}>Listado</ToggleButton>
          </ToggleButtonGroup>
	 <div style={{ height: '10px' }}></div>
	<Container>
	<div className={this.state.showCards ? '': 'hiddenCard'}>
           <MatchCardGroup id_list={this.state.id_list}></MatchCardGroup>
         </div>
         <div className={this.state.showCards ? 'hiddenCard': ''}>

           <MatchList id_list={this.state.id_list} showName={true}></MatchList>

         </div>
	</Container>

	<div style={{ height: '100px' }}></div>
        <div className="mytitlepartidos">  Jugadores UDEC Primera B 1999</div>
        <div style={{ height: '10px' }}></div>

	<Players torneo="Primera B 1999" />
     </div>


  )
}

}
export default PrimeraB1999;
