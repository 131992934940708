import React from 'react';
import Image from 'react-bootstrap/Image';
import Table from 'react-bootstrap/Table';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ToggleButtonGroup from 'react-bootstrap/ToggleButtonGroup';
import ToggleButton from 'react-bootstrap/ToggleButton';
import { Link } from 'react-router-dom';

class Goleadores extends React.Component {

  constructor(props){
    super(props);
    this.state = {
      torneo: '',
	mapa: {0:'', 1:'Primera División', 2:'Primera B', 3:'Tercera División', 4:'Copa Chile', 5:'SuperCopa',
		6:'Promoción', 7:'Pre-Libertadores', 8:'Pre-Sudamericana', 9:'Libertadores', 10:'Sudamericana'},
      goleadores:[]
    };
  }

  componentDidMount(){

	this.getGoleadores(0);

  }



updateTorneo = (value) => {

    
    this.setState({ torneo: this.state.mapa[value] }, () => {
      // Update the cache after the state has been updated
      this.getGoleadores(value);
    });
  }

 getGoleadores(id_torneo){

        fetch("https://www.registrosudec.com:5000/goleadores", {

    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      id: id_torneo
    })
    }).then(
		res => res.json()
		)
  .then(
    (result) => {
	console.log(result)
        this.setState({ 
        goleadores: result['goles']}) //[{player:"name", goles:int, 
    },
    // Nota: es importante manejar errores aqu   y no en
    // un bloque catch() para que no interceptemos errores
    // de errores reales en los componentes.
    (error) => {
      console.log(error);
      this.setState({fecha: []})
    }
  );


}


  render(){

    return(

      <div style={{textAlign: 'center' }}>
      <div style={{ height: '30px' }}></div>
	<div className="mytitlepartidos"> Lista Goleadores {this.state.torneo}</div>
      <div style={{textAlign: 'center' }}>
	<div style={{ height: '20px' }}></div>
      <Image  className="myescudo-away-main"  src={'/images/escudos/udeconce.png'}/>
      </div>

      <div style={{ height: '50px' }}></div>

	<div>

        <ToggleButtonGroup type="radio" name="options"  defaultValue={0} onChange={this.updateTorneo}>
	 <ToggleButton variant="outline-primary" value={0}>Todos</ToggleButton>
         <ToggleButton variant="outline-primary" value={1}>Primera A</ToggleButton>
         <ToggleButton variant="outline-primary" value={2}>Primera B</ToggleButton>
	<ToggleButton variant="outline-primary" value={4}>Copa Chile</ToggleButton>
         <ToggleButton variant="outline-primary" value={5}>SuperCopa</ToggleButton>
         <ToggleButton variant="outline-primary" value={6}>Promoción</ToggleButton>
        <ToggleButton variant="outline-primary" value={7}>Pre-Libertadores</ToggleButton>
	<ToggleButton variant="outline-primary" value={8}>Pre-Sudamericana</ToggleButton>
         <ToggleButton variant="outline-primary" value={9}>Libertadores</ToggleButton>
        <ToggleButton variant="outline-primary" value={10}>Sudamericana</ToggleButton>
        </ToggleButtonGroup>

      </div>
	<div style={{ height: '20px' }}></div>
	<div>

	 <Row className="justify-content-md-center">
    <Col lg={3} xl={3} sm={4} >

	  <Table responsive variant="dark"  striped bordered hover size="sm">
     <thead>
     <tr>
       <th>Nombre</th>
       <th>Goles</th>
     </tr>
   </thead>
     <tbody>

    {this.state.goleadores.map((i) => {
                   return ( <tr><td>   <Link className="link-name" to={"/goles/" + i['name']}>{i['player']}</Link> </td> <td> {i['goles']} </td></tr> )
            })}
            </tbody>

     </Table>
	</Col>
	</Row>
	</div>
        <div style={{ height: '70px' }}></div>

     </div>

    )

}
}
export default Goleadores;
