import React from 'react';
import MatchCardGroup from './MatchCardGroup';
import MatchList from './MatchList';
import SummaryTable from './SummaryTable';
import Container from 'react-bootstrap/Container';
import ToggleButtonGroup from 'react-bootstrap/ToggleButtonGroup';
import ToggleButton from 'react-bootstrap/ToggleButton';
import Image from 'react-bootstrap/Image';


class SingleTeams extends React.Component {

  constructor(props){
    super(props);
    this.state = {
      showCards: true,
      matches: []
    };
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount(){

     this.getPartidos();

  }


  getPartidos(){
    fetch("https://www.registrosudec.com:5000/lista_partidos", {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      equipo: this.props.equipo
    })
    }).then(res => res.json())
  .then(
    (result) => {
     this.setState({matches: result['ids']})
    },
    (error) => {
      console.log(error);
      this.setState({matches: []})
    }
  )
  }

  handleChange(val){
    console.log(val);
    if(val===1){
      this.setState({showCards:true});
    }
    else{
      this.setState({showCards:false});
    }
  }

  render(){

    return(

      <div style={{textAlign: 'center' }}>
      <div style={{ height: '100px' }}></div>
      <div style={{textAlign: 'center' }}>
      <Image  className="myescudo-away-main" data-tip data-for={this.props.equipo+'-escudo'} src={'/images/escudos/'+this.props.equipo+'.png'}/>
      </div>
      <div style={{ height: '10px' }}></div>

        <SummaryTable key={this.props.equipo} shortname={this.props.equipo}   ></SummaryTable>
        <div style={{ height: '70px' }}></div>

        <ToggleButtonGroup type="radio" name="options" defaultValue={1} onChange={this.handleChange}>
            <ToggleButton variant="secondary" value={1}>Tarjetas</ToggleButton>
            <ToggleButton variant="secondary" value={2}>Listado</ToggleButton>
          </ToggleButtonGroup>
        <Container>
         <div style={{ height: '20px' }}></div>
         <div className={this.state.showCards ? '': 'hiddenCard'}>
           <MatchCardGroup id_list={this.state.matches}></MatchCardGroup>
         </div>
         <div className={this.state.showCards ? 'hiddenCard': ''}>

           <MatchList id_list={this.state.matches} showName={false}></MatchList>




         </div>
        </Container>
     </div>

    )

}
}
export default SingleTeams;
