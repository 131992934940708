import React from 'react';
import Image from 'react-bootstrap/Image';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Table from 'react-bootstrap/Table';
import Tabla from '../Tabla';
import MatchList from '../MatchList';
import MatchCardGroup from '../MatchCardGroup';
import Players from '../Players';
import ToggleButtonGroup from 'react-bootstrap/ToggleButtonGroup';
import ToggleButton from 'react-bootstrap/ToggleButton';
class Apertura2003 extends React.Component {

  constructor(props){
    super(props);
        this.comeBack = this.comeBack.bind(this);
	this.handleChange = this.handleChange.bind(this);
	var id_list = [];
	for (var i = 301; i <= 321; i++) {
        	id_list.push(i);
        }
    this.state = {
	showCards: true,
      path: this.props.path,
	kit_path: '/images/kits/todas/',
      kits: [],
      ctype: this.props.ctype,
      torneo: this.props.torneo,
      id_list: id_list,
      table1:[{'color':'#295e1e','shortname':'udeconce', 'rivalName':'U. de Concepción', 'pj':15, 'pg':7,'pe':3,'pp':5,'gf':29,'gc':21,'dif':8, 'pts':24},
	      {'color':'#295e1e','shortname':'catolica', 'rivalName':'U. Católica', 'pj':15, 'pg':6,'pe':6,'pp':3,'gf':27,'gc':20,'dif':7, 'pts':24},
	      {'color':'#295e1e','shortname':'colocolo', 'rivalName':'Colo Colo', 'pj':15, 'pg':6,'pe':5,'pp':4,'gf':28,'gc':23,'dif':5, 'pts':23},
	      {'color':0,'shortname':'temuco', 'rivalName':'Dep. Temuco', 'pj':15, 'pg':5,'pe':3,'pp':7,'gf':24,'gc':33,'dif':-9, 'pts':18}],

	table2:[{'color':'#295e1e','shortname':'cobreloa', 'rivalName':'Cobreloa', 'pj':15, 'pg':10,'pe':1,'pp':4,'gf':26,'gc':16,'dif':10, 'pts':31},
              {'color':'#295e1e','shortname':'audax', 'rivalName':'Audax Italiano', 'pj':15, 'pg':8,'pe':2,'pp':5,'gf':37,'gc':30,'dif':7, 'pts':26},
              {'color':'#295e1e','shortname':'puertomontt', 'rivalName':'Puerto Montt', 'pj':15, 'pg':7,'pe':4,'pp':4,'gf':26,'gc':22,'dif':4, 'pts':25},
	      {'color':'#295e1e','shortname':'udeconce', 'rivalName':'U. de Concepción', 'pj':15, 'pg':7,'pe':3,'pp':5,'gf':29,'gc':21,'dif':8, 'pts':24},
              {'color':'#295e1e','shortname':'catolica', 'rivalName':'U. Católica', 'pj':15, 'pg':6,'pe':6,'pp':3,'gf':27,'gc':20,'dif':7, 'pts':24},
              {'color':'#295e1e','shortname':'colocolo', 'rivalName':'Colo Colo', 'pj':15, 'pg':6,'pe':5,'pp':4,'gf':28,'gc':23,'dif':5, 'pts':23},
              {'color':'#295e1e','shortname':'cobresal', 'rivalName':'Cobresal', 'pj':15, 'pg':6,'pe':3,'pp':6,'gf':27,'gc':19,'dif':8, 'pts':21},
              {'color':'#295e1e','shortname':'huachipato', 'rivalName':'Huachipato', 'pj':15, 'pg':6,'pe':3,'pp':6,'gf':22,'gc':22,'dif':0, 'pts':21},
              {'color':'#295e1e','shortname':'udechile', 'rivalName':'U. de Chile', 'pj':15, 'pg':4,'pe':8,'pp':3,'gf':30,'gc':24,'dif':6, 'pts':20},
              {'color':'#295e1e','shortname':'rangers', 'rivalName':'Rangers', 'pj':15, 'pg':3,'pe':11,'pp':1,'gf':28,'gc':26,'dif':2, 'pts':20},
              {'color':'#295e1e','shortname':'wanderers', 'rivalName':'S. Wanderers', 'pj':15, 'pg':5,'pe':4,'pp':6,'gf':24,'gc':23,'dif':1, 'pts':19},
              {'color':'#295e1e','shortname':'union', 'rivalName':'U. Española', 'pj':15, 'pg':5,'pe':3,'pp':7,'gf':24,'gc':32,'dif':-8, 'pts':18},
              {'color':0,'shortname':'temuco', 'rivalName':'Dep. Temuco', 'pj':15, 'pg':5,'pe':3,'pp':7,'gf':24,'gc':33,'dif':-9, 'pts':18},
              {'color':0,'shortname':'palestino', 'rivalName':'Palestino', 'pj':15, 'pg':5,'pe':2,'pp':8,'gf':18,'gc':28,'dif':-10, 'pts':17},
              {'color':0,'shortname':'sanfelipe', 'rivalName':'U. San Felipe', 'pj':15, 'pg':3,'pe':4,'pp':8,'gf':13,'gc':26,'dif':-13, 'pts':13},
              {'color':0,'shortname':'coquimbo', 'rivalName':'Coquimbo Unido', 'pj':15, 'pg':2,'pe':2,'pp':11,'gf':12,'gc':30,'dif':-18, 'pts':8}]

    };
  }

  componentDidMount() {
		this.getKits();
	}


getKits(){

        fetch("https://www.registrosudec.com:5000/kits", {

    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      id_list: this.state.id_list
    })
    }).then(
                res => res.json()
                )
  .then(
    (result) => {
        console.log(result)
        this.setState({ 
        kits: result['kits']}) //[{path:"45.png", kit:yellow, short:"blue", soc$
    },
    // Nota: es importante manejar errores aqu   y no en
    // un bloque catch() para que no interceptemos errores
    // de errores reales en los componentes.
    (error) => {
      console.log(error);
      this.setState({fecha: []})
    }
  );
}


  handleChange(val){
    console.log(val);
    if(val===1){
      this.setState({showCards:true});
    }
    else{
      this.setState({showCards:false});
    }
  }

comeBack(){
this.setState({path:this.state.path});
}

subText(color, text){

let comp = [];
comp.push(

	<Row className="justify-content-md-center">
            <Col lg={2} xl={2} >

        <Table bordered={ false } size="sm">
        <tr style={{border: 'none', textAlign: 'rigth' }}>
        <td bgcolor={color}>
        </td>
        <td style={{border: 'none', textAlign: 'center'}}> {text} </td>
        </tr>
        </Table>
        </Col>
        </Row>



)
return comp;

}





render(){


  let subT = this.subText('#295e1e', 'Clasificado a Play-Off');
console.log(this.state.id_list)

  return(

	<div style={{textAlign: 'center' }}>

	 <div style={{ height: '50px' }}></div>
        <div className="mytitlepartidos">  Indumentaria UDEC Apertura 2003</div>
        <div style={{ height: '50px' }}></div> 

        <div style={{display: 'inline-block' }}>
        <table style={{textAlign: 'center' }}>
        <tr>
           {this.state.kits.map((i) => {
                   return ( <td> <Image  className="escudo-home" src={this.state.kit_path+i['path']+".png"}/> </td>)
            })}
        </tr>
        <tr>
        {this.state.kits.map((i) => {
                   return ( <td> <Image  className="myshort" src={this.state.kit_path+i['short']+".png"}/> </td>)
            })}
        </tr>
        <tr>
        {this.state.kits.map((i) => {
                   return ( <td> <Image  className="myshort" src={this.state.kit_path+'socks-'+i['socks']+".png"}/> </td>)
            })}
        </tr>

        <tr>
                {this.state.kits.map((i) => {
                   return ( <td class="circle"> {i['count']} </td>)
            })}
        </tr>
        </table>
        </div>

      <div style={{ height: '50px' }}></div>
	<div className="mytitlepartidos"> Grupo 4 </div>
	<div style={{ height: '10px' }}></div>
	{<Tabla teams={this.state.table1} />}
        <div style={{ height: '10px' }}></div>
         {subT}


	<div style={{ height: '50px' }}></div>
	<div className="mytitlepartidos"> Tabla General</div>
	<div style={{ height: '10px' }}></div>
        {<Tabla teams={this.state.table2} />}
        <div style={{ height: '10px' }}></div>
         {subT}

	
	<div style={{ height: '100px' }}></div>
	<div className="mytitlepartidos">  Partidos UDEC Apertura 2003</div>
	<div style={{ height: '10px' }}></div>

	<ToggleButtonGroup type="radio" name="options" defaultValue={1} onChange={this.handleChange}>
            <ToggleButton variant="secondary" value={1}>Tarjetas</ToggleButton>
            <ToggleButton variant="secondary" value={2}>Listado</ToggleButton>
          </ToggleButtonGroup>
	 <div style={{ height: '10px' }}></div>
	<Container>
	<div className={this.state.showCards ? '': 'hiddenCard'}>
           <MatchCardGroup id_list={this.state.id_list}></MatchCardGroup>
         </div>
         <div className={this.state.showCards ? 'hiddenCard': ''}>

           <MatchList id_list={this.state.id_list} showName={true}></MatchList>

         </div>
	</Container>

	<div style={{ height: '100px' }}></div>
        <div className="mytitlepartidos">  Jugadores UDEC Apertura 2003</div>
        <div style={{ height: '10px' }}></div>

	<Players torneo="Apertura 2003" />

	<div style={{ height: '100px' }}></div>
        <div className="mytitlepartidos">  Indumentaria UDEC Apertura 2003</div>
        <div style={{ height: '10px' }}></div>
	
     </div>


  )
}

}
export default Apertura2003;
