import React from 'react';
import Image from 'react-bootstrap/Image';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Table from 'react-bootstrap/Table';
import Tabla from '../Tabla';
import MatchList from '../MatchList';
import MatchCardGroup from '../MatchCardGroup';
import Players from '../Players';
import ToggleButtonGroup from 'react-bootstrap/ToggleButtonGroup';
import ToggleButton from 'react-bootstrap/ToggleButton';
class Clausura2009 extends React.Component {

  constructor(props){
    super(props);
        this.comeBack = this.comeBack.bind(this);
	this.handleChange = this.handleChange.bind(this);
	var id_list = [];
	for (var i = 585; i <= 608; i++) {
           if([586,595,599,603,606].indexOf(i) === -1){
        	id_list.push(i);
	    }
        }
    this.state = {
	showCards: true,
	kit_path: '/images/kits/todas/',
      kits: [],
      path: this.props.path,
      ctype: this.props.ctype,
      torneo: this.props.torneo,
      id_list: id_list,

	table2:[{'color':0,'shortname':'cobreloa', 'rivalName':'Cobreloa', 'pj':17, 'pg':6,'pe':1,'pp':10,'gf':21,'gc':22,'dif':-1, 'pts':19},
              {'color':'#295e1e','shortname':'audax', 'rivalName':'Audax Italiano', 'pj':17, 'pg':9,'pe':5,'pp':3,'gf':26,'gc':22,'dif':4, 'pts':32},
              {'color':0,'shortname':'curico', 'rivalName':'Curicó', 'pj':17, 'pg':4,'pe':8,'pp':5,'gf':15,'gc':25,'dif':-10, 'pts':17},
	      {'color':'#295e1e','shortname':'udeconce', 'rivalName':'U. de Concepción', 'pj':17, 'pg':8,'pe':3,'pp':6,'gf':23,'gc':23,'dif':0, 'pts':27},
              {'color':'#135683','shortname':'catolica', 'rivalName':'U. Católica', 'pj':17, 'pg':11,'pe':5,'pp':1,'gf':43,'gc':13,'dif':30, 'pts':38},
              {'color':'#295e1e','shortname':'colocolo', 'rivalName':'Colo Colo', 'pj':17, 'pg':8,'pe':4,'pp':5,'gf':28,'gc':19,'dif':9, 'pts':28},
              {'color':0,'shortname':'cobresal', 'rivalName':'Cobresal', 'pj':17, 'pg':5,'pe':5,'pp':7,'gf':18,'gc':17,'dif':1, 'pts':20},
              {'color':0,'shortname':'huachipato', 'rivalName':'Huachipato', 'pj':17, 'pg':5,'pe':2,'pp':10,'gf':20,'gc':29,'dif':-9, 'pts':17},
              {'color':0,'shortname':'udechile', 'rivalName':'U. de Chile', 'pj':17, 'pg':5,'pe':6,'pp':6,'gf':23,'gc':24,'dif':-1, 'pts':21},
              {'color':0,'shortname':'rangers', 'rivalName':'Rangers', 'pj':17, 'pg':7,'pe':3,'pp':7,'gf':26,'gc':29,'dif':-3, 'pts':21},
              {'color':0,'shortname':'iquique', 'rivalName':'Mun. Iquique', 'pj':17, 'pg':1,'pe':6,'pp':10,'gf':18,'gc':36,'dif':-18, 'pts':9},
              {'color':'#295e1e','shortname':'union', 'rivalName':'U. Española', 'pj':17, 'pg':7,'pe':4,'pp':6,'gf':30,'gc':30,'dif':0, 'pts':25},
              {'color':0,'shortname':'nublense', 'rivalName':'Ñublense', 'pj':17, 'pg':5,'pe':4,'pp':8,'gf':15,'gc':21,'dif':-6, 'pts':19},
	      {'color':'#295e1e','shortname':'laserena', 'rivalName':'La Serena', 'pj':17, 'pg':11,'pe':0,'pp':6,'gf':31,'gc':19,'dif':12, 'pts':33},
	      {'color':'#295e1e','shortname':'everton', 'rivalName':'Everton', 'pj':17, 'pg':7,'pe':4,'pp':6,'gf':19,'gc':20,'dif':-1, 'pts':25},
              {'color':0,'shortname':'palestino', 'rivalName':'Palestino', 'pj':17, 'pg':5,'pe':5,'pp':7,'gf':16,'gc':20,'dif':-4, 'pts':20},
              {'color':0,'shortname':'ohiggins', 'rivalName':'O\'Higgins', 'pj':17, 'pg':4,'pe':7,'pp':6,'gf':17,'gc':23,'dif':-6, 'pts':19},
              {'color':'#295e1e','shortname':'morning', 'rivalName':'S. Morning', 'pj':17, 'pg':8,'pe':2,'pp':7,'gf':22,'gc':25,'dif':-3, 'pts':26}],

        table3:[{'color':0,'shortname':'cobreloa', 'rivalName':'Cobreloa', 'pj':34, 'pg':13,'pe':4,'pp':17,'gf':47,'gc':47,'dif':0, 'pts':43},
              {'color':0,'shortname':'audax', 'rivalName':'Audax Italiano', 'pj':34, 'pg':16,'pe':9,'pp':9,'gf':51,'gc':42,'dif':9, 'pts':57},
              {'color':'#E4AB27','shortname':'curico', 'rivalName':'Curicó', 'pj':34, 'pg':9,'pe':12,'pp':13,'gf':39,'gc':53,'dif':-14, 'pts':36},
              {'color':0,'shortname':'udeconce', 'rivalName':'U. de Concepción', 'pj':34, 'pg':14,'pe':8,'pp':12,'gf':47,'gc':47,'dif':0, 'pts':50},
              {'color':0,'shortname':'catolica', 'rivalName':'U. Católica', 'pj':34, 'pg':19,'pe':8,'pp':7,'gf':69,'gc':31,'dif':38, 'pts':65},
              {'color':0,'shortname':'colocolo', 'rivalName':'Colo Colo', 'pj':34, 'pg':13,'pe':8,'pp':13,'gf':56,'gc':47,'dif':9, 'pts':47},
              {'color':0,'shortname':'cobresal', 'rivalName':'Cobresal', 'pj':34, 'pg':9,'pe':12,'pp':13,'gf':41,'gc':51,'dif':-10, 'pts':39},
              {'color':0,'shortname':'huachipato', 'rivalName':'Huachipato', 'pj':34, 'pg':12,'pe':6,'pp':16,'gf':46,'gc':58,'dif':-12, 'pts':42},
              {'color':0,'shortname':'udechile', 'rivalName':'U. de Chile', 'pj':34, 'pg':14,'pe':10,'pp':10,'gf':56,'gc':45,'dif':11, 'pts':52},
              {'color':'#E42727','shortname':'rangers', 'rivalName':'Rangers', 'pj':34, 'pg':10,'pe':8,'pp':16,'gf':46,'gc':57,'dif':-11, 'pts':35},
              {'color':'#E42727','shortname':'iquique', 'rivalName':'Mun. Iquique', 'pj':34, 'pg':8,'pe':11,'pp':15,'gf':48,'gc':59,'dif':-11, 'pts':35},
              {'color':0,'shortname':'union', 'rivalName':'U. Española', 'pj':34, 'pg':19,'pe':6,'pp':9,'gf':68,'gc':47,'dif':21, 'pts':63},
              {'color':0,'shortname':'nublense', 'rivalName':'Ñublense', 'pj':34, 'pg':10,'pe':9,'pp':15,'gf':33,'gc':47,'dif':-14, 'pts':39},
              {'color':0,'shortname':'laserena', 'rivalName':'La Serena', 'pj':34, 'pg':14,'pe':5,'pp':15,'gf':50,'gc':51,'dif':-1, 'pts':47},
              {'color':0,'shortname':'everton', 'rivalName':'Everton', 'pj':34, 'pg':14,'pe':11,'pp':9,'gf':43,'gc':37,'dif':6, 'pts':53},
              {'color':'#E4AB27','shortname':'palestino', 'rivalName':'Palestino', 'pj':34, 'pg':10,'pe':7,'pp':17,'gf':34,'gc':57,'dif':-23, 'pts':37},
              {'color':0,'shortname':'ohiggins', 'rivalName':'O\'Higgins', 'pj':34, 'pg':11,'pe':11,'pp':12,'gf':42,'gc':49,'dif':-7, 'pts':44},
              {'color':0,'shortname':'morning', 'rivalName':'S. Morning', 'pj':34, 'pg':16,'pe':5,'pp':13,'gf':63,'gc':54,'dif':9, 'pts':53}]

    };
  }

  componentDidMount() {
		this.getKits();
		let newtable = this.sortTable(this.state.table2);
  		this.setState({table2:newtable});
		let newtable2 = this.sortTable(this.state.table3);
                this.setState({table3:newtable2});
	}

getKits(){

        fetch("https://www.registrosudec.com:5000/kits", {

    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      id_list: this.state.id_list
    })
    }).then(
                res => res.json()
                )
  .then(
    (result) => {
        console.log(result)
        this.setState({ 
        kits: result['kits']}) //[{path:"45.png", kit:yellow, short:"blue", soc$
    },
    // Nota: es importante manejar errores aqu   y no en
    // un bloque catch() para que no interceptemos errores
    // de errores reales en los componentes.
    (error) => {
      console.log(error);
      this.setState({fecha: []})
    }
  );
}


  handleChange(val){
    console.log(val);
    if(val===1){
      this.setState({showCards:true});
    }
    else{
      this.setState({showCards:false});
    }
  }

comeBack(){
this.setState({path:this.state.path});
}

subText(color, text){

let comp = [];
comp.push(

	<Row className="justify-content-md-center">
            <Col lg={3} xl={3} >

        <Table bordered={ false } size="sm">
        <tr style={{border: 'none', textAlign: 'rigth' }}>
        <td bgcolor={color} style={{width:'20px'}}>
        </td>
        <td style={{border: 'none', textAlign: 'center'}}> {text} </td>
        </tr>
        </Table>
        </Col>
        </Row>



)
return comp;

}


sortTable(table){
       let aux;
      for(let i=table.length-1;i>0;i--){
             for(let j =0; j<i; j++){
                 if( (table[j].pts < table[j+1].pts) || 
                     ((table[j].pts === table[j+1].pts) && (table[j].pg < table[j+1].pg)) ||
                     ((table[j].pts === table[j+1].pts) && (table[j].pg === table[j+1].pg) && (table[j].dif < table[j+1].dif)) ||
                     ((table[j].pts === table[j+1].pts) && (table[j].pg === table[j+1].pg) && (table[j].dif === table[j+1].dif) && (table[j].gf < table[j+1].gf))){
                        aux = table[j+1];
                        table[j+1] = table[j];
                        table[j] = aux;
                } 
             }          
        }
return table;

}



render(){


  let subT = this.subText('#295e1e', 'Clasificado a Play-Off');
  let subC = this.subText('#135683', 'Clasificado a Play-Off y Libertadores 2010');
    let subP = this.subText('#E4AB27', 'Juega Liguilla Promoción');
  let subD = this.subText('#E42727', 'Desciende a Primera B');

  return(

	<div style={{textAlign: 'center' }}>
	  <div style={{ height: '50px' }}></div>
        <div className="mytitlepartidos">  Indumentaria UDEC Clausura 2009</div>
        <div style={{ height: '50px' }}></div> 

        <div style={{display: 'inline-block' }}>
        <table style={{textAlign: 'center' }}>
        <tr>
           {this.state.kits.map((i) => {
                   return ( <td> <Image  className="escudo-home" src={this.state.kit_path+i['path']+".png"}/> </td>)
            })}
        </tr>
        <tr>
        {this.state.kits.map((i) => {
                   return ( <td> <Image  className="myshort" src={this.state.kit_path+i['short']+".png"}/> </td>)
            })}
        </tr>
        <tr>
        {this.state.kits.map((i) => {
                   return ( <td> <Image  className="myshort" src={this.state.kit_path+'socks-'+i['socks']+".png"}/> </td>)
            })}
        </tr>

        <tr>
                {this.state.kits.map((i) => {
                   return ( <td class="circle"> {i['count']} </td>)
            })}
        </tr>
        </table>
        </div>

	<div style={{ height: '50px' }}></div>
	<div className="mytitlepartidos"> Tabla General</div>
	<div style={{ height: '10px' }}></div>
        {<Tabla teams={this.state.table2} />}

	<div> 3 puntos menos a Rangers y Curicó por jugar con 6 extranjeros </div> 
	<div style={{ height: '10px' }}></div>
	{subC}
	{subT}
	
	<div style={{ height: '50px' }}></div>
        <div className="mytitlepartidos"> Tabla Anual 2009</div>
        <div style={{ height: '10px' }}></div>
        {<Tabla teams={this.state.table3} />}

        <div> 3 puntos menos a Rangers y Curicó por jugar con 6 extranjeros </div> 
        <div style={{ height: '10px' }}></div>
        {subP}
	{subD}


	<div style={{ height: '100px' }}></div>
	<div className="mytitlepartidos">  Partidos UDEC Clausura 2009</div>
	<div style={{ height: '10px' }}></div>

	<ToggleButtonGroup type="radio" name="options" defaultValue={1} onChange={this.handleChange}>
            <ToggleButton variant="secondary" value={1}>Tarjetas</ToggleButton>
            <ToggleButton variant="secondary" value={2}>Listado</ToggleButton>
          </ToggleButtonGroup>
	 <div style={{ height: '10px' }}></div>
	<Container>
	<div className={this.state.showCards ? '': 'hiddenCard'}>
           <MatchCardGroup id_list={this.state.id_list}></MatchCardGroup>
         </div>
         <div className={this.state.showCards ? 'hiddenCard': ''}>

           <MatchList id_list={this.state.id_list} showName={true}></MatchList>

         </div>
	</Container>

	<div style={{ height: '100px' }}></div>
        <div className="mytitlepartidos">  Jugadores UDEC Clausura 2009</div>
        <div style={{ height: '10px' }}></div>

	<Players torneo="Clausura 2009" />
     </div>


  )
}

}
export default Clausura2009;
