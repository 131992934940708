
import React from 'react';
import Image from 'react-bootstrap/Image';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Table from 'react-bootstrap/Table';
import Tabla from '../Tabla';
import MatchList from '../MatchList';
import MatchCardGroup from '../MatchCardGroup';
import Players from '../Players';
import ToggleButtonGroup from 'react-bootstrap/ToggleButtonGroup';
import ToggleButton from 'react-bootstrap/ToggleButton';
class PrimeraB1998 extends React.Component {

  constructor(props){
    super(props);
        this.comeBack = this.comeBack.bind(this);
	this.handleChange = this.handleChange.bind(this);
	var id_list = [];
	for (var i = 117; i <= 146; i++) {
        	id_list.push(i);
        }
    this.state = {
	showCards: true,
      path: this.props.path,
	kit_path: '/images/kits/todas/',
      kits: [],
      ctype: this.props.ctype,
      torneo: this.props.torneo,
      id_list: id_list,

		table2:[{'color':0,'shortname':'nublense', 'rivalName':'Ñublense', 'pj':30, 'pg':5,'pe':8,'pp':17,'gf':28,'gc':54,'dif':-26, 'pts':23},
              {'color':'#ff3355','shortname':'atacama', 'rivalName':'Reg. Atacama', 'pj':30, 'pg':3,'pe':10,'pp':17,'gf':28,'gc':54,'dif':-26, 'pts':19},
              {'color':0,'shortname':'ovalle', 'rivalName':'Dep. Ovalle', 'pj':30, 'pg':8,'pe':10,'pp':12,'gf':39,'gc':44,'dif':-5, 'pts':34},
              {'color':0,'shortname':'udeconce', 'rivalName':'U. de Concepción', 'pj':30, 'pg':16,'pe':4,'pp':10,'gf':59,'gc':44,'dif':15, 'pts':52},
              {'color':0,'shortname':'antofagasta', 'rivalName':'Antofagasta', 'pj':30, 'pg':6,'pe':11,'pp':13,'gf':30,'gc':45,'dif':-15, 'pts':29},
              {'color':0,'shortname':'melipilla', 'rivalName':'Melipilla', 'pj':30, 'pg':10,'pe':13,'pp':7,'gf':42,'gc':35,'dif':7, 'pts':43},
              {'color':'#295e1e','shortname':'cobresal', 'rivalName':'Cobresal', 'pj':30, 'pg':19,'pe':8,'pp':3,'gf':79,'gc':22,'dif':57, 'pts':65},
              {'color':0,'shortname':'magallanes', 'rivalName':'Magallanes', 'pj':30, 'pg':10,'pe':10,'pp':10,'gf':37,'gc':41,'dif':-4, 'pts':40},
              {'color':0,'shortname':'arica', 'rivalName':'Dep. Arica', 'pj':30, 'pg':5,'pe':10,'pp':15,'gf':24,'gc':47,'dif':-23, 'pts':25},
              {'color':'#295e1e','shortname':'ohiggins', 'rivalName':'O\'Higgins', 'pj':30, 'pg':19,'pe':2,'pp':9,'gf':61,'gc':39,'dif':22, 'pts':59},
              {'color':0,'shortname':'everton', 'rivalName':'Everton', 'pj':30, 'pg':14,'pe':6,'pp':10,'gf':43,'gc':30,'dif':13, 'pts':48},
              {'color':0,'shortname':'vial', 'rivalName':'Fernández Vial', 'pj':30, 'pg':5,'pe':12,'pp':13,'gf':36,'gc':53,'dif':-17, 'pts':27},
              {'color':'#0033ff','shortname':'morning', 'rivalName':'S. Morning', 'pj':30, 'pg':17,'pe':7,'pp':6,'gf':40,'gc':23,'dif':17, 'pts':58},
              {'color':0,'shortname':'linares', 'rivalName':'Dep. Linares', 'pj':30, 'pg':12,'pe':8,'pp':10,'gf':47,'gc':52,'dif':-5, 'pts':44},
              {'color':0,'shortname':'sanfelipe', 'rivalName':'U. San Felipe', 'pj':30, 'pg':11,'pe':2,'pp':17,'gf':40,'gc':65,'dif':-25, 'pts':35},
              {'color':'#0033ff','shortname':'union', 'rivalName':'U. Española', 'pj':30, 'pg':17,'pe':5,'pp':8,'gf':60,'gc':44,'dif':16, 'pts':56}]
    };
  }

  componentDidMount() {
	this.getKits();
	let newtable2 = this.sortTable(this.state.table2);
                this.setState({table2:newtable2});
	}

getKits(){

        fetch("https://www.registrosudec.com:5000/kits", {

    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      id_list: this.state.id_list
    })
    }).then(
                res => res.json()
                )
  .then(
    (result) => {
        console.log(result)
        this.setState({ 
        kits: result['kits']}) //[{path:"45.png", kit:yellow, short:"blue", soc$
    },
    // Nota: es importante manejar errores aqu   y no en
    // un bloque catch() para que no interceptemos errores
    // de errores reales en los componentes.
    (error) => {
      console.log(error);
      this.setState({fecha: []})
    }
  );
}


  handleChange(val){
    console.log(val);
    if(val===1){
      this.setState({showCards:true});
    }
    else{
      this.setState({showCards:false});
    }
  }

comeBack(){
this.setState({path:this.state.path});
}

subText(color, text){

let comp = [];
comp.push(

	<Row className="justify-content-md-center">
            <Col lg={2} xl={2} >

        <Table bordered={ false } size="sm">
        <tr style={{border: 'none', textAlign: 'rigth' }}>
        <td bgcolor={color}>
        </td>
        <td style={{border: 'none', textAlign: 'center'}}> {text} </td>
        </tr>
        </Table>
        </Col>
        </Row>



)
return comp;

}

sortTable(table){
       let aux;
      for(let i=table.length-1;i>0;i--){
             for(let j =0; j<i; j++){
                 if( (table[j].pts < table[j+1].pts) || 
                     ((table[j].pts === table[j+1].pts) && (table[j].dif < table[j+1].dif)) ||
                     ((table[j].pts === table[j+1].pts) && (table[j].dif === table[j+1].dif) && (table[j].gf < table[j+1].gf))){
                        aux = table[j+1];
                        table[j+1] = table[j];
                        table[j] = aux;
                } 
             }          
        }
return table;

}




render(){


  let subT = this.subText('#295e1e', 'Asciende a Primera A 1999');
  let subP = this.subText('#0033ff', 'Juego Liguilla Promoción');
  let subD = this.subText('#ff3355', 'Desciende a Tercera División 1999');
console.log(this.state.id_list)

  return(

	<div style={{textAlign: 'center' }}>
	 <div style={{ height: '50px' }}></div>
        <div className="mytitlepartidos">  Indumentaria UDEC Primera B 1998</div>
        <div style={{ height: '50px' }}></div> 

        <div style={{display: 'inline-block' }}>
        <table style={{textAlign: 'center' }}>
        <tr>
           {this.state.kits.map((i) => {
                   return ( <td> <Image  className="escudo-home" src={this.state.kit_path+i['path']+".png"}/> </td>)
            })}
        </tr>
        <tr>
        {this.state.kits.map((i) => {
                   return ( <td> <Image  className="myshort" src={this.state.kit_path+i['short']+".png"}/> </td>)
            })}
        </tr>
        <tr>
        {this.state.kits.map((i) => {
                   return ( <td> <Image  className="myshort" src={this.state.kit_path+'socks-'+i['socks']+".png"}/> </td>)
            })}
        </tr>

        <tr>
                {this.state.kits.map((i) => {
                   return ( <td class="circle"> {i['count']} </td>)
            })}
        </tr>
        </table>
        </div>

	<div style={{ height: '50px' }}></div>
	<div className="mytitlepartidos"> Tabla General</div>
	<div style={{ height: '10px' }}></div>
        {<Tabla teams={this.state.table2} />}
        <div style={{ height: '10px' }}></div>
         {subT}
         {subP}
	 {subD}
	
	<div style={{ height: '100px' }}></div>
	<div className="mytitlepartidos">  Partidos UDEC Primera B 1998</div>
	<div style={{ height: '10px' }}></div>

	<ToggleButtonGroup type="radio" name="options" defaultValue={1} onChange={this.handleChange}>
            <ToggleButton variant="secondary" value={1}>Tarjetas</ToggleButton>
            <ToggleButton variant="secondary" value={2}>Listado</ToggleButton>
          </ToggleButtonGroup>
	 <div style={{ height: '10px' }}></div>
	<Container>
	<div className={this.state.showCards ? '': 'hiddenCard'}>
           <MatchCardGroup id_list={this.state.id_list}></MatchCardGroup>
         </div>
         <div className={this.state.showCards ? 'hiddenCard': ''}>

           <MatchList id_list={this.state.id_list} showName={true}></MatchList>

         </div>
	</Container>

	<div style={{ height: '100px' }}></div>
        <div className="mytitlepartidos">  Jugadores UDEC Primera B 1998</div>
        <div style={{ height: '10px' }}></div>

	<Players torneo="Primera B 1998" />
     </div>


  )
}

}
export default PrimeraB1998;
