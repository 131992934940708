import React from 'react';
import Image from 'react-bootstrap/Image';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Table from 'react-bootstrap/Table';
import Tabla from '../Tabla';
import MatchList from '../MatchList';
import MatchCardGroup from '../MatchCardGroup';
import Players from '../Players';
import ToggleButtonGroup from 'react-bootstrap/ToggleButtonGroup';
import ToggleButton from 'react-bootstrap/ToggleButton';
class Apertura2016 extends React.Component {

  constructor(props){
    super(props);
        this.comeBack = this.comeBack.bind(this);
	this.handleChange = this.handleChange.bind(this);
	var id_list = [];
	for (var i = 892; i <= 910; i++) {
              if(i!==894 && i!==896 && i!==900 && i!==902){
        	id_list.push(i);
		}
        }
    this.state = {
	showCards: true,
      path: this.props.path,
         kit_path: '/images/kits/todas/',
      kits: [],
      ctype: this.props.ctype,
      torneo: this.props.torneo,
      id_list: id_list,

	table2:[{'color':0,'shortname':'sanluis', 'rivalName':'San Luis', 'pj':15, 'pg':6,'pe':3,'pp':6,'gf':21,'gc':22,'dif':-1, 'pts':21},
              {'color':0,'shortname':'audax', 'rivalName':'Audax Italiano', 'pj':15, 'pg':4,'pe':3,'pp':8,'gf':17,'gc':28,'dif':-11, 'pts':15},
	     {'color':0,'shortname':'everton', 'rivalName':'Everton', 'pj':15, 'pg':3,'pe':6,'pp':6,'gf':22,'gc':28,'dif':-6, 'pts':15},
              {'color':'#990022','shortname':'iquique', 'rivalName':'Dep. Iquique', 'pj':15, 'pg':8,'pe':4,'pp':3,'gf':28,'gc':22,'dif':6, 'pts':28},
	      {'color':0,'shortname':'udeconce', 'rivalName':'U. de Concepción', 'pj':15, 'pg':4,'pe':2,'pp':9,'gf':15,'gc':25,'dif':-10, 'pts':14},
              {'color':'#990022','shortname':'catolica', 'rivalName':'U. Católica', 'pj':15, 'pg':9,'pe':4,'pp':2,'gf':37,'gc':18,'dif':19, 'pts':31},
              {'color':0,'shortname':'colocolo', 'rivalName':'Colo Colo', 'pj':15, 'pg':6,'pe':5,'pp':4,'gf':26,'gc':20,'dif':6, 'pts':23},
              {'color':0,'shortname':'cobresal', 'rivalName':'Cobresal', 'pj':15, 'pg':4,'pe':4,'pp':7,'gf':14,'gc':21,'dif':-7, 'pts':16},
              {'color':0,'shortname':'huachipato', 'rivalName':'Huachipato', 'pj':15, 'pg':4,'pe':6,'pp':5,'gf':24,'gc':24,'dif':0, 'pts':18},
              {'color':'#3333ff','shortname':'udechile', 'rivalName':'U. de Chile', 'pj':15, 'pg':5,'pe':6,'pp':4,'gf':23,'gc':22,'dif':1, 'pts':21},
              {'color':0,'shortname':'temuco', 'rivalName':'Dep. Temuco', 'pj':15, 'pg':5,'pe':1,'pp':9,'gf':15,'gc':21,'dif':-6, 'pts':16},
              {'color':0,'shortname':'antofagasta', 'rivalName':'Antofagasta', 'pj':15, 'pg':5,'pe':4,'pp':6,'gf':17,'gc':22,'dif':-5, 'pts':19},
              {'color':'#295e1e','shortname':'union', 'rivalName':'U. Española', 'pj':15, 'pg':8,'pe':3,'pp':4,'gf':27,'gc':20,'dif':7, 'pts':27},
              {'color':0,'shortname':'ohiggins', 'rivalName':'O\'Higgins', 'pj':15, 'pg':7,'pe':5,'pp':3,'gf':23,'gc':15,'dif':8, 'pts':26},
              {'color':'#3333ff','shortname':'palestino', 'rivalName':'Palestino', 'pj':15, 'pg':6,'pe':3,'pp':6,'gf':25,'gc':19,'dif':6, 'pts':21},
              {'color':0,'shortname':'wanderers', 'rivalName':'S. Wanderers', 'pj':15, 'pg':5,'pe':3,'pp':7,'gf':13,'gc':20,'dif':-7, 'pts':18}]

    };
  }

  componentDidMount() {
		this.getKits();
		let newtable = this.sortTable(this.state.table2);
  		this.setState({table2:newtable});

	}


getKits(){

        fetch("https://www.registrosudec.com:5000/kits", {

    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      id_list: this.state.id_list
    })
    }).then(
                res => res.json()
                )
  .then(
    (result) => {
        console.log(result)
        this.setState({ 
        kits: result['kits']}) //[{path:"45.png", kit:yellow, short:"blue", socks:"blue", count:15, 
    },
    // Nota: es importante manejar errores aqu   y no en
    // un bloque catch() para que no interceptemos errores
    // de errores reales en los componentes.
    (error) => {
      console.log(error);
      this.setState({fecha: []})
    }
  );
}


  handleChange(val){
    console.log(val);
    if(val===1){
      this.setState({showCards:true});
    }
    else{
      this.setState({showCards:false});
    }
  }

comeBack(){
this.setState({path:this.state.path});
}

subText(color, text){

let comp = [];
comp.push(

	<Row className="justify-content-md-center">
            <Col lg={3} xl={3} >

        <Table bordered={ false } size="sm">
        <tr style={{border: 'none', textAlign: 'rigth' }}>
        <td bgcolor={color} style={{width:'20px'}}>
        </td>
        <td style={{border: 'none', textAlign: 'center'}}> {text} </td>
        </tr>
        </Table>
        </Col>
        </Row>



)
return comp;

}


sortTable(table){
       let aux;
      for(let i=table.length-1;i>0;i--){
             for(let j =0; j<i; j++){
                 if( (table[j].pts < table[j+1].pts) || 
                     ((table[j].pts === table[j+1].pts) && (table[j].dif < table[j+1].dif)) ||
                     ((table[j].pts === table[j+1].pts) && (table[j].dif === table[j+1].dif) && (table[j].gf < table[j+1].gf))){
			aux = table[j+1];
			table[j+1] = table[j];
                        table[j] = aux;
		} 
	     }		
	}
return table;

}


render(){


  let subT = this.subText('#295e1e', 'Clasificado a Liguilla Lib/Sud');
  let subS = this.subText('#3333ff', 'Clasificado a Sudamericana');
  let subL = this.subText('#990022', 'Clasificado a Libertadores');
  return(

	<div style={{textAlign: 'center' }}>

	        <div style={{ height: '50px' }}></div>
        <div className="mytitlepartidos">  Indumentaria UDEC Apertura 2016</div>
        <div style={{ height: '50px' }}></div> 

        <div style={{display: 'inline-block' }}>
        <table style={{textAlign: 'center' }}>
        <tr>
           {this.state.kits.map((i) => {
                   return ( <td> <Image  className="escudo-home" src={this.state.kit_path+i['path']+".png"}/> </td>)
            })}
        </tr>
        <tr>
        {this.state.kits.map((i) => {
                   return ( <td> <Image  className="myshort" src={this.state.kit_path+i['short']+".png"}/> </td>)
            })}
        </tr>
        <tr>
        {this.state.kits.map((i) => {
                   return ( <td> <Image  className="myshort" src={this.state.kit_path+'socks-'+i['socks']+".png"}/> </td>)
            })}
        </tr>

        <tr>
                {this.state.kits.map((i) => {
                   return ( <td class="circle"> {i['count']} </td>)
            })}
        </tr>
        </table>
        </div>



	<div style={{ height: '50px' }}></div>
	<div className="mytitlepartidos"> Tabla General</div>
	<div style={{ height: '10px' }}></div>
        {<Tabla teams={this.state.table2} />}
        <div style={{ height: '10px' }}></div>
	{subL}
         {subT}
	{subS}
	 
	
	<div style={{ height: '100px' }}></div>
	<div className="mytitlepartidos">  Partidos UDEC Apertura 2016</div>
	<div style={{ height: '10px' }}></div>

	<ToggleButtonGroup type="radio" name="options" defaultValue={1} onChange={this.handleChange}>
            <ToggleButton variant="secondary" value={1}>Tarjetas</ToggleButton>
            <ToggleButton variant="secondary" value={2}>Listado</ToggleButton>
          </ToggleButtonGroup>
	 <div style={{ height: '10px' }}></div>
	<Container>
	<div className={this.state.showCards ? '': 'hiddenCard'}>
           <MatchCardGroup id_list={this.state.id_list}></MatchCardGroup>
         </div>
         <div className={this.state.showCards ? 'hiddenCard': ''}>

           <MatchList id_list={this.state.id_list} showName={true}></MatchList>

         </div>
	</Container>

	<div style={{ height: '100px' }}></div>
        <div className="mytitlepartidos">  Jugadores UDEC Apertura 2016</div>
        <div style={{ height: '10px' }}></div>

	<Players torneo="Apertura 2016" />
     </div>


  )
}

}
export default Apertura2016;
