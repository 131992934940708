import React from 'react';
import Container from 'react-bootstrap/Container';
import Image from 'react-bootstrap/Image';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Table from 'react-bootstrap/Table';
import Tabla from '../Tabla';
import MatchList from '../MatchList';
import MatchCardGroup from '../MatchCardGroup';
import Players from '../Players';
import ToggleButtonGroup from 'react-bootstrap/ToggleButtonGroup';
import ToggleButton from 'react-bootstrap/ToggleButton';
import TablaProm2022 from '../TablaProm2022';
class PrimeraB2022 extends React.Component {

  constructor(props){
    super(props);
        this.comeBack = this.comeBack.bind(this);
	this.handleChange = this.handleChange.bind(this);
	var id_list = [];
	for (var i = 1077; i <= 1115; i++) {
             if(i != 1103 && i != 1104 && i != 1083 && i != 1094 && i != 1095){
        	id_list.push(i);
		}
        }
    this.state = {
	showCards: true,
	kits:[],
	kit_path: '/images/kits/todas/',
      path: this.props.path,
      ctype: this.props.ctype,
      torneo: this.props.torneo,
      id_list: id_list,

	table2:[{'color':0,'shortname':'recoleta', 'rivalName':'Dep. Recoleta', 'pj':32, 'pg':8,'pe':10,'pp':14,'gf':34,'gc':41,'dif':-7, 'pts':34},
              {'color':'#295e1e','shortname':'magallanes', 'rivalName':'Magallanes', 'pj':32, 'pg':22,'pe':6,'pp':4,'gf':61,'gc':23,'dif':38, 'pts':72},
              {'color':0,'shortname':'barnechea', 'rivalName':'Barnechea', 'pj':32, 'pg':12,'pe':8,'pp':12,'gf':47,'gc':43,'dif':4, 'pts':44},
	      {'color':'#ffa500','shortname':'udeconce', 'rivalName':'U. de Concepción', 'pj':32, 'pg':12,'pe':10,'pp':10,'gf':42,'gc':34,'dif':8, 'pts':46},
              {'color':'#ffa500','shortname':'copiapo', 'rivalName':'Dep. Copiapó', 'pj':32, 'pg':15,'pe':7,'pp':10,'gf':46,'gc':34,'dif':12, 'pts':52},
              {'color':0,'shortname':'wanderers', 'rivalName':'S. Wanderers', 'pj':32, 'pg':10,'pe':12,'pp':10,'gf':37,'gc':37,'dif':0, 'pts':42},
              {'color':'#ffa500','shortname':'cobreloa', 'rivalName':'Cobreloa', 'pj':32, 'pg':20,'pe':8,'pp':4,'gf':41,'gc':24,'dif':17, 'pts':68},
              {'color':0,'shortname':'rangers', 'rivalName':'Rangers', 'pj':32, 'pg':14,'pe':4,'pp':14,'gf':39,'gc':40,'dif':-1, 'pts':46},
              {'color':0,'shortname':'melipilla', 'rivalName':'Melipilla', 'pj':32, 'pg':7,'pe':9,'pp':16,'gf':30,'gc':49,'dif':-19, 'pts':30},
              {'color':0,'shortname':'morning', 'rivalName':'S. Morning', 'pj':32, 'pg':8,'pe':10,'pp':14,'gf':37,'gc':47,'dif':-10, 'pts':34},
              {'color':0,'shortname':'sanluis', 'rivalName':'San Luis', 'pj':32, 'pg':7,'pe':13,'pp':12,'gf':27,'gc':42,'dif':-15, 'pts':34},
              {'color':'#ffa500','shortname':'sanfelipe', 'rivalName':'U. San Felipe', 'pj':32, 'pg':15,'pe':7,'pp':10,'gf':38,'gc':35,'dif':3, 'pts':52},
              {'color':0,'shortname':'temuco', 'rivalName':'Dep. Temuco', 'pj':32, 'pg':10,'pe':8,'pp':14,'gf':31,'gc':41,'dif':-10, 'pts':38},
              {'color':'#ffa500','shortname':'puertomontt', 'rivalName':'Puerto Montt', 'pj':32, 'pg':12,'pe':11,'pp':9,'gf':36,'gc':31,'dif':5, 'pts':47},
              {'color':0,'shortname':'santacruz', 'rivalName':'Dep. Santa Cruz', 'pj':32, 'pg':9,'pe':9,'pp':14,'gf':34,'gc':39,'dif':-5, 'pts':36},
		{'color':'#ff3355','shortname':'vial', 'rivalName':'Fernández Vial', 'pj':32, 'pg':3,'pe':17,'pp':12,'gf':26,'gc':39,'dif':-13, 'pts':26},
              {'color':0,'shortname':'iquique', 'rivalName':'Dep. Iquique', 'pj':32, 'pg':8,'pe':11,'pp':13,'gf':31,'gc':38,'dif':-7, 'pts':35}],

		table3:[
		 {'color':0,'shortname':'magallanes', 'rivalName':'Magallanes', 'pts21':41, 'pts22':72,'pts':113,'pon':1.82},
		 {'color':0,'shortname':'copiapo', 'rivalName':'Dep. Copiapó', 'pts21':52, 'pts22':52,'pts':104,'pon':1.68},
		  {'color':0,'shortname':'cobreloa', 'rivalName':'Cobreloa', 'pts21':33, 'pts22':68,'pts':101,'pon':1.63},
		  {'color':0,'shortname':'puertomontt', 'rivalName':'Puerto Montt', 'pts21':48, 'pts22':47,'pts':95,'pon':1.53},
		  {'color':0,'shortname':'sanfelipe', 'rivalName':'U. San Felipe', 'pts21':36, 'pts22':52,'pts':88,'pon':1.42},
		  {'color':0,'shortname':'temuco', 'rivalName':'Dep. Temuco', 'pts21':49, 'pts22':38,'pts':87,'pon':1.4},
		  {'color':0,'shortname':'morning', 'rivalName':'S. Morning', 'pts21':51, 'pts22':34,'pts':85,'pon':1.37},
		  {'color':0,'shortname':'wanderers', 'rivalName':'S. Wanderers', 'pts21':0,'pts22':42,'pts':42,'pon':1.31},
		  {'color':0,'shortname':'rangers', 'rivalName':'Rangers', 'pts21':35, 'pts22':46,'pts':81,'pon':1.31},
		  {'color':0,'shortname':'santacruz', 'rivalName':'Dep. Santa Cruz', 'pts21':45, 'pts22':36,'pts':81,'pon':1.31},
		  {'color':0,'shortname':'udeconce', 'rivalName':'U. de Concepción', 'pts21':33, 'pts22':46,'pts':79,'pon':1.27},
                 {'color':0,'shortname':'iquique', 'rivalName':'Dep. Iquique', 'pts21':40, 'pts22':35,'pts':75,'pon':1.21},
                 {'color':0,'shortname':'sanluis', 'rivalName':'San Luis', 'pts21':40, 'pts22':34,'pts':74,'pon':1.19},
                   {'color':0,'shortname':'barnechea', 'rivalName':'Barnechea', 'pts21':24, 'pts22':44,'pts':68,'pon':1.1},
                 {'color':0,'shortname':'vial', 'rivalName':'Fernández Vial', 'pts21':40, 'pts22':26,'pts':66,'pon':1.06},
                 {'color':0,'shortname':'recoleta', 'rivalName':'Dep. Recoleta', 'pts21':0, 'pts22':34,'pts':34,'pon':1.06},
              {'color':'#ff3355','shortname':'melipilla', 'rivalName':'Melipilla', 'pts21':0, 'pts22':30,'pts':30,'pon':0.94}
             ]


    };
  }

  componentDidMount() {
	this.getKits();
	let newtable2 = this.sortTable(this.state.table2);
                this.setState({table2:newtable2});
	}


getKits(){

        fetch("https://www.registrosudec.com:5000/kits", {

    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      id_list: this.state.id_list
    })
    }).then(
                res => res.json()
                )
  .then(
    (result) => {
        console.log(result)
        this.setState({ 
        kits: result['kits']}) //[{path:"45.png", kit:yellow, short:"blue", socks:"blue", count:15, 
    },
    // Nota: es importante manejar errores aqu   y no en
    // un bloque catch() para que no interceptemos errores
    // de errores reales en los componentes.
    (error) => {
      console.log(error);
      this.setState({fecha: []})
    }
  );
}



  handleChange(val){
    console.log(val);
    if(val===1){
      this.setState({showCards:true});
    }
    else{
      this.setState({showCards:false});
    }
  }

comeBack(){
this.setState({path:this.state.path});
}

subText(color, text){

let comp = [];
comp.push(

	<Row className="justify-content-md-center">
            <Col lg={2} xl={2} >

        <Table bordered={ false } size="sm">
        <tr style={{border: 'none', textAlign: 'rigth' }}>
        <td bgcolor={color}>
        </td>
        <td style={{border: 'none', textAlign: 'center'}}> {text} </td>
        </tr>
        </Table>
        </Col>
        </Row>



)
return comp;

}

sortTable(table){
       let aux;
      for(let i=table.length-1;i>0;i--){
             for(let j =0; j<i; j++){
                 if( (table[j].pts < table[j+1].pts) || 
                     ((table[j].pts === table[j+1].pts) && (table[j].dif < table[j+1].dif)) ||
                     ((table[j].pts === table[j+1].pts) && (table[j].dif === table[j+1].dif) && (table[j].gf < table[j+1].gf))){
                        aux = table[j+1];
                        table[j+1] = table[j];
                        table[j] = aux;
                } 
             }          
        }
return table;

}




render(){


  let subT = this.subText('#295e1e', 'Asciende a Primera A 2023');
  let subD = this.subText('#ff3355', 'Desciende a Segunda División 2023');
console.log(this.state.id_list);
let subP = this.subText('#ffa500', 'Clasifica a liguilla de ascenso');

  return(

	<div style={{textAlign: 'center' }}>

	<div style={{ height: '50px' }}></div>
        <div className="mytitlepartidos">  Indumentaria UDEC Primera B 2022</div>
        <div style={{ height: '50px' }}></div> 

        <div style={{display: 'inline-block' }}>
        <table style={{textAlign: 'center' }}>
        <tr>
           {this.state.kits.map((i) => {
                   return ( <td> <Image  className="escudo-home" src={this.state.kit_path+i['path']+".png"}/> </td>)
            })}
        </tr>
        <tr>
        {this.state.kits.map((i) => {
                   return ( <td> <Image  className="myshort" src={this.state.kit_path+i['short']+".png"}/> </td>)
            })}
        </tr>
        <tr>
        {this.state.kits.map((i) => {
                   return ( <td> <Image  className="myshort" src={this.state.kit_path+'socks-'+i['socks']+".png"}/> </td>)
            })}
        </tr>

        <tr>
                {this.state.kits.map((i) => {
                   return ( <td class="circle"> {i['count']} </td>)
            })}
        </tr>
        </table>
        </div>

	<div style={{ height: '50px' }}></div>
	<div className="mytitlepartidos"> Tabla General</div>
	<div style={{ height: '10px' }}></div>
        {<Tabla teams={this.state.table2} />}
        <div style={{ height: '10px' }}></div>
         {subT}
	 {subP}
	 {subD}

	        <div style={{ height: '50px' }}></div>
        <div className="mytitlepartidos"> Tabla Promedios 2021-22</div>
        <div style={{ height: '10px' }}></div>
        {<TablaProm2022 teams={this.state.table3} />}
        <div style={{ height: '10px' }}></div>
        {subD}


	<div style={{ height: '100px' }}></div>
	<div className="mytitlepartidos">  Partidos UDEC Primera B 2022</div>
	<div style={{ height: '10px' }}></div>

	<ToggleButtonGroup type="radio" name="options" defaultValue={1} onChange={this.handleChange}>
            <ToggleButton variant="secondary" value={1}>Tarjetas</ToggleButton>
            <ToggleButton variant="secondary" value={2}>Listado</ToggleButton>
          </ToggleButtonGroup>
	 <div style={{ height: '10px' }}></div>
	<Container>
	<div className={this.state.showCards ? '': 'hiddenCard'}>
           <MatchCardGroup id_list={this.state.id_list}></MatchCardGroup>
         </div>
         <div className={this.state.showCards ? 'hiddenCard': ''}>

           <MatchList id_list={this.state.id_list} showName={true}></MatchList>

         </div>
	</Container>

	<div style={{ height: '100px' }}></div>
        <div className="mytitlepartidos">  Jugadores UDEC Primera B 2022</div>
        <div style={{ height: '10px' }}></div>

	<Players torneo="Primera B 2022" />
     </div>


  )
}

}
export default PrimeraB2022;
