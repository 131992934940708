import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import MatchCard from './MatchCard';





class MatchCardGroup extends React.Component {


render(){


  return(
    <Row className="justify-content-md-center">
    {this.props.id_list.map((i) => {
                   return (<Col lg={4} xl={4}  > <MatchCard key={i} id={i} ></MatchCard>  </Col>)
            })}
    </Row>


  )
}
}
export default MatchCardGroup;
