import React from 'react';
import Card from 'react-bootstrap/Card';
import Image from 'react-bootstrap/Image';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import ReactTooltip from 'react-tooltip';




class MatchCard extends React.Component {

  constructor(props){
    super(props);
    this.state = {
      fecha: '',
      fixture: '',
      rivalName: null,
      rivalShortName: null,
      state: null,
      tournament: null,
      goal_home:'',
      goal_away:'',
      stadium:'',
      goles_udec: [],
      goles_rival: [],
      rojas_udec: [],
      rojas_rival: [],
      fallados_udec: [],
      fallados_rival: [],
      penales_udec: [],
      penales_rival: [],
      pk_udec: 0,
      pk_rival: 0,
      match_image: null,
    };
  }

  componentDidMount() {

    fetch("https://www.registrosudec.com:5000/match", {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      id: this.props.id
    })
    }).then(res => res.json())
  .then(
    (result) => {
     this.setState({fecha: result['date'],
                    rivalName: result['name'],
                    rivalShortName: result['shortname'],
                    fixture: result['fixture'],
                    state: result['state'],
                    tournament: result['tournament'],
                    goal_home: result['goalh'],
                    goal_away: result['goala'],
                    goles_udec: result['goles_udec'],
                    goles_rival: result['goles_rival'],
                    rojas_udec: result['rojas_udec'],
                    rojas_rival: result['rojas_rival'],
                    fallados_udec: result['fallados_udec'],
                    fallados_rival: result['fallados_rival'],
                    penales_udec: result['penales_udec'],
                    penales_rival: result['penales_rival'],
                    pk_udec: result['pk_udec'],
                    pk_rival: result['pk_rival'],
		    video: result['link'],
                    match_image: '/images/partidos/'+this.normalize(result['tournament'])+'/'+this.props.id+'/1.png',
                    stadium: result['stadium']})


    },
    // Nota: es importante manejar errores aquí y no en
    // un bloque catch() para que no interceptemos errores
    // de errores reales en los componentes.
    (error) => {
      console.log(error);
      this.setState({fecha: []})
    }
  )

}


normalize = (function() {
  var from = "ÃÀÁÄÂÈÉËÊÌÍÏÎÒÓÖÔÙÚÜÛãàáäâèéëêìíïîòóöôùúüûÑñÇç",
      to   = "AAAAAEEEEIIIIOOOOUUUUaaaaaeeeeiiiioooouuuunncc",
      mapping = {};

  for(var i = 0, j = from.length; i < j; i++ )
      mapping[ from.charAt( i ) ] = to.charAt( i );

  return function( str ) {
      var ret = [];
      for( var i = 0, j = str.length; i < j; i++ ) {
          var c = str.charAt( i );
          if( mapping.hasOwnProperty( str.charAt( i ) ) )
              ret.push( mapping[ c ] );
          else
              ret.push( c );
      }
      return ret.join( '' );
  }

})();


merge_arrays(goles, rojas, fallados){

  let aux = [];
  let final = [];
  let largo_goles = goles.length;
  let largo_rojas = rojas.length;
  let largo_fallados = fallados.length;

  let it_goles = 0;
  let it_rojas = 0;


  while(it_goles<largo_goles || it_rojas<largo_rojas){

    if(it_goles>=largo_goles){
      aux.push({'player':rojas[it_rojas].player, 'minute':rojas[it_rojas].minute, 'icono':'red'})
      it_rojas++;
    }
    else if(it_rojas>=largo_rojas){
      aux.push({'player':goles[it_goles].player, 'minute':goles[it_goles].minute, 'icono':goles[it_goles].tipo})
      it_goles++;
    }
    else{
      if(parseFloat(goles[it_goles].minute.replace("+",".")) >= parseFloat(rojas[it_rojas].minute.replace("+","."))){
        aux.push({'player':rojas[it_rojas].player, 'minute':rojas[it_rojas].minute, 'icono':'red'})
        it_rojas++;
      }
      else if(parseFloat(goles[it_goles].minute.replace("+",".")) < parseFloat(rojas[it_rojas].minute.replace("+","."))){
        aux.push({'player':goles[it_goles].player, 'minute':goles[it_goles].minute, 'icono':goles[it_goles].tipo})
        it_goles++;
      }
    }
  }
  let largo_aux = aux.length;
  let it_aux = 0;
  let it_fallados = 0;

  if(largo_fallados === 0){
    final = aux;
  }
  else{
    while(it_aux < largo_aux || it_fallados < largo_fallados){

       if(it_aux >= largo_aux){
         final.push({'player':fallados[it_fallados].player, 'minute':fallados[it_fallados].minute, 'icono':'missed'});
         it_fallados++;
       }
       else if(it_fallados >= largo_fallados){
         final.push(aux[it_aux]);
         it_aux++;
       }
       else{
         if(parseFloat(aux[it_aux].minute.replace("+",".")) >= parseFloat(fallados[it_fallados].minute.replace("+","."))){
           final.push({'player':fallados[it_fallados].player, 'minute':fallados[it_fallados].minute, 'icono':'missed'});
           it_fallados++;
         }
         else if(parseFloat(aux[it_aux].minute.replace("+",".")) < parseFloat(fallados[it_fallados].minute.replace("+","."))){
           final.push(aux[it_aux]);
           it_aux++;
         }
       }

    }

  }

  return final;

}

setGoals(){

  let merged_udec = this.merge_arrays(this.state.goles_udec, this.state.rojas_udec, this.state.fallados_udec);
  let merged_rival = this.merge_arrays(this.state.goles_rival, this.state.rojas_rival, this.state.fallados_rival);
  let num_udec = merged_udec.length;
  let num_rival = merged_rival.length;

  let mayor = Math.max(num_udec,num_rival);
  let componente_goles = [];
  for(let i=0;i<mayor;i++){

         componente_goles.push(
           <Row className="justify-content-md-center">
           <Col  lg={6} xl={6} md={6} xs={6} className="match_card">
           {this.state.state === 0 && i < num_udec && <Image  className={'my-'+merged_udec[i].icono} src={'/images/iconos/' + merged_udec[i].icono + '.png'}/>}
          {this.state.state === 0 && i < num_udec &&  merged_udec[i].player + ' ' + merged_udec[i].minute}

            {this.state.state === 1 && i < num_rival && <Image  className={'my-'+merged_rival[i].icono} src={'/images/iconos/' + merged_rival[i].icono + '.png'}/>}
             {this.state.state === 1 && i < num_rival && merged_rival[i].player + ' ' + merged_rival[i].minute}
           </Col>
           <Col  lg={6} xl={6} md={6} xs={6}>
           {this.state.state === 1 && i < num_udec && <Image  className={'my-'+merged_udec[i].icono} src={'/images/iconos/' + merged_udec[i].icono + '.png'}/>}
           {this.state.state === 1 && i < num_udec && merged_udec[i].player + ' ' + merged_udec[i].minute}

            {this.state.state === 0 && i < num_rival && <Image  className={'my-'+merged_rival[i].icono} src={'/images/iconos/' + merged_rival[i].icono + '.png'}/>}
            {this.state.state === 0 && i < num_rival && merged_rival[i].player + ' ' + merged_rival[i].minute}

          </Col>
          </Row>
         )



  }
  return componente_goles;
}

render(){

  let goles = this.setGoals();


  return(


              <Card className="mycol text-center" border="primary">
              <Card.Header>{this.state.fixture}</Card.Header>

               <Card.Img variant="top" src={this.state.match_image} onError={() => this.setState({match_image: '/images/partidos/Default/1/1.png'})} />
                <Card.Body>

                  <Row >
                  <Col  lg={4} xl={4} md={4} xs={4}>
                  {this.state.state === 0 &&
                  <div>
                  <Image  className="myescudo-home" data-tip data-for='udec-escudo' src='/images/escudos/udeconce.png'/>
                  <ReactTooltip id='udec-escudo' type='light' effect='float'>
                    <span>Universidad de Concepción</span>
                  </ReactTooltip>
                  </div>
                  }
                  {this.state.state === 1 && this.state.rivalShortName &&
                  <div>
                  <Image  className="myescudo-home" data-tip data-for={this.state.rivalShortName+'-escudo'} src={'/images/escudos/'+this.state.rivalShortName+'.png'}/>
                  <ReactTooltip id={this.state.rivalShortName+'-escudo'} type='light' effect='float'>
                    <span>{this.state.rivalName}</span>
                  </ReactTooltip>
                  </div>
                  }
                  </Col>
                  <Col  lg={4} xl={4} md={4} xs={4}>
                  <Card.Title>{this.state.goal_home + " - " + this.state.goal_away}
                  {this.state.state === 0 && this.state.penales_udec.length > 0 &&
                  <div className="pen-text"> {'('+this.state.pk_udec+'-'+this.state.pk_rival+' pen.)'} </div>}
                  {this.state.state === 1 && this.state.penales_udec.length > 0 &&
                  <div className="pen-text"> {'('+this.state.pk_rival+'-'+this.state.pk_udec+' pen.)'} </div>}
                  </Card.Title>
                  </Col>
                  <Col  lg={4} xl={4} md={4} xs={4}>
                  {this.state.state === 0 && this.state.rivalShortName &&
                  <div>
                  <Image  className="myescudo-away" data-tip data-for={this.state.rivalShortName+'-escudo'} src={'/images/escudos/'+this.state.rivalShortName+'.png'}/>
                  <ReactTooltip id={this.state.rivalShortName+'-escudo'} type='light' effect='float'>
                    <span>{this.state.rivalName}</span>
                  </ReactTooltip>
                  </div>
                  }
                  {this.state.state === 1 &&
                    <div>
                    <Image  className="myescudo-away" data-tip data-for='udec-escudo' src='/images/escudos/udeconce.png'/>
                    <ReactTooltip id='udec-escudo' type='light' effect='float'>
                      <span>Universidad de Concepción</span>
                    </ReactTooltip>
                    </div>
                  }
                  </Col>
                 </Row>
                 <Card.Subtitle lg={12} xl={12} md={12} xs={12} className="justify-content-md-center mb-2 text-muted text-center">{this.state.stadium}</Card.Subtitle>


                  <Card.Text className="mytext">



                  {goles}



                  </Card.Text>
                 {this.state.video !== '' &&  <Button href={"/partido/" + this.props.id} variant="success">Ir al partido</Button>}
		{this.state.video === '' &&  <Button href={"/partido/" + this.props.id} variant="secondary">Ir al partido</Button>}
                </Card.Body>

                 <Card.Footer className="text-muted">{this.state.fecha}</Card.Footer>
              </Card>


  )
}
}
export default MatchCard;
