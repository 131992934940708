import React from 'react';
import Container from 'react-bootstrap/Container';
import Image from 'react-bootstrap/Image';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Table from 'react-bootstrap/Table';
import Tabla from '../Tabla';
import MatchList from '../MatchList';
import MatchCardGroup from '../MatchCardGroup';
import Players from '../Players';
import ToggleButtonGroup from 'react-bootstrap/ToggleButtonGroup';
import ToggleButton from 'react-bootstrap/ToggleButton';
import TablaProm2020 from '../TablaProm2020';
class Campeonato2020 extends React.Component {

  constructor(props){
    super(props);
        this.comeBack = this.comeBack.bind(this);
	this.handleChange = this.handleChange.bind(this);
	var id_list = [];
	for (var i = 1011; i <= 1044; i++) {
        	id_list.push(i);
        }
    this.state = {
	showCards: true,
	                kits:[],
        kit_path: '/images/kits/todas/',
      path: this.props.path,
      ctype: this.props.ctype,
      torneo: this.props.torneo,
      id_list: id_list,


        table3:[{'color':'#295e1e','shortname':'cobresal', 'rivalName':'Cobresal', 'pj':34, 'pg':13,'pe':8,'pp':13,'gf':45,'gc':40,'dif':5, 'pts':47},
              {'color':0,'shortname':'audax', 'rivalName':'Audax Italiano', 'pj':34, 'pg':10,'pe':11,'pp':13,'gf':47,'gc':50,'dif':-3, 'pts':41},
              {'color':'#135683','shortname':'lacalera', 'rivalName':'U. La Calera', 'pj':34, 'pg':17,'pe':6,'pp':11,'gf':59,'gc':41,'dif':18, 'pts':57},
              {'color':0,'shortname':'udeconce', 'rivalName':'U. de Concepción', 'pj':34, 'pg':9,'pe':14,'pp':11,'gf':38,'gc':46,'dif':-8, 'pts':41},
              {'color':'#135683','shortname':'catolica', 'rivalName':'U. Católica', 'pj':34, 'pg':18,'pe':11,'pp':5,'gf':65,'gc':35,'dif':30, 'pts':65},
              {'color':'#835612','shortname':'colocolo', 'rivalName':'Colo Colo', 'pj':34, 'pg':9,'pe':12,'pp':13,'gf':33,'gc':43,'dif':-10, 'pts':39},
              {'color':0,'shortname':'curico', 'rivalName':'Curicó', 'pj':34, 'pg':13,'pe':7,'pp':14,'gf':40,'gc':52,'dif':-12, 'pts':46},
              {'color':'#295e1e','shortname':'huachipato', 'rivalName':'Huachipato', 'pj':34, 'pg':13,'pe':7,'pp':14,'gf':43,'gc':44,'dif':-1, 'pts':46},
              {'color':'#135683','shortname':'udechile', 'rivalName':'U. de Chile', 'pj':34, 'pg':13,'pe':13,'pp':8,'gf':49,'gc':33,'dif':16, 'pts':52},
              {'color':0,'shortname':'everton', 'rivalName':'Everton', 'pj':34, 'pg':10,'pe':13,'pp':11,'gf':37,'gc':41,'dif':-4, 'pts':43},
              {'color':0,'shortname':'iquique', 'rivalName':'Dep. Iquique', 'pj':34, 'pg':9,'pe':11,'pp':14,'gf':38,'gc':46,'dif':-8, 'pts':38},
              {'color':'#135683','shortname':'union', 'rivalName':'U. Española', 'pj':34, 'pg':14,'pe':10,'pp':10,'gf':55,'gc':53,'dif':2, 'pts':52},
              {'color':'#295e1e','shortname':'palestino', 'rivalName':'Palestino', 'pj':34, 'pg':14,'pe':9,'pp':11,'gf':49,'gc':45,'dif':4, 'pts':51},
              {'color':0,'shortname':'ohiggins', 'rivalName':'O\'Higgins', 'pj':34, 'pg':12,'pe':9,'pp':13,'gf':40,'gc':39,'dif':1, 'pts':45},
		{'color':0,'shortname':'wanderers', 'rivalName':'S. Wanderers', 'pj':34, 'pg':12,'pe':8,'pp':14,'gf':42,'gc':53,'dif':-11, 'pts':44},
		{'color':0,'shortname':'laserena', 'rivalName':'La Serena', 'pj':34, 'pg':10,'pe':9,'pp':15,'gf':34,'gc':41,'dif':-7, 'pts':39},
                {'color':'#E42727','shortname':'coquimbo', 'rivalName':'Coquimbo U.', 'pj':34, 'pg':9,'pe':8,'pp':17,'gf':33,'gc':46,'dif':-13, 'pts':35},
              {'color':'#295e1e','shortname':'antofagasta', 'rivalName':'Antofagasta', 'pj':34, 'pg':12,'pe':12,'pp':10,'gf':43,'gc':42,'dif':1, 'pts':48}],


	 table2:[
		{'color':0,'shortname':'catolica', 'rivalName':'U. Católica', 'pts19':53, 'pon19':1.325,'pts20':65,'pon20':0.765,'pon':2.09},
		{'color':0,'shortname':'lacalera', 'rivalName':'U. La Calera', 'pts19':37, 'pon19':0.888,'pts20':57,'pon20':0.671,'pon':1.559},
		 {'color':0,'shortname':'palestino', 'rivalName':'Palestino', 'pts19':38, 'pon19':0.95,'pts20':51,'pon20':0.6,'pon':1.55},
		{'color':0,'shortname':'colocolo', 'rivalName':'Colo Colo', 'pts19':40, 'pon19':1,'pts20':39,'pon20':0.459,'pon':1.459},
		{'color':0,'shortname':'union', 'rivalName':'U. Española', 'pts19':34, 'pon19':0.816,'pts20':52,'pon20':0.612,'pon':1.428},
		{'color':0,'shortname':'huachipato', 'rivalName':'Huachipato', 'pts19':34, 'pon19':0.85,'pts20':46,'pon20':0.541,'pon':1.391},
		{'color':0,'shortname':'ohiggins', 'rivalName':'O\'Higgins', 'pts19':34, 'pon19':0.85,'pts20':45,'pon20':0.529,'pon':1.379},
		{'color':0,'shortname':'cobresal', 'rivalName':'Cobresal', 'pts19':34, 'pon19':0.816,'pts20':47,'pon20':0.553,'pon':1.369},
              {'color':0,'shortname':'audax', 'rivalName':'Audax Italiano', 'pts19':34, 'pon19':0.85,'pts20':41,'pon20':0.482,'pon':1.332},
		{'color':0,'shortname':'wanderers', 'rivalName':'S. Wanderers', 'pts19':0, 'pon19':0,'pts20':44,'pon20':1.294,'pon':1.294},
		{'color':0,'shortname':'coquimbo', 'rivalName':'Coquimbo U.', 'pts19':34, 'pon19':0.85,'pts20':35,'pon20':0.412,'pon':1.262},
		{'color':0,'shortname':'antofagasta', 'rivalName':'Antofagasta', 'pts19':27, 'pon19':0.675,'pts20':48,'pon20':0.565,'pon':1.24},
		{'color':0,'shortname':'everton', 'rivalName':'Everton', 'pts19':29, 'pon19':0.725,'pts20':43,'pon20':0.506,'pon':1.231},
		 {'color':0,'shortname':'udechile', 'rivalName':'U. de Chile', 'pts19':24, 'pon19':0.6,'pts20':52,'pon20':0.612,'pon':1.212},
		{'color':0,'shortname':'curico', 'rivalName':'Curicó', 'pts19':26, 'pon19':0.65,'pts20':46,'pon20':0.541,'pon':1.191},
		{'color':0,'shortname':'laserena', 'rivalName':'La Serena', 'pts19':0, 'pon19':0,'pts20':39,'pon20':1.147,'pon':1.147},
		{'color':'#835612','shortname':'udeconce', 'rivalName':'U. de Concepción', 'pts19':23, 'pon19':0.575,'pts20':41,'pon20':0.482,'pon':1.057},
              {'color':'#E42727','shortname':'iquique', 'rivalName':'Dep. Iquique', 'pts19':25, 'pon19':0.6,'pts20':38,'pon20':0.447,'pon':1.047}],
    };

  }

  componentDidMount() {
	this.getKits();
		let newtable2 = this.sortTable(this.state.table3);
                this.setState({table3:newtable2});
	}


getKits(){

        fetch("https://www.registrosudec.com:5000/kits", {

    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      id_list: this.state.id_list
    })
    }).then(
                res => res.json()
                )
  .then(
    (result) => {
        console.log(result)
        this.setState({ 
        kits: result['kits']}) //[{path:"45.png", kit:yellow, short:"blue", socks:"blue", count:15, 
    },
    // Nota: es importante manejar errores aqu   y no en
    // un bloque catch() para que no interceptemos errores
    // de errores reales en los componentes.
    (error) => {
      console.log(error);
      this.setState({fecha: []})
    }
  );
}


  handleChange(val){
    console.log(val);
    if(val===1){
      this.setState({showCards:true});
    }
    else{
      this.setState({showCards:false});
    }
  }

comeBack(){
this.setState({path:this.state.path});
}

subText(color, text){

let comp = [];
comp.push(

	<Row className="justify-content-md-center">
            <Col lg={3} xl={3} >

        <Table bordered={ false } size="sm">
        <tr style={{border: 'none', textAlign: 'rigth' }}>
        <td bgcolor={color} style={{width:'20px'}}>
        </td>
        <td style={{border: 'none', textAlign: 'center'}}> {text} </td>
        </tr>
        </Table>
        </Col>
        </Row>



)
return comp;

}


sortTable(table){
       let aux;
      for(let i=table.length-1;i>0;i--){
             for(let j =0; j<i; j++){
                 if( (table[j].pts < table[j+1].pts) || 
                     ((table[j].pts === table[j+1].pts) && (table[j].dif < table[j+1].dif)) ||
                     ((table[j].pts === table[j+1].pts) && (table[j].pg === table[j+1].pg) && (table[j].dif < table[j+1].dif)) ||
                     ((table[j].pts === table[j+1].pts) && (table[j].pg === table[j+1].pg) && (table[j].dif === table[j+1].dif) && (table[j].gf < table[j+1].gf))){
                        aux = table[j+1];
                        table[j+1] = table[j];
                        table[j] = aux;
                } 
             }          
        }
return table;

}



render(){


  let subT = this.subText('#295e1e', 'Clasificado a Sudamericana 2021');
  let subC = this.subText('#135683', 'Clasificado a Libertadores 2021');
  let subP = this.subText('#835612', 'Juega Liguilla Promoción');
  let subD = this.subText('#E42727', 'Desciende a Primera B');
  return(

	<div style={{textAlign: 'center' }}>

	 <div style={{ height: '50px' }}></div>
        <div className="mytitlepartidos">  Indumentaria UDEC Campeonato 2020</div>
        <div style={{ height: '50px' }}></div> 

        <div style={{display: 'inline-block' }}>
        <table style={{textAlign: 'center' }}>
        <tr>
           {this.state.kits.map((i) => {
                   return ( <td> <Image  className="escudo-home" src={this.state.kit_path+i['path']+".png"}/> </td>)
            })}
        </tr>
        <tr>
        {this.state.kits.map((i) => {
                   return ( <td> <Image  className="myshort" src={this.state.kit_path+i['short']+".png"}/> </td>)
            })}
        </tr>
        <tr>
        {this.state.kits.map((i) => {
                   return ( <td> <Image  className="myshort" src={this.state.kit_path+'socks-'+i['socks']+".png"}/> </td>)
            })}
        </tr>

        <tr>
                {this.state.kits.map((i) => {
                   return ( <td class="circle"> {i['count']} </td>)
            })}
        </tr>
        </table>
        </div>


	<div style={{ height: '50px' }}></div>
	<div className="mytitlepartidos"> Tabla General</div>
	<div style={{ height: '10px' }}></div>
        {<Tabla teams={this.state.table3} />}
	<div style={{ height: '10px' }}></div>
	{subC}
	{subT}
	{subP}
 	{subD}

	<div style={{ height: '50px' }}></div>
        <div className="mytitlepartidos"> Tabla Promedios 2019-20</div>
        <div style={{ height: '10px' }}></div>
	{<TablaProm2020 teams={this.state.table2} />}
        <div style={{ height: '10px' }}></div>
	{subP}
        {subD}


	<div style={{ height: '100px' }}></div>
	<div className="mytitlepartidos">  Partidos UDEC Campeonato 2020</div>
	<div style={{ height: '10px' }}></div>

	<ToggleButtonGroup type="radio" name="options" defaultValue={1} onChange={this.handleChange}>
            <ToggleButton variant="secondary" value={1}>Tarjetas</ToggleButton>
            <ToggleButton variant="secondary" value={2}>Listado</ToggleButton>
          </ToggleButtonGroup>
	 <div style={{ height: '10px' }}></div>
	<Container>
	<div className={this.state.showCards ? '': 'hiddenCard'}>
           <MatchCardGroup id_list={this.state.id_list}></MatchCardGroup>
         </div>
         <div className={this.state.showCards ? 'hiddenCard': ''}>

           <MatchList id_list={this.state.id_list} showName={true}></MatchList>

         </div>
	</Container>

	<div style={{ height: '100px' }}></div>
        <div className="mytitlepartidos">  Jugadores UDEC Campeonato 2020</div>
        <div style={{ height: '10px' }}></div>

	<Players torneo="Campeonato 2020" />
     </div>


  )
}

}
export default Campeonato2020;
