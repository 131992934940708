import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Image from 'react-bootstrap/Image';
import Col from 'react-bootstrap/Col';
import Table from 'react-bootstrap/Table';
import Tabla from '../Tabla';
import MatchList from '../MatchList';
import MatchCardGroup from '../MatchCardGroup';
import Players from '../Players';
import ToggleButtonGroup from 'react-bootstrap/ToggleButtonGroup';
import ToggleButton from 'react-bootstrap/ToggleButton';
import TablaProm2017 from '../TablaProm2017';
class Transicion2017 extends React.Component {

  constructor(props){
    super(props);
        this.comeBack = this.comeBack.bind(this);
	this.handleChange = this.handleChange.bind(this);
	var id_list = [];
	for (var i = 928; i <= 942; i++) {
	        	id_list.push(i);
        }
    this.state = {
	showCards: true,
      path: this.props.path,
	 kit_path: '/images/kits/todas/',
      kits: [],
      ctype: this.props.ctype,
      torneo: this.props.torneo,
      id_list: id_list,

	table2:[{'color':0,'shortname':'curico', 'rivalName':'Curicó', 'pj':15, 'pg':5,'pe':3,'pp':7,'gf':11,'gc':14,'dif':-3, 'pts':18},
              {'color':'#835613','shortname':'audax', 'rivalName':'Audax Italiano', 'pj':15, 'pg':7,'pe':4,'pp':4,'gf':24,'gc':19,'dif':5, 'pts':25},
              {'color':0,'shortname':'wanderers', 'rivalName':'S. Wanderers', 'pj':15, 'pg':2,'pe':9,'pp':4,'gf':16,'gc':17,'dif':-1, 'pts':15},
	      {'color':0,'shortname':'udeconce', 'rivalName':'U. de Concepción', 'pj':15, 'pg':3,'pe':8,'pp':4,'gf':16,'gc':15,'dif':1, 'pts':17},
              {'color':0,'shortname':'catolica', 'rivalName':'U. Católica', 'pj':15, 'pg':4,'pe':4,'pp':7,'gf':17,'gc':19,'dif':-2, 'pts':16},
              {'color':'#135683','shortname':'colocolo', 'rivalName':'Colo Colo', 'pj':15, 'pg':10,'pe':3,'pp':2,'gf':33,'gc':13,'dif':20, 'pts':33},
              {'color':'#835613','shortname':'everton', 'rivalName':'Everton', 'pj':15, 'pg':7,'pe':5,'pp':3,'gf':24,'gc':19,'dif':5, 'pts':26},
              {'color':0,'shortname':'huachipato', 'rivalName':'Huachipato', 'pj':15, 'pg':5,'pe':1,'pp':9,'gf':11,'gc':17,'dif':-6, 'pts':16},
              {'color':0,'shortname':'udechile', 'rivalName':'U. de Chile', 'pj':15, 'pg':9,'pe':3,'pp':3,'gf':21,'gc':18,'dif':3, 'pts':30},
              {'color':'#835613','shortname':'temuco', 'rivalName':'Dep. Temuco', 'pj':15, 'pg':5,'pe':7,'pp':3,'gf':15,'gc':12,'dif':3, 'pts':22},
              {'color':0,'shortname':'iquique', 'rivalName':'Dep. Iquique', 'pj':15, 'pg':2,'pe':3,'pp':10,'gf':8,'gc':20,'dif':-12, 'pts':9},
              {'color':'#295e1e','shortname':'union', 'rivalName':'U. Española', 'pj':15, 'pg':9,'pe':4,'pp':2,'gf':18,'gc':12,'dif':6, 'pts':31},
	      {'color':0,'shortname':'sanluis', 'rivalName':'San Luis', 'pj':15, 'pg':5,'pe':3,'pp':7,'gf':15,'gc':20,'dif':-5, 'pts':18},
              {'color':0,'shortname':'palestino', 'rivalName':'Palestino', 'pj':15, 'pg':3,'pe':4,'pp':8,'gf':18,'gc':24,'dif':-6, 'pts':13},
              {'color':0,'shortname':'ohiggins', 'rivalName':'O\'Higgins', 'pj':15, 'pg':4,'pe':3,'pp':8,'gf':15,'gc':24,'dif':-9, 'pts':15},
              {'color':0,'shortname':'antofagasta', 'rivalName':'Antofagasta', 'pj':15, 'pg':5,'pe':6,'pp':4,'gf':14,'gc':13,'dif':1, 'pts':21}],

 

		            table4:[
                        
               {'color':0,'shortname':'colocolo', 'rivalName':'Colo Colo', 'pts16':52, 'pts17':33,'pts':85,'pj':45,'pon':1.889},
		{'color':0,'shortname':'udechile', 'rivalName':'U. de Chile', 'pts16':51, 'pts17':30,'pts':81,'pj':45,'pon':1.800},
		{'color':0,'shortname':'union', 'rivalName':'U. Española', 'pts16':49, 'pts17':31,'pts':80,'pj':45,'pon':1.778},
               {'color':0,'shortname':'catolica', 'rivalName':'U. Católica', 'pts16':54, 'pts17':16,'pts':70,'pj':45,'pon':1.556},
                {'color':0,'shortname':'ohiggins', 'rivalName':'O\'Higgins', 'pts16':48, 'pts17':15,'pts':63,'pj':45,'pon':1.400},
		{'color':0,'shortname':'audax', 'rivalName':'Audax Italiano', 'pts16':37, 'pts17':25,'pts':62,'pj':45,'pon':1.378},
		{'color':0,'shortname':'everton', 'rivalName':'Everton', 'pts16':36, 'pts17':26,'pts':62,'pj':45,'pon':1.378},
		{'color':0,'shortname':'temuco', 'rivalName':'Dep. Temuco', 'pts16':38, 'pts17':22,'pts':60,'pj':45,'pon':1.333},
		{'color':0,'shortname':'iquique', 'rivalName':'Dep. Iquique', 'pts16':50, 'pts17':9,'pts':59,'pj':45,'pon':1.311},
		{'color':0,'shortname':'antofagasta', 'rivalName':'Antofagasta', 'pts16':37, 'pts17':21,'pts':58,'pj':45,'pon':1.289},
		{'color':0,'shortname':'sanluis', 'rivalName':'San Luis', 'pts16':39, 'pts17':18,'pts':57,'pj':45,'pon':1.267},
		{'color':0,'shortname':'udeconce', 'rivalName':'U. de Concepción', 'pts16':38, 'pts17':17,'pts':55,'pj':45,'pon':1.222},
		{'color':0,'shortname':'curico', 'rivalName':'Curicó', 'pts16':0, 'pts17':18,'pts':18,'pj':15,'pon':1.200},
		{'color':0,'shortname':'huachipato', 'rivalName':'Huachipato', 'pts16':36, 'pts17':16,'pts':52,'pj':45,'pon':1.156},
                 {'color':0,'shortname':'palestino', 'rivalName':'Palestino', 'pts16':35, 'pts17':13,'pts':48,'pj':45,'pon':1.066},
                  {'color':'#E42727','shortname':'wanderers', 'rivalName':'S. Wanderers', 'pts16':31, 'pts17':15,'pts':46,'pj':45,'pon':1.022}
              
              ]

  };
  }

  componentDidMount() {
		this.getKits();
		let newtable = this.sortTable(this.state.table2);
  		this.setState({table2:newtable});
	}


getKits(){

        fetch("https://www.registrosudec.com:5000/kits", {

    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      id_list: this.state.id_list
    })
    }).then(
                res => res.json()
                )
  .then(
    (result) => {
        console.log(result)
        this.setState({ 
        kits: result['kits']}) //[{path:"45.png", kit:yellow, short:"blue", socks:"blue", count:15, 
    },
    // Nota: es importante manejar errores aqu   y no en
    // un bloque catch() para que no interceptemos errores
    // de errores reales en los componentes.
    (error) => {
      console.log(error);
      this.setState({fecha: []})
    }
  );
}


  handleChange(val){
    console.log(val);
    if(val===1){
      this.setState({showCards:true});
    }
    else{
      this.setState({showCards:false});
    }
  }

comeBack(){
this.setState({path:this.state.path});
}

subText(color, text){

let comp = [];
comp.push(

	<Row className="justify-content-md-center">
            <Col lg={3} xl={3} >

        <Table bordered={ false } size="sm">
        <tr style={{border: 'none', textAlign: 'rigth' }}>
        <td bgcolor={color} style={{width:'20px'}}>
        </td>
        <td style={{border: 'none', textAlign: 'center'}}> {text} </td>
        </tr>
        </Table>
        </Col>
        </Row>



)
return comp;

}


sortTable(table){
       let aux;
      for(let i=table.length-1;i>0;i--){
             for(let j =0; j<i; j++){
                 if( (table[j].pts < table[j+1].pts) || 
                     ((table[j].pts === table[j+1].pts) && (table[j].dif < table[j+1].dif)) ||
                     ((table[j].pts === table[j+1].pts) && (table[j].pg === table[j+1].pg) && (table[j].dif < table[j+1].dif)) ||
                     ((table[j].pts === table[j+1].pts) && (table[j].pg === table[j+1].pg) && (table[j].dif === table[j+1].dif) && (table[j].gf < table[j+1].gf))){
                        aux = table[j+1];
                        table[j+1] = table[j];
                        table[j] = aux;
                } 
             }          
        }
return table;

}



render(){


  let subT = this.subText('#295e1e', 'Clasificado a Liguilla Lib/Sud');
  let subC = this.subText('#135683', 'Clasificado a Libertadores 2018');
  let subD = this.subText('#E42727', 'Desciende a Primera B');
let subL = this.subText('#835613', 'Clasificado a Sudamericana 2018');
  return(

	<div style={{textAlign: 'center' }}>
	  <div style={{ height: '50px' }}></div>
        <div className="mytitlepartidos">  Indumentaria UDEC Transición 2017</div>
        <div style={{ height: '50px' }}></div> 

        <div style={{display: 'inline-block' }}>
        <table style={{textAlign: 'center' }}>
        <tr>
           {this.state.kits.map((i) => {
                   return ( <td> <Image  className="escudo-home" src={this.state.kit_path+i['path']+".png"}/> </td>)
            })}
        </tr>
        <tr>
        {this.state.kits.map((i) => {
                   return ( <td> <Image  className="myshort" src={this.state.kit_path+i['short']+".png"}/> </td>)
            })}
        </tr>
        <tr>
        {this.state.kits.map((i) => {
                   return ( <td> <Image  className="myshort" src={this.state.kit_path+'socks-'+i['socks']+".png"}/> </td>)
            })}
        </tr>

        <tr>
                {this.state.kits.map((i) => {
                   return ( <td class="circle"> {i['count']} </td>)
            })}
        </tr>
        </table>
        </div>


	<div style={{ height: '50px' }}></div>
	<div className="mytitlepartidos"> Tabla General</div>
	<div style={{ height: '10px' }}></div>
        {<Tabla teams={this.state.table2} />}
        <div style={{ height: '10px' }}></div>
	{subC}
	{subT}
	{subL}	
	        <div style={{ height: '50px' }}></div>
        <div className="mytitlepartidos"> Tabla Descenso 2017</div>
        <div style={{ height: '10px' }}></div>
        {<TablaProm2017 teams={this.state.table4} />}

        <div style={{ height: '10px' }}></div>
        {subD}


	<div style={{ height: '100px' }}></div>
	<div className="mytitlepartidos">  Partidos UDEC Transición 2017</div>
	<div style={{ height: '10px' }}></div>

	<ToggleButtonGroup type="radio" name="options" defaultValue={1} onChange={this.handleChange}>
            <ToggleButton variant="secondary" value={1}>Tarjetas</ToggleButton>
            <ToggleButton variant="secondary" value={2}>Listado</ToggleButton>
          </ToggleButtonGroup>
	 <div style={{ height: '10px' }}></div>
	<Container>
	<div className={this.state.showCards ? '': 'hiddenCard'}>
           <MatchCardGroup id_list={this.state.id_list}></MatchCardGroup>
         </div>
         <div className={this.state.showCards ? 'hiddenCard': ''}>

           <MatchList id_list={this.state.id_list} showName={true}></MatchList>

         </div>
	</Container>

	<div style={{ height: '100px' }}></div>
        <div className="mytitlepartidos">  Jugadores UDEC Transición 2017</div>
        <div style={{ height: '10px' }}></div>

	<Players torneo="Transicion 2017" />
     </div>


  )
}

}
export default Transicion2017;
