import React from 'react';
import Image from 'react-bootstrap/Image';
import ReactTooltip from 'react-tooltip';
import { Link } from 'react-router-dom';




class Match extends React.Component {

  constructor(props){
    super(props);
    this.state = {
      fecha: '',
      fixture: '',
      rivalName: null,
      rivalShortName: null,
      state: null,
      tournament: null,
      goal_home:'',
      goal_away:'',
      stadium:'',
      goles_udec: [],
      goles_rival: [],
      rojas_udec: [],
      rojas_rival: [],
      fallados_udec: [],
      fallados_rival: [],
      penales_udec: [],
      penales_rival: [],
      pk_udec: 0,
      pk_rival: 0,
      match_image: null,
    };
  }

  componentDidMount() {

    fetch("https://www.registrosudec.com:5000/match_femenino", {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      id: this.props.id
    })
    }).then(res => res.json())
  .then(
    (result) => {
     this.setState({fecha: result['date'],
                    rivalName: result['name'],
                    rivalShortName: result['shortname'],
                    fixture: result['fixture'],
                    state: result['state'],
                    tournament: result['tournament'],
                    goal_home: result['goalh'],
                    goal_away: result['goala'],
                    goles_udec: result['goles_udec'],
                    goles_rival: result['goles_rival'],
                    rojas_udec: result['rojas_udec'],
                    rojas_rival: result['rojas_rival'],
                    fallados_udec: result['fallados_udec'],
                    fallados_rival: result['fallados_rival'],
                    penales_udec: result['penales_udec'],
                    penales_rival: result['penales_rival'],
                    pk_udec: result['pk_udec'],
                    pk_rival: result['pk_rival'],
                    tv: result['tv'],
		    video: result['video'],
                    team_image: '/images_fem/escudos/'+result['shortname']+'.png',
                    stadium: result['stadium']})


    },
    // Nota: es importante manejar errores aquí y no en
    // un bloque catch() para que no interceptemos errores
    // de errores reales en los componentes.
    (error) => {
      console.log(error);
      this.setState({fecha: []})
    }
  )

}





render(){


  return(



    <tr style={{textAlign: 'center' }}>

       <td style={{minWidth: '220px'}}>{this.state.fixture}</td>

       {this.state.state === 0 &&
       <td ><Image   data-tip data-for="udeconce-escudo" className="list-escudo" src="/images_fem/escudos/udeconce.png"/>
        <ReactTooltip id="udeconce-escudo" type='light' effect='float'>
          <span >U. de Concepción</span>
        </ReactTooltip>
          {this.props.showName &&  " U. de Concepción" }

       </td>}
       {this.state.state === 1 &&
       <td ><Image   data-tip data-for={this.state.rivalShortName+'-escudo'} className="list-escudo" src={this.state.team_image}/>
       <ReactTooltip id={this.state.rivalShortName+'-escudo'} type='light' effect='float'>
          <span >{this.state.rivalName}</span>
        </ReactTooltip>
          {this.props.showName &&  " " + this.state.rivalName}
       </td>}

       <td style={{minWidth: '45px'}}><Link className="link-name" to={"/partido/" + this.props.id}>{this.state.goal_home + " - " + this.state.goal_away}</Link>
       </td>
       {this.state.state === 1 &&
       <td >
      {this.props.showName &&  "U. de Concepción "  }

       <Image   data-tip data-for="udeconce-escudo" className="list-escudo" src="/images_fem/escudos/udeconce.png"/>
        <ReactTooltip id="udeconce-escudo" type='light' effect='float'>
          <span >U. de Concepción</span>
        </ReactTooltip>
       </td>}
       {this.state.state === 0 &&
       <td >
          {this.props.showName && this.state.rivalName + " " }
       <Image   data-tip data-for={this.state.rivalShortName+'-escudo'} className="list-escudo" src={this.state.team_image}/>
       <ReactTooltip id={this.state.rivalShortName+'-escudo'} type='light' effect='float'>
          <span >{this.state.rivalName}</span>
        </ReactTooltip>

       </td>}
       <td style={{minWidth: '220px'}}>{this.state.stadium}</td>
       {this.state.tv === 1 && <td >{"TV"}</td>}
       {this.state.tv === 0 && <td >{""}</td>}
       {this.state.video === 1 && <td ><Image  className="list-escudo" src="/images_fem/iconos/button.png"/></td>}
       {this.state.video !== 1 && <td >{""}</td>}

    </tr>




  )
}
}
export default Match;
