import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import * as serviceWorker from './serviceWorker';
import ToggleButtonGroup from 'react-bootstrap/ToggleButtonGroup';
import ToggleButton from 'react-bootstrap/ToggleButton';

import 'bootstrap/dist/css/bootstrap.min.css';
import MyNavBarFem from './udecfem/MyNavBar';
import MyNavBar from './MyNavBar';





class Home extends React.Component {
  constructor(props) {
    super(props);
    // Read the value of 'fem' from the cache or default to 0
    this.state = {
      fem: parseInt(localStorage.getItem('fem')) || 1
    };
  }

  // Function to update the fem state and cache
  updateFem = (value) => {
    this.setState({ fem: value }, () => {
      // Update the cache after the state has been updated
      localStorage.setItem('fem', value);
    });
  }

  render() {
    return (
      <div>

	<ToggleButtonGroup type="radio" name="options"  defaultValue={this.state.fem} onChange={this.updateFem}>
    <ToggleButton variant="outline-warning" value={1}>Masculino</ToggleButton>
    <ToggleButton variant="outline-primary" value={2}>Femenino</ToggleButton>
  </ToggleButtonGroup>

        {this.state.fem === 2 && <MyNavBarFem />}
        {this.state.fem === 1 && <MyNavBar />}
      </div>
    );
  }
}



export default Home;

