import React from 'react';
import Container from 'react-bootstrap/Container';
import YoutubeEmbed from "./YoutubeEmbed";
import Card from 'react-bootstrap/Card';
import Image from 'react-bootstrap/Image';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import ReactTooltip from 'react-tooltip';
import CarouselImg from './CarouselImg';
import Modal from 'react-bootstrap/Modal'
import BodyModal from "./BodyModal";


class Partido extends React.Component {

  constructor(props){
    super(props);
this.state = {
      loopActive: false,
      shuffleActive: false,
    };
  
  this.handleShow = this.handleShow.bind(this);
this.handleClose = this.handleClose.bind(this);
    this.handleShowLineup = this.handleShowLineup.bind(this);
this.handleCloseLineup = this.handleCloseLineup.bind(this);
    this.state = {
      fecha: '',
      fixture: '',
      rivalName: null,
      rivalShortName: null,
      state: null,
      tournament: null,
      goal_home:'',
      goal_away:'',
      stadium:'',
      goles_udec: [],
      goles_rival: [],
      amarillas_udec: [],
      amarillas_rival: [],
      rojas_udec: [],
      rojas_rival: [],
      fallados_udec: [],
      fallados_rival: [],
      penales_udec: [],
      penales_rival: [],
      showM: false,
      showL: false,
	pk_udec: 0,
      pk_rival: 0,
      referee: null,
      kit_home: null,
      kit_away: null,
      video: null,
      lineup_udec: [],
      lineup_team: [],
      tv: null,
      publico: 0,
      comentario: null,
      hora: '',
      match_images: 0,
    };
  }

  componentDidMount() {


  

fetch("https://www.registrosudec.com:5000/lineup", {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      id: this.props.id
    })
    }).then(res => res.json())
  .then(
    (result) => {
        this.setState({	lineup_udec: result['lineup_udec'],
        lineup_team: result['lineup_team']})

    },
    // Nota: es importante manejar errores aqu   y no en
    // un bloque catch() para que no interceptemos errores
    // de errores reales en los componentes.
    (error) => {
      console.log(error);
      this.setState({fecha: []})
    }
  );


    fetch("https://www.registrosudec.com:5000/match", {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      id: this.props.id
    })
    }).then(res => res.json())
  .then(
    (result) => {
     this.setState({fecha: result['date'],
                    rivalName: result['name'],
                    rivalShortName: result['shortname'],
                    fixture: result['fixture'],
                    state: result['state'],
                    tournament: result['tournament'],
                    goal_home: result['goalh'],
                    goal_away: result['goala'],
                    goles_udec: result['goles_udec'],
                    goles_rival: result['goles_rival'],
		    amarillas_udec: result['amarillas_udec'],
                    amarillas_rival: result['amarillas_rival'],
                    rojas_udec: result['rojas_udec'],
                    rojas_rival: result['rojas_rival'],
                    fallados_udec: result['fallados_udec'],
                    fallados_rival: result['fallados_rival'],
                    penales_udec: result['penales_udec'],
                    penales_rival: result['penales_rival'],
                    pk_udec: result['pk_udec'],
                    pk_rival: result['pk_rival'],
		    match_images_path_default: '/images/partidos/Default/2/1.png',
                    match_images_path: '/images/partidos/'+this.normalize(result['tournament'])+'/'+this.props.id+'/',
                    match_kits_path: '/images/kits/'+this.normalize(result['tournament'])+'/',
		    num_of_images: result['numImg'],
                    referee: result['referee'],
                    kit_home: result['kit_home'],
                    kit_away: result['kit_away'],
                    video: result['link'],
                    hasImage: result['hasImage'],
                    tv: result['tv'],
                    publico: result['publico'],
                    comentario: result['comentario'],
                    hora: result['hora'],
                    dayOfWeek: result['diaSemana'],
		    loadImg: 1,
                    stadium: result['stadium']})

    },
    // Nota: es importante manejar errores aquí y no en
    // un bloque catch() para que no interceptemos errores
    // de errores reales en los componentes.
    (error) => {
      console.log(error);
      this.setState({fecha: []})
    }
  )
}

day_of_week = (function(num) {

  let dow = {0:'Lunes', 1:'Martes', 2:'Miércoles', 3:'Jueves', 4:'Viernes', 5:'Sábado', 6:'Domingo'};
  return dow[num]
})

normalize = (function() {
  var from = "ÃÀÁÄÂÈÉËÊÌÍÏÎÒÓÖÔÙÚÜÛãàáäâèéëêìíïîòóöôùúüûÑñÇç",
      to   = "AAAAAEEEEIIIIOOOOUUUUaaaaaeeeeiiiioooouuuunncc",
      mapping = {};

  for(var i = 0, j = from.length; i < j; i++ )
      mapping[ from.charAt( i ) ] = to.charAt( i );

  return function( str ) {
      var ret = [];
      for( var i = 0, j = str.length; i < j; i++ ) {
          var c = str.charAt( i );
          if( mapping.hasOwnProperty( str.charAt( i ) ) )
              ret.push( mapping[ c ] );
          else
              ret.push( c );
      }
      return ret.join( '' );
  }

})();


merge_arrays(goles, rojas, fallados){

  let aux = [];
  let final = [];
  let largo_goles = goles.length;
  let largo_rojas = rojas.length;
  let largo_fallados = fallados.length;

  let it_goles = 0;
  let it_rojas = 0;


  while(it_goles<largo_goles || it_rojas<largo_rojas){

    if(it_goles>=largo_goles){
      aux.push({'player':rojas[it_rojas].player, 'minute':rojas[it_rojas].minute, 'icono':'red'})
      it_rojas++;
    }
    else if(it_rojas>=largo_rojas){
      aux.push({'player':goles[it_goles].player, 'minute':goles[it_goles].minute, 'icono':goles[it_goles].tipo})
      it_goles++;
    }
    else{
      if(parseFloat(goles[it_goles].minute.replace("+",".")) >= parseFloat(rojas[it_rojas].minute.replace("+","."))){
        aux.push({'player':rojas[it_rojas].player, 'minute':rojas[it_rojas].minute, 'icono':'red'})
        it_rojas++;
      }
      else if(parseFloat(goles[it_goles].minute.replace("+",".")) < parseFloat(rojas[it_rojas].minute.replace("+","."))){
        aux.push({'player':goles[it_goles].player, 'minute':goles[it_goles].minute, 'icono':goles[it_goles].tipo})
        it_goles++;
      }
    }
  }
  let largo_aux = aux.length;
  let it_aux = 0;
  let it_fallados = 0;

  if(largo_fallados === 0){
    final = aux;
  }
  else{
    while(it_aux < largo_aux || it_fallados < largo_fallados){

       if(it_aux >= largo_aux){
         final.push({'player':fallados[it_fallados].player, 'minute':fallados[it_fallados].minute, 'icono':'missed'});
         it_fallados++;
       }
       else if(it_fallados >= largo_fallados){
         final.push(aux[it_aux]);
         it_aux++;
       }
       else{
         if(parseFloat(aux[it_aux].minute.replace("+",".")) >= parseFloat(fallados[it_fallados].minute.replace("+","."))){
           final.push({'player':fallados[it_fallados].player, 'minute':fallados[it_fallados].minute, 'icono':'missed'});
           it_fallados++;
         }
         else if(parseFloat(aux[it_aux].minute.replace("+",".")) < parseFloat(fallados[it_fallados].minute.replace("+","."))){
           final.push(aux[it_aux]);
           it_aux++;
         }
       }

    }

  }

  return final;

}

 handleShow() {
   console.log("show");
   this.setState({showM: true});

}
 handleClose(){
 this.setState({showM: false});

}

 handleShowLineup() {
   console.log("show");

   this.setState({showL: true});

}
 handleCloseLineup(){
 this.setState({showL: false});

}



setGoals(){

  let merged_udec = this.merge_arrays(this.state.goles_udec, this.state.rojas_udec, this.state.fallados_udec);
  let merged_rival = this.merge_arrays(this.state.goles_rival, this.state.rojas_rival, this.state.fallados_rival);
  let num_udec = merged_udec.length;
  let num_rival = merged_rival.length;

  let mayor = Math.max(num_udec,num_rival);
  let componente_goles = [];
  for(let i=0;i<mayor;i++){

         componente_goles.push(
           <Row className="justify-content-md-center">
           <Col  lg={6} xl={6} md={6} xs={6}>
           {this.state.state === 0 && i < num_udec && <Image  className={'my-'+merged_udec[i].icono} src={'/images/iconos/' + merged_udec[i].icono + '.png'}/>}
          {this.state.state === 0 && i < num_udec &&  merged_udec[i].player + ' ' + merged_udec[i].minute}

            {this.state.state === 1 && i < num_rival && <Image  className={'my-'+merged_rival[i].icono} src={'/images/iconos/' + merged_rival[i].icono + '.png'}/>}
             {this.state.state === 1 && i < num_rival && merged_rival[i].player + ' ' + merged_rival[i].minute}
           </Col>
           <Col  lg={6} xl={6} md={6} xs={6}>
           {this.state.state === 1 && i < num_udec && <Image  className={'my-'+merged_udec[i].icono} src={'/images/iconos/' + merged_udec[i].icono + '.png'}/>}
           {this.state.state === 1 && i < num_udec && merged_udec[i].player + ' ' + merged_udec[i].minute}

            {this.state.state === 0 && i < num_rival && <Image  className={'my-'+merged_rival[i].icono} src={'/images/iconos/' + merged_rival[i].icono + '.png'}/>}
            {this.state.state === 0 && i < num_rival && merged_rival[i].player + ' ' + merged_rival[i].minute}

          </Col>
          </Row>
         )



  }
  return componente_goles;
}


setPenalties(){

  let num_udec = this.state.penales_udec.length;
  let num_rival = this.state.penales_rival.length;

  let mayor = Math.max(num_udec,num_rival);
  let componente_penales = [];
  for(let i=0;i<mayor;i++){

         componente_penales.push(
           <Row className="justify-content-md-center">
           <Col  lg={6} xl={6} md={6} xs={6}>
           {this.state.state === 0 && i < num_udec && <Image  className={'my-pelota'} src={'/images/iconos/penalty' + this.state.penales_udec[i].goal + '.png'}/>}
          {this.state.state === 0 && i < num_udec &&  this.state.penales_udec[i].player}

            {this.state.state === 1 && i < num_rival && <Image  className={'my-pelota'} src={'/images/iconos/penalty' + this.state.penales_rival[i].goal + '.png'}/>}
             {this.state.state === 1 && i < num_rival && this.state.penales_rival[i].player}
           </Col>
           <Col  lg={6} xl={6} md={6} xs={6}>
           {this.state.state === 1 && i < num_udec && <Image  className={'my-pelota'} src={'/images/iconos/penalty' + this.state.penales_udec[i].goal + '.png'}/>}
           {this.state.state === 1 && i < num_udec && this.state.penales_udec[i].player}

            {this.state.state === 0 && i < num_rival && <Image  className={'my-pelota'} src={'/images/iconos/penalty' + this.state.penales_rival[i].goal + '.png'}/>}
            {this.state.state === 0 && i < num_rival && this.state.penales_rival[i].player}

          </Col>
          </Row>
         )



  }
  return componente_penales;
}


render(){

  let goles = this.setGoals();
  let penalties = this.setPenalties();
  let dia = '';
  if(this.state.dayOfWeek === 6){
      dia = "Domingo";
  }
  else if(this.state.dayOfWeek === 0){
     dia = "Lunes";
  }
    else if(this.state.dayOfWeek === 1){
     dia = "Martes";
  }
    else if(this.state.dayOfWeek === 2){
     dia = "Miércoles";
  }
    else if(this.state.dayOfWeek === 3){
     dia = "Jueves";
  }
    else if(this.state.dayOfWeek === 4){
     dia = "Viernes";
  }
    else if(this.state.dayOfWeek === 5){
     dia = "Sábado";
  }



  return(
<Container className="d-flex justify-content-center">

    <Card  className="mycol text-center" border="primary">
              <Card.Header>{this.state.fixture}</Card.Header>

               <Card.Img variant="top" src={this.state.match_image} onError={() => this.setState({match_image: '/images/partidos/Default/1/1.png'})} />
                <Card.Body>

                  <Row >
                  <Col  lg={4} xl={4} md={4} xs={4}>
                  {this.state.state === 0 &&
                  <div>
                  <Image className="myescudo-home2" data-tip data-for='udec-escudo' src='/images/escudos/udeconce.png'/>
                  <ReactTooltip id='udec-escudo' type='light' effect='float'>
                    <span>Universidad de Concepción</span>
                  </ReactTooltip>
                  </div>
                  }
                  {this.state.state === 1 && this.state.rivalShortName &&
                  <div>
                  <Image  className="myescudo-home2" data-tip data-for={this.state.rivalShortName+'-escudo'} src={'/images/escudos/'+this.state.rivalShortName+'.png'}/>
                  <ReactTooltip id={this.state.rivalShortName+'-escudo'} type='light' effect='float'>
                    <span>{this.state.rivalName}</span>
                  </ReactTooltip>
                  </div>
                  }
                  </Col>
                  <Col  lg={4} xl={4} md={4} xs={4}>
                  <div className="myresultado">{this.state.goal_home + " - " + this.state.goal_away}
                  </div>
                  </Col>
                  <Col  lg={4} xl={4} md={4} xs={4}>
                  {this.state.state === 0 && this.state.rivalShortName &&
                  <div>
                  <Image  className="myescudo-away2" data-tip data-for={this.state.rivalShortName+'-escudo'} src={'/images/escudos/'+this.state.rivalShortName+'.png'}/>
                  <ReactTooltip id={this.state.rivalShortName+'-escudo'} type='light' effect='float'>
                    <span>{this.state.rivalName}</span>
                  </ReactTooltip>
                  </div>
                  }
                  {this.state.state === 1 &&
                    <div>
                    <Image  className="myescudo-away2" data-tip data-for='udec-escudo' src='/images/escudos/udeconce.png'/>
                    <ReactTooltip id='udec-escudo' type='light' effect='float'>
                      <span>Universidad de Concepción</span>
                    </ReactTooltip>
                    </div>
                  }
                  </Col>
                 </Row>
                 <Card.Subtitle lg={12} xl={12} md={12} xs={12} className="justify-content-md-center mb-2 text-muted text-center">{this.state.stadium} </Card.Subtitle>


                  <Card.Text  className="mycardtext">


 <Col > Público: {this.state.publico}</Col>
                <Col >  Árbitro: {this.state.referee}</Col><Col ><br/></Col>
<Col ><br/></Col>
	


                  {goles}
<Col ><br/></Col>

 {penalties.length > 1 && <Col >Penales: </Col>}
  {penalties.length > 1 && <Col ><br/></Col>}
 {penalties.length > 1 && penalties}
 {penalties.length > 1 && <Col ><br/><br/></Col>}
<Col style={{whiteSpace: 'pre-wrap'}}> {this.state.comentario}</Col>
<Col ><br/><br/></Col>

	
{this.state.lineup_udec.length > 0 && <Button onClick={this.handleShowLineup} variant="primary">Alineaciones</Button>}

<Modal size="lg" show={this.state.showM} onHide={this.handleClose}>
        <Modal.Header closeButton>
        </Modal.Header>
        <Modal.Body>
		<YoutubeEmbed embedId={this.state.video} />
	</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={this.handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
&nbsp;
<Modal size="xl" show={this.state.showL} onHide={this.handleCloseLineup}>
        <Modal.Header closeButton>
		<div className="text-center w-100 titulo" >
	{this.state.fixture}
	<div style={{ height: '10px' }}></div>
	{this.state.state === 1 && this.state.rivalName + " vs. U. de Concepción" }
	{this.state.state === 0 &&  "U. de Concepción vs. " + this.state.rivalName }
	</div>
        </Modal.Header>
        <Modal.Body>
                {this.state.state === 0 && <BodyModal kit_away_path={this.state.match_kits_path+this.state.rivalShortName+this.state.kit_away} kit_home_path={this.state.match_kits_path+"udeconce"+this.state.kit_home} yellow_home={this.state.amarillas_udec} yellow_away={this.state.amarillas_rival} red_home={this.state.rojas_udec} red_away={this.state.rojas_rival} home_name={'udeconce'} away_name={this.state.rivalShortName} lineup_home={this.state.lineup_udec} lineup_away={this.state.lineup_team} goal_home={this.state.goles_udec} goal_away={this.state.goles_rival} matchid={this.props.id} local={0}/>}
		{this.state.state === 1 && <BodyModal kit_away_path={this.state.match_kits_path+"udeconce"+this.state.kit_away} kit_home_path={this.state.match_kits_path+this.state.rivalShortName+this.state.kit_home} yellow_home={this.state.amarillas_rival} yellow_away={this.state.amarillas_udec} red_home={this.state.rojas_rival} red_away={this.state.rojas_udec} home_name={this.state.rivalShortName} away_name={'udeconce'} lineup_home={this.state.lineup_team} lineup_away={this.state.lineup_udec} goal_home={this.state.goles_rival} goal_away={this.state.goles_udec} matchid={this.props.id} local={1}/>}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={this.handleCloseLineup}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

&nbsp;
{this.state.video !== '' && <Button onClick={this.handleShow} variant="success">Compacto</Button>}
&nbsp;



<Col ><br/></Col>
                  </Card.Text>

                </Card.Body>

		 {this.state.hasImage === 1 && <CarouselImg id={this.props.id} torneo={this.state.match_images_path} n={this.state.num_of_images}/> }
                 {this.state.hasImage === 0 && <img  alt="" className=" d-block "  src={this.state.match_images_path_default} /> }

{this.state.hora !== 'None' && <Card.Footer className="text-muted">{dia} {this.state.fecha} - {this.state.hora} hrs.</Card.Footer>}
{this.state.hora === 'None' && <Card.Footer className="text-muted">{dia} {this.state.fecha}</Card.Footer>}
            </Card>
</Container>
  )
}
}
export default Partido
;
