import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import RowComp from './RowComp';


import Table from 'react-bootstrap/Table';



class TableGroup extends React.Component {


render(){


  return(
    <Row className="justify-content-md-center">
    <Col lg={6} xl={6}  >
    <Table variant="dark" striped bordered hover size="sm">
  <thead>
    <tr style={{textAlign: 'center' }}>
      <th>Equipo</th>
      <th>PJ</th>
      <th>PG</th>
      <th>PE</th>
      <th>PP</th>
      <th>GF</th>
      <th>GC</th>
      <th>DIF</th>
    </tr>
  </thead>
  <tbody>
    {this.props.team_list.map((team, i) => {
                   return ( <RowComp key={team['path']} shortname={team['path']} name={team['name']}  ></RowComp>  )
            })}
</tbody>
</Table>
</Col>
</Row>
  )
}
}
export default TableGroup;
