import React from 'react';
import Match from './Match';
import Table from 'react-bootstrap/Table';





class MatchList extends React.Component {



render(){


  return(
     <Table responsive variant="dark"  striped bordered hover size="sm">
     <thead>
     <tr>
       <th>Fecha</th>
       <th>Local</th>
       <th>vs.</th>
       <th>Visita</th>
       <th>Estadio</th>
       <th>TV</th>
	<th> Video</th>
     </tr>
   </thead>
     <tbody>

    {this.props.id_list.map((i) => {
                   return ( <Match key={i} id={i} showName={this.props.showName}></Match>  )
            })}
            </tbody>

     </Table>

  )
}
}
export default MatchList;
