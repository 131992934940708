import React from 'react';
import Image from 'react-bootstrap/Image';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Table from 'react-bootstrap/Table';
import Tabla from '../Tabla';
import MatchList from '../MatchList';
import MatchCardGroup from '../MatchCardGroup';
import Players from '../Players';
import ToggleButtonGroup from 'react-bootstrap/ToggleButtonGroup';
import ToggleButton from 'react-bootstrap/ToggleButton';
class Clausura2007 extends React.Component {

  constructor(props){
    super(props);
        this.comeBack = this.comeBack.bind(this);
	this.handleChange = this.handleChange.bind(this);
	var id_list = [];
	for (var i = 498; i <= 523; i++) {
        	id_list.push(i);
        }
    this.state = {
	showCards: true,
      path: this.props.path,
      ctype: this.props.ctype,
      torneo: this.props.torneo,
	kit_path: '/images/kits/todas/',
      kits: [],
      id_list: id_list,
      table1:[
		{'color':'#295e1e','shortname':'udeconce', 'rivalName':'U. de Concepción', 'pj':20, 'pg':9,'pe':8,'pp':3,'gf':30,'gc':19,'dif':11, 'pts':35},
              {'color':'#295e1e','shortname':'catolica', 'rivalName':'U. Católica', 'pj':20, 'pg':10,'pe':4,'pp':6,'gf':33,'gc':21,'dif':12, 'pts':34},
		{'color':0,'shortname':'union', 'rivalName':'U. Española', 'pj':20, 'pg':4,'pe':5,'pp':11,'gf':26,'gc':36,'dif':-10, 'pts':17},
		{'color':0,'shortname':'melipilla', 'rivalName':'Melipilla', 'pj':20, 'pg':7,'pe':6,'pp':7,'gf':32,'gc':34,'dif':-2, 'pts':27},
	    {'color':0,'shortname':'concepcion', 'rivalName':'Dep. Concepción', 'pj':20, 'pg':7,'pe':4,'pp':9,'gf':32,'gc':41,'dif':-9, 'pts':25}
],

	table2:[{'color':'#295e1e','shortname':'cobreloa', 'rivalName':'Cobreloa', 'pj':20, 'pg':8,'pe':7,'pp':5,'gf':36,'gc':30,'dif':6, 'pts':31},
              {'color':'#135683','shortname':'audax', 'rivalName':'Audax Italiano', 'pj':20, 'pg':14,'pe':5,'pp':1,'gf':47,'gc':20,'dif':27, 'pts':47},
              {'color':0,'shortname':'puertomontt', 'rivalName':'Puerto Montt', 'pj':20, 'pg':5,'pe':6,'pp':9,'gf':18,'gc':22,'dif':-4, 'pts':21},
		{'color':0,'shortname':'concepcion', 'rivalName':'Dep. Concepción', 'pj':20, 'pg':7,'pe':4,'pp':9,'gf':32,'gc':41,'dif':-9, 'pts':25},
	      {'color':'#295e1e','shortname':'udeconce', 'rivalName':'U. de Concepción', 'pj':20, 'pg':9,'pe':8,'pp':3,'gf':30,'gc':19,'dif':11, 'pts':35},
              {'color':'#295e1e','shortname':'catolica', 'rivalName':'U. Católica', 'pj':20, 'pg':10,'pe':4,'pp':6,'gf':33,'gc':21,'dif':12, 'pts':34},
              {'color':'#295e1e','shortname':'colocolo', 'rivalName':'Colo Colo', 'pj':20, 'pg':11,'pe':6,'pp':3,'gf':40,'gc':21,'dif':19, 'pts':39},
              {'color':'#295e1e','shortname':'cobresal', 'rivalName':'Cobresal', 'pj':20, 'pg':8,'pe':8,'pp':4,'gf':31,'gc':23,'dif':8, 'pts':32},
              {'color':0,'shortname':'huachipato', 'rivalName':'Huachipato', 'pj':20, 'pg':7,'pe':4,'pp':9,'gf':30,'gc':36,'dif':-6, 'pts':25},
              {'color':'#295e1e','shortname':'udechile', 'rivalName':'U. de Chile', 'pj':20, 'pg':13,'pe':6,'pp':1,'gf':32,'gc':21,'dif':21, 'pts':45},
              {'color':0,'shortname':'melipilla', 'rivalName':'Melipilla', 'pj':20, 'pg':7,'pe':6,'pp':7,'gf':32,'gc':34,'dif':-2, 'pts':27},
              {'color':0,'shortname':'wanderers', 'rivalName':'S. Wanderers', 'pj':20, 'pg':4,'pe':5,'pp':11,'gf':15,'gc':43,'dif':-28, 'pts':17},
              {'color':0,'shortname':'union', 'rivalName':'U. Española', 'pj':20, 'pg':4,'pe':5,'pp':11,'gf':26,'gc':36,'dif':-10, 'pts':17},
              {'color':'#295e1e','shortname':'ohiggins', 'rivalName':'O\'Higgins', 'pj':20, 'pg':9,'pe':7,'pp':4,'gf':28,'gc':23,'dif':5, 'pts':34},
	      {'color':0,'shortname':'laserena', 'rivalName':'La Serena', 'pj':20, 'pg':6,'pe':4,'pp':10,'gf':25,'gc':33,'dif':-8, 'pts':22},
	      {'color':0,'shortname':'everton', 'rivalName':'Everton', 'pj':20, 'pg':3,'pe':4,'pp':13,'gf':20,'gc':36,'dif':-16, 'pts':13},
              {'color':0,'shortname':'palestino', 'rivalName':'Palestino', 'pj':20, 'pg':7,'pe':5,'pp':8,'gf':27,'gc':33,'dif':-6, 'pts':26},
              {'color':0,'shortname':'antofagasta', 'rivalName':'Antofagasta', 'pj':20, 'pg':6,'pe':7,'pp':7,'gf':22,'gc':21,'dif':1, 'pts':25},
		{'color':0,'shortname':'nublense', 'rivalName':'Ñublense', 'pj':20, 'pg':7,'pe':5,'pp':8,'gf':32,'gc':38,'dif':-6, 'pts':26},
		{'color':0,'shortname':'lota', 'rivalName':'Lota Schwager', 'pj':20, 'pg':4,'pe':7,'pp':9,'gf':22,'gc':28,'dif':-6, 'pts':19},
              {'color':0,'shortname':'coquimbo', 'rivalName':'Coquimbo Unido', 'pj':20, 'pg':4,'pe':1,'pp':15,'gf':18,'gc':37,'dif':-19, 'pts':13}],

	table3:[{'color':0,'shortname':'cobreloa', 'rivalName':'Cobreloa', 'pj':40, 'pg':18,'pe':12,'pp':10,'gf':79,'gc':52,'dif':37, 'pts':66},
              {'color':0,'shortname':'audax', 'rivalName':'Audax Italiano', 'pj':40, 'pg':27,'pe':10,'pp':3,'gf':86,'gc':40,'dif':46, 'pts':91},
              {'color':'#E4AB27','shortname':'puertomontt', 'rivalName':'Puerto Montt', 'pj':40, 'pg':9,'pe':8,'pp':23,'gf':37,'gc':62,'dif':-25, 'pts':35},
              {'color':0,'shortname':'udeconce', 'rivalName':'U. de Concepción', 'pj':40, 'pg':13,'pe':13,'pp':14,'gf':55,'gc':52,'dif':3, 'pts':52},
              {'color':0,'shortname':'catolica', 'rivalName':'U. Católica', 'pj':40, 'pg':24,'pe':8,'pp':8,'gf':69,'gc':35,'dif':34, 'pts':80},
              {'color':0,'shortname':'colocolo', 'rivalName':'Colo Colo', 'pj':40, 'pg':25,'pe':11,'pp':4,'gf':87,'gc':37,'dif':50, 'pts':86},
              {'color':0,'shortname':'cobresal', 'rivalName':'Cobresal', 'pj':40, 'pg':17,'pe':13,'pp':10,'gf':62,'gc':44,'dif':18, 'pts':64},
              {'color':0,'shortname':'huachipato', 'rivalName':'Huachipato', 'pj':40, 'pg':19,'pe':8,'pp':13,'gf':65,'gc':57,'dif':8, 'pts':65},
              {'color':0,'shortname':'udechile', 'rivalName':'U. de Chile', 'pj':40, 'pg':19,'pe':13,'pp':8,'gf':62,'gc':42,'dif':20, 'pts':70},
              {'color':0,'shortname':'melipilla', 'rivalName':'Melipilla', 'pj':40, 'pg':15,'pe':10,'pp':15,'gf':67,'gc':68,'dif':-1, 'pts':55},
	      {'color':0,'shortname':'concepcion', 'rivalName':'Dep. Concepción', 'pj':40, 'pg':13,'pe':9,'pp':18,'gf':50,'gc':75,'dif':-25, 'pts':48},
              {'color':'#E42727','shortname':'wanderers', 'rivalName':'S. Wanderers', 'pj':40, 'pg':8,'pe':9,'pp':23,'gf':39,'gc':76,'dif':-37, 'pts':30},
		{'color':'#E42727','shortname':'lota', 'rivalName':'Lota Schwager', 'pj':40, 'pg':6,'pe':13,'pp':21,'gf':50,'gc':80,'dif':-30, 'pts':31},
              {'color':0,'shortname':'union', 'rivalName':'U. Española', 'pj':40, 'pg':12,'pe':9,'pp':19,'gf':55,'gc':61,'dif':-6, 'pts':45},
              {'color':0,'shortname':'ohiggins', 'rivalName':'O\'Higgins', 'pj':40, 'pg':17,'pe':10,'pp':13,'gf':58,'gc':61,'dif':-3, 'pts':61},
              {'color':0,'shortname':'laserena', 'rivalName':'La Serena', 'pj':40, 'pg':13,'pe':9,'pp':18,'gf':56,'gc':63,'dif':-7, 'pts':48},
              {'color':0,'shortname':'everton', 'rivalName':'Everton', 'pj':40, 'pg':9,'pe':12,'pp':19,'gf':44,'gc':63,'dif':-19, 'pts':39},
              {'color':0,'shortname':'palestino', 'rivalName':'Palestino', 'pj':40, 'pg':12,'pe':13,'pp':15,'gf':54,'gc':53,'dif':1, 'pts':49},
              {'color':0,'shortname':'antofagasta', 'rivalName':'Antofagasta', 'pj':40, 'pg':11,'pe':14,'pp':15,'gf':46,'gc':55,'dif':-9, 'pts':47},
                {'color':0,'shortname':'nublense', 'rivalName':'Ñublense', 'pj':40, 'pg':15,'pe':13,'pp':12,'gf':63,'gc':69,'dif':-6, 'pts':58},
              {'color':'#E42727','shortname':'coquimbo', 'rivalName':'Coquimbo Unido', 'pj':40, 'pg':9,'pe':3,'pp':28,'gf':39,'gc':78,'dif':-39, 'pts':27}]

    };
  }

  componentDidMount() {
	this.getKits();
		let _newtable = this.sortTable(this.state.table1);
                this.setState({table1:_newtable});
		let newtable = this.sortTable(this.state.table2);
  		this.setState({table2:newtable});
		let newtable2 = this.sortTable(this.state.table3);
                this.setState({table3:newtable2});
	}

getKits(){

        fetch("https://www.registrosudec.com:5000/kits", {

    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      id_list: this.state.id_list
    })
    }).then(
                res => res.json()
                )
  .then(
    (result) => {
        console.log(result)
        this.setState({ 
        kits: result['kits']}) //[{path:"45.png", kit:yellow, short:"blue", soc$
    },
    // Nota: es importante manejar errores aqu   y no en
    // un bloque catch() para que no interceptemos errores
    // de errores reales en los componentes.
    (error) => {
      console.log(error);
      this.setState({fecha: []})
    }
  );
}


  handleChange(val){
    console.log(val);
    if(val===1){
      this.setState({showCards:true});
    }
    else{
      this.setState({showCards:false});
    }
  }

comeBack(){
this.setState({path:this.state.path});
}

subText(color, text){

let comp = [];
comp.push(

	<Row className="justify-content-md-center">
            <Col lg={3} xl={3} >

        <Table bordered={ false } size="sm">
        <tr style={{border: 'none', textAlign: 'rigth' }}>
        <td bgcolor={color} style={{width:'20px'}}>
        </td>
        <td style={{border: 'none', textAlign: 'center'}}> {text} </td>
        </tr>
        </Table>
        </Col>
        </Row>



)
return comp;

}


sortTable(table){
       let aux;
      for(let i=table.length-1;i>0;i--){
             for(let j =0; j<i; j++){
                 if( (table[j].pts < table[j+1].pts) || 
                     ((table[j].pts === table[j+1].pts) && (table[j].dif < table[j+1].dif)) ||
                     ((table[j].pts === table[j+1].pts) && (table[j].dif === table[j+1].dif) && (table[j].gf < table[j+1].gf))){
			aux = table[j+1];
			table[j+1] = table[j];
                        table[j] = aux;
		} 
	     }		
	}
return table;

}


render(){


  let subT = this.subText('#295e1e', 'Clasificado a Play-Off');
  let subC = this.subText('#135683', 'Clasificado a Play-Off y a Libertadores 2008');
  let subP = this.subText('#E4AB27', 'Juega Liguilla Promoción');
  let subD = this.subText('#E42727', 'Desciende a Primera B');
  return(

	<div style={{textAlign: 'center' }}>
	 <div style={{ height: '50px' }}></div>
        <div className="mytitlepartidos">  Indumentaria UDEC Clausura 2007</div>
        <div style={{ height: '50px' }}></div> 

        <div style={{display: 'inline-block' }}>
        <table style={{textAlign: 'center' }}>
        <tr>
           {this.state.kits.map((i) => {
                   return ( <td> <Image  className="escudo-home" src={this.state.kit_path+i['path']+".png"}/> </td>)
            })}
        </tr>
        <tr>
        {this.state.kits.map((i) => {
                   return ( <td> <Image  className="myshort" src={this.state.kit_path+i['short']+".png"}/> </td>)
            })}
        </tr>
        <tr>
        {this.state.kits.map((i) => {
                   return ( <td> <Image  className="myshort" src={this.state.kit_path+'socks-'+i['socks']+".png"}/> </td>)
            })}
        </tr>

        <tr>
                {this.state.kits.map((i) => {
                   return ( <td class="circle"> {i['count']} </td>)
            })}
        </tr>
        </table>
        </div>

      <div style={{ height: '50px' }}></div>
	<div className="mytitlepartidos"> Grupo 2 </div>
	<div style={{ height: '10px' }}></div>
	{<Tabla teams={this.state.table1} />}
        <div style={{ height: '10px' }}></div>
         {subT}


	<div style={{ height: '50px' }}></div>
	<div className="mytitlepartidos"> Tabla General</div>
	<div style={{ height: '10px' }}></div>
        {<Tabla teams={this.state.table2} />}

	{subT}
	{subC}
	
	<div style={{ height: '50px' }}></div>
        <div className="mytitlepartidos"> Tabla Anual 2007</div>
        <div style={{ height: '10px' }}></div>
        {<Tabla teams={this.state.table3} />}

        {subP}
	{subD}


	<div style={{ height: '100px' }}></div>
	<div className="mytitlepartidos">  Partidos UDEC Clausura 2007</div>
	<div style={{ height: '10px' }}></div>

	<ToggleButtonGroup type="radio" name="options" defaultValue={1} onChange={this.handleChange}>
            <ToggleButton variant="secondary" value={1}>Tarjetas</ToggleButton>
            <ToggleButton variant="secondary" value={2}>Listado</ToggleButton>
          </ToggleButtonGroup>
	 <div style={{ height: '10px' }}></div>
	<Container>
	<div className={this.state.showCards ? '': 'hiddenCard'}>
           <MatchCardGroup id_list={this.state.id_list}></MatchCardGroup>
         </div>
         <div className={this.state.showCards ? 'hiddenCard': ''}>

           <MatchList id_list={this.state.id_list} showName={true}></MatchList>

         </div>
	</Container>

	<div style={{ height: '100px' }}></div>
        <div className="mytitlepartidos">  Jugadores UDEC Clausura 2007</div>
        <div style={{ height: '10px' }}></div>

	<Players torneo="Clausura 2007" />
     </div>


  )
}

}
export default Clausura2007;
