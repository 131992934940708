import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import * as serviceWorker from './serviceWorker';

import 'bootstrap/dist/css/bootstrap.min.css';
import Home from './Home';




ReactDOM.render(

  <React.StrictMode>
       <Home />
  </React.StrictMode>,
  document.getElementById('root')
);
serviceWorker.unregister();
