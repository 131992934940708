import React from 'react';


class PitchCanvas extends React.Component {

  constructor(props){
    super(props);
    this.state = {
    };
    this.colorMap = {'udeconce':'yellow', 'antofagasta': 'white', 'vial': 'yellow', 'audax': 'green', 'barnechea':'blue', 'bolivar': 'cyan', 'caracas':'red','cobreloa':'orange', 'cobresal':'orange',
                      'colocolito':'white', 'linares': 'white', 'colocolo':'white','coquimbo':'yellow','cruzeiro':'blue','curico':'white','colchagua':'white', 'concepcion':'purple', 'copiapo': 'white', 'iquique':'DarkTurquoise',
                       'ovalle':'green','recoleta':'blue','santacruz':'white','temuco':'white','valdivia':'red','everton':'blue','godoy':'white','huachipato':'blue','iberia':'MediumVioletRed',
                        'laserena':'Crimson','lota':'white','magallanes':'cyan','melipilla':'white','nacional':'white','naval':'white','nublense':'red','ohiggins':'cyan','olimpia':'white',
                          'palestino':'white', 'osorno':'darkblue','puertomontt':'white','kumey':'red','rangers':'red','sanluis':'yellow','arica':'blue','morning':'white','wanderers':'green',
                          'santos':'white','sporting':'cyan','union':'red','lacalera':'red','sanfelipe':'white','utemuco':'red','catolica':'white','udechile':'blue','vasco':'black', 'atacama': 'white'}

    this.borderColorMap = {'udeconce':'blue', 'antofagasta':'blue', 'vial': 'black', 'audax': 'white', 'barnechea': 'yellow', 'bolivar': 'black','caracas':'white','cobreloa':'black','cobresal':'white', 
                            'colocolito':'black', 'colocolo':'black','linares': 'red', 'coquimbo':'black','cruzeiro':'white','curico':'red','colchagua':'blue','concepcion':'white','copiapo':'red','iquique':'black',
                            'ovalle':'white','recoleta':'white','santacruz':'blue','temuco':'green','valdivia':'white','everton':'yellow','godoy':'blue','huachipato':'black', 'iberia':'blue',
                            'laserena':'white','lota':'black','magallanes':'white','melipilla':'blue','nacional':'blue','naval':'blue','nublense':'white','ohiggins':'black','olimpia':'black',
                             'palestino':'black','osorno':'white','puertomontt':'green','kumey':'white','rangers':'black','sanluis':'black','arica':'white','morning':'black','wanderers':'white',
                              'santos':'green','sporting':'white','union':'blue','lacalera':'blue','sanfelipe':'red','utemuco':'white','catolica':'blue','udechile':'white','vasco':'white', 'atacama': 'red'}


  }



  componentDidMount(){



	 var drawGoal = function(ogX, ogY, _fillColor, _borderColor, txt){

                ctx.fillStyle = _fillColor;
                ctx.beginPath();
                var radius = 12; 
                var x = (canvasPitch.width/52) * (ogY + 3.5);
                var y = canvasPitch.height - ((canvasPitch.height/76) * (ogX + 3.5));
                ctx.arc(x,y, radius, 0, Math.PI * 2);
                ctx.lineWidth = 2;
                ctx.fill();
                ctx.strokeStyle = _borderColor;
                ctx.stroke();

                ctx.fillStyle=_borderColor;
                ctx.font = 'bold 12px serif';
                
                ctx.fillText(txt, x - radius/3, y  + radius/3);

  }


    var canvasPitch = document.getElementById("pitchCanvas");
    var ctx = canvasPitch.getContext("2d");
    ctx.canvas.width = 480;
    ctx.canvas.height = 690;
    var imgCabin = new Image();
    imgCabin.src = "/images/iconos/football-pitch.jpg";
     var goal_home = this.props.goal_home;
     var goal_away = this.props.goal_away;
     var home_name = this.props.home_name;
     var away_name = this.props.away_name;
     var colorMap = this.colorMap;
     var borderColorMap = this.borderColorMap;

    imgCabin.onload =function(){
        ctx.drawImage(imgCabin, 0, 0, this.naturalWidth, this.naturalHeight, 0, 0, canvasPitch.width, canvasPitch.height );
	

	for(var i = 0; i < goal_home.length; i++){

		drawGoal(goal_home[i].x, goal_home[i].y, colorMap[home_name], borderColorMap[home_name], goal_home[i].it + 1)
	}
	for(var j = 0; j < goal_away.length; j++){

                drawGoal(goal_away[j].x, goal_away[j].y, colorMap[away_name], borderColorMap[away_name], goal_away[j].it + 1)
        }


    };



  }






  render(){

    return(

	<canvas id="pitchCanvas" class="pitch"> </canvas>

    )

}
}
export default PitchCanvas;
