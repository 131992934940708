import Teams from './Teams';
import SingleTeam from './SingleTeam';
import React from 'react';


class Equipos extends React.Component {


  render(){

    return(

      <div>
      {this.props.equipo === "Todos" &&
      <Teams />}
      {this.props.equipo !== "Todos" &&
      <SingleTeam equipo={this.props.short} name={this.props.equipo}/>}
      </div>

    )

  }
}
export default Equipos;
