import React from 'react';
import Image from 'react-bootstrap/Image';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Table from 'react-bootstrap/Table';
import Tabla from '../Tabla';
import MatchList from '../MatchList';
import MatchCardGroup from '../MatchCardGroup';
import Players from '../Players';
import ToggleButtonGroup from 'react-bootstrap/ToggleButtonGroup';
import ToggleButton from 'react-bootstrap/ToggleButton';
class PrimeraB2000 extends React.Component {

  constructor(props){
    super(props);
        this.comeBack = this.comeBack.bind(this);
	this.handleChange = this.handleChange.bind(this);
	var id_list = [];
	for (var i = 187; i <= 222; i++) {
        	id_list.push(i);
        }
    this.state = {
	showCards: true,
      path: this.props.path,
	kit_path: '/images/kits/todas/',
      kits: [],
      ctype: this.props.ctype,
      torneo: this.props.torneo,
      id_list: id_list,
      table1:[{'color':0,'shortname':'naval', 'rivalName':'Dep. Talcahuano', 'pj':6, 'pg':4,'pe':1,'pp':1,'gf':9,'gc':4,'dif':5, 'pts':13},
	      {'color':0,'shortname':'vial', 'rivalName':'Fernández Vial', 'pj':6, 'pg':3,'pe':1,'pp':2,'gf':9,'gc':6,'dif':3, 'pts':10},
	      {'color':0,'shortname':'udeconce', 'rivalName':'U. de Concepción', 'pj':6, 'pg':2,'pe':1,'pp':3,'gf':4,'gc':6,'dif':-4, 'pts':7},
	      {'color':0,'shortname':'temuco', 'rivalName':'Dep. Temuco', 'pj':6, 'pg':1,'pe':1,'pp':4,'gf':4,'gc':10,'dif':-6, 'pts':4}],

	table2:[{'color':'#ff3355','shortname':'nublense', 'rivalName':'Ñublense', 'pj':30, 'pg':3,'pe':10,'pp':17,'gf':32,'gc':60,'dif':-28, 'pts':21},
              {'color':0,'shortname':'laserena', 'rivalName':'La Serena', 'pj':30, 'pg':7,'pe':10,'pp':13,'gf':37,'gc':44,'dif':-7, 'pts':33},
              {'color':0,'shortname':'ovalle', 'rivalName':'Dep. Ovalle', 'pj':30, 'pg':12,'pe':9,'pp':9,'gf':49,'gc':33,'dif':16, 'pts':48},
	      {'color':0,'shortname':'udeconce', 'rivalName':'U. de Concepción', 'pj':30, 'pg':11,'pe':5,'pp':14,'gf':34,'gc':42,'dif':-8, 'pts':42},
              {'color':0,'shortname':'antofagasta', 'rivalName':'Antofagasta', 'pj':30, 'pg':14,'pe':7,'pp':9,'gf':41,'gc':32,'dif':9, 'pts':53},
              {'color':0,'shortname':'melipilla', 'rivalName':'Melipilla', 'pj':30, 'pg':11,'pe':9,'pp':10,'gf':42,'gc':43,'dif':-1, 'pts':49},
              {'color':0,'shortname':'cobresal', 'rivalName':'Cobresal', 'pj':30, 'pg':12,'pe':6,'pp':12,'gf':44,'gc':51,'dif':-7, 'pts':47},
              {'color':0,'shortname':'magallanes', 'rivalName':'Magallanes', 'pj':30, 'pg':7,'pe':10,'pp':13,'gf':36,'gc':50,'dif':-14, 'pts':35},
              {'color':0,'shortname':'arica', 'rivalName':'Dep. Arica', 'pj':30, 'pg':9,'pe':10,'pp':11,'gf':41,'gc':45,'dif':-4, 'pts':44},
              {'color':'#295e1e','shortname':'rangers', 'rivalName':'Rangers', 'pj':30, 'pg':16,'pe':7,'pp':7,'gf':54,'gc':31,'dif':22, 'pts':58},
              {'color':0,'shortname':'naval', 'rivalName':'Dep. Talcahuano', 'pj':30, 'pg':13,'pe':9,'pp':8,'gf':43,'gc':41,'dif':2, 'pts':55},
              {'color':0,'shortname':'vial', 'rivalName':'Fernández Vial', 'pj':30, 'pg':13,'pe':9,'pp':8,'gf':47,'gc':34,'dif':13, 'pts':53},
              {'color':0,'shortname':'temuco', 'rivalName':'Dep. Temuco', 'pj':30, 'pg':9,'pe':12,'pp':9,'gf':38,'gc':36,'dif':2, 'pts':41},
              {'color':0,'shortname':'linares', 'rivalName':'Dep. Linares', 'pj':30, 'pg':10,'pe':7,'pp':13,'gf':48,'gc':45,'dif':3, 'pts':41},
              {'color':'#295e1e','shortname':'sanfelipe', 'rivalName':'U. San Felipe', 'pj':30, 'pg':14,'pe':10,'pp':6,'gf':42,'gc':32,'dif':10, 'pts':59},
              {'color':0,'shortname':'iquique', 'rivalName':'Dep. Iquique', 'pj':30, 'pg':12,'pe':4,'pp':14,'gf':44,'gc':53,'dif':-9, 'pts':42}]

    };
  }

  componentDidMount() {
		this.getKits();
	let newtable = this.sortTable(this.state.table1);
                this.setState({table1:newtable});

	let newtable2 = this.sortTable(this.state.table2);
                this.setState({table2:newtable2});
	}

getKits(){

        fetch("https://www.registrosudec.com:5000/kits", {

    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      id_list: this.state.id_list
    })
    }).then(
                res => res.json()
                )
  .then(
    (result) => {
        console.log(result)
        this.setState({ 
        kits: result['kits']}) //[{path:"45.png", kit:yellow, short:"blue", soc$
    },
    // Nota: es importante manejar errores aqu   y no en
    // un bloque catch() para que no interceptemos errores
    // de errores reales en los componentes.
    (error) => {
      console.log(error);
      this.setState({fecha: []})
    }
  );
}


  handleChange(val){
    console.log(val);
    if(val===1){
      this.setState({showCards:true});
    }
    else{
      this.setState({showCards:false});
    }
  }

comeBack(){
this.setState({path:this.state.path});
}

subText(color, text){

let comp = [];
comp.push(

	<Row className="justify-content-md-center">
            <Col lg={2} xl={2} >

        <Table bordered={ false } size="sm">
        <tr style={{border: 'none', textAlign: 'rigth' }}>
        <td bgcolor={color}>
        </td>
        <td style={{border: 'none', textAlign: 'center'}}> {text} </td>
        </tr>
        </Table>
        </Col>
        </Row>



)
return comp;

}

sortTable(table){
       let aux;
      for(let i=table.length-1;i>0;i--){
             for(let j =0; j<i; j++){
                 if( (table[j].pts < table[j+1].pts) || 
                     ((table[j].pts === table[j+1].pts) && (table[j].dif < table[j+1].dif)) ||
                     ((table[j].pts === table[j+1].pts) && (table[j].dif === table[j+1].dif) && (table[j].gf < table[j+1].gf))){
                        aux = table[j+1];
                        table[j+1] = table[j];
                        table[j] = aux;
                } 
             }          
        }
return table;

}




render(){


  let subT = this.subText('#295e1e', 'Asciende a Primera A 2001');
  let subD = this.subText('#ff3355', 'Desciende a Tercera División 2001');
console.log(this.state.id_list)

  return(

	<div style={{textAlign: 'center' }}>
	 <div style={{ height: '50px' }}></div>
        <div className="mytitlepartidos">  Indumentaria UDEC Primera B 2000</div>
        <div style={{ height: '50px' }}></div> 

        <div style={{display: 'inline-block' }}>
        <table style={{textAlign: 'center' }}>
        <tr>
           {this.state.kits.map((i) => {
                   return ( <td> <Image  className="escudo-home" src={this.state.kit_path+i['path']+".png"}/> </td>)
            })}
        </tr>
        <tr>
        {this.state.kits.map((i) => {
                   return ( <td> <Image  className="myshort" src={this.state.kit_path+i['short']+".png"}/> </td>)
            })}
        </tr>
        <tr>
        {this.state.kits.map((i) => {
                   return ( <td> <Image  className="myshort" src={this.state.kit_path+'socks-'+i['socks']+".png"}/> </td>)
            })}
        </tr>

        <tr>
                {this.state.kits.map((i) => {
                   return ( <td class="circle"> {i['count']} </td>)
            })}
        </tr>
        </table>
        </div>

      <div style={{ height: '50px' }}></div>
	<div className="mytitlepartidos"> Grupo 4 </div>
	<div style={{ height: '10px' }}></div>
	{<Tabla teams={this.state.table1} />}
        <div style={{ height: '10px' }}></div>

       <div> Cada equipo empieza la Segunda Fase con la mitad de los puntos obtenidos en la Primera Fase</div> 
	<div style={{ height: '50px' }}></div>
	<div className="mytitlepartidos"> Tabla General</div>
	<div style={{ height: '10px' }}></div>
        {<Tabla teams={this.state.table2} />}
        <div style={{ height: '10px' }}></div>
         {subT}
	 {subD}
	
	<div style={{ height: '100px' }}></div>
	<div className="mytitlepartidos">  Partidos UDEC Primera B 2000</div>
	<div style={{ height: '10px' }}></div>

	<ToggleButtonGroup type="radio" name="options" defaultValue={1} onChange={this.handleChange}>
            <ToggleButton variant="secondary" value={1}>Tarjetas</ToggleButton>
            <ToggleButton variant="secondary" value={2}>Listado</ToggleButton>
          </ToggleButtonGroup>
	 <div style={{ height: '10px' }}></div>
	<Container>
	<div className={this.state.showCards ? '': 'hiddenCard'}>
           <MatchCardGroup id_list={this.state.id_list}></MatchCardGroup>
         </div>
         <div className={this.state.showCards ? 'hiddenCard': ''}>

           <MatchList id_list={this.state.id_list} showName={true}></MatchList>

         </div>
	</Container>

	<div style={{ height: '100px' }}></div>
        <div className="mytitlepartidos">  Jugadores UDEC Primera B 2000</div>
        <div style={{ height: '10px' }}></div>

	<Players torneo="Primera B 2000" />
     </div>


  )
}

}
export default PrimeraB2000;
